import { connect } from 'react-redux';
import { GlobalState } from '../../reducers';
import { updateOwner } from '../../actions/user';
import { getSelectedPharmacy, getOwner } from '../../selectors/user';
import { getServiceNamesState, getServiceNamesLookupState } from '../../selectors/services';
import { getUserPharmacies } from '../../actions/user';
import { getServiceNames } from '../../actions/services';
import ServiceEdit from '.';
import { Owner } from '@avicennapharmacy/managemymeds-shared';

const mapStateToProps = (state: GlobalState) => ({
  pharmacy: getSelectedPharmacy(state),
  owner: getOwner(state),
  serviceNames: getServiceNamesState(state),
  serviceNamesLookup: getServiceNamesLookupState(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  updateOwner: async (owner: Owner | null) => {
    dispatch(await updateOwner(owner));
  },
  getUserPharmacies: () => dispatch(getUserPharmacies()),
  getServiceNames: async () => dispatch(await getServiceNames())
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEdit);

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export type BulkSuccessModalProps = {
  show?: boolean;
  setShowSuccess: (show: boolean) => void;
  title?: string;
  message?: string;
};

export default ({ show, setShowSuccess, title, message }: BulkSuccessModalProps) => {
  return (
    <Modal show={show} onHide={() => setShowSuccess(false)}>
      <Modal.Header>
        <Modal.Title className="text-center col-md-12">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowSuccess(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

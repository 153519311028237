import { actionTypes } from '../actions/services';
import { ServiceName, ServiceNamesLookup } from '@avicennapharmacy/managemymeds-shared';

export type servicesStateType = {
  serviceNames: ServiceName[] | null;
  serviceNamesLookup: ServiceNamesLookup | null;
};

export const userState: servicesStateType = {
  serviceNames: null,
  serviceNamesLookup: null
};

export default (state = userState, action: Action): servicesStateType => {
  if (actionTypes === undefined) {
    return state;
  }
  switch (action.type) {
    case actionTypes.GET_SERVICE_NAMES:
      return {
        ...state,
        serviceNames: action.payload.result,
        serviceNamesLookup: action.payload.serviceNamesLookup
      };
    default:
      return state;
  }
};

import React from 'react';
import OrdersPage from './components/orders/OrdersPage.container';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCheckCircle,
  faCloudDownloadAlt,
  faExclamationCircle,
  faHome,
  faHistory,
  faInfoCircle,
  faPlusCircle,
  faSave,
  faShuttleVan,
  faSort,
  faSortUp,
  faSortDown,
  faTimesCircle,
  faTrashAlt,
  faQuestionCircle,
  faSignInAlt,
  faClipboard
} from '@fortawesome/free-solid-svg-icons';
import { HashRouter, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import Dashboard from './components/Dashboard.container';
import DiaryPage from './components/diary/DiaryPage.container';
import Help from './components/help';
import Patients from './components/patients';
import EditDetails from './components/edit/index.container';
import Button from 'react-bootstrap/Button';
import ServiceEdit from './components/serviceEdit/index.container';
import CreatePassword from './components/CreatePassword';
import PrivateRoute from './components/PrivateRoute.container';
import GroupSettings from './components/group/settings/index.container';
import GroupPharmacies from './components/group/pharmacies/index.container';

library.add(
  faCheck,
  faCheckCircle,
  faCloudDownloadAlt,
  faExclamationCircle,
  faInfoCircle,
  faHome,
  faHistory,
  faPlusCircle,
  faSave,
  faShuttleVan,
  faSort,
  faSortUp,
  faSortDown,
  faTimesCircle,
  faTrashAlt,
  faQuestionCircle,
  faSignInAlt,
  faClipboard
);

export default () => {
  return (
    <HashRouter basename="">
      <Switch>
        <Route path="/createpassword/:token">
          <CreatePassword />
        </Route>
        <PrivateRoute path="/Orders/:tabKey">
          <OrdersPage />
        </PrivateRoute>
        <PrivateRoute path="/Diary">
          <DiaryPage />
        </PrivateRoute>
        <PrivateRoute path="/Help">
          <Help />
        </PrivateRoute>
        <PrivateRoute path="/Details">
          <EditDetails />
        </PrivateRoute>
        <PrivateRoute path="/Patients">
          <Patients />
        </PrivateRoute>
        <PrivateRoute path="/Services">
          <ServiceEdit />
        </PrivateRoute>
        <PrivateRoute path="/Group/Settings">
          <GroupSettings />
        </PrivateRoute>
        <PrivateRoute path="/Group/Pharmacies">
          <GroupPharmacies />
        </PrivateRoute>
        <PrivateRoute exact path="/">
          <Dashboard />
        </PrivateRoute>
        <Route path="/">
          <StyledP>
            Page not found. If this error persists please contact support on{' '}
            <a href="mailto:managemymeds@avicenna.org">managemymeds@avicenna.org</a>
          </StyledP>
          <Centered>
            <Button href="/">Return home</Button>
          </Centered>
        </Route>
      </Switch>
    </HashRouter>
  );
};

const StyledP = styled.p`
  margin-top: 100px;
  text-align: center;
`;

const Centered = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Order, formatDate, ERDSurveyAPIResponse } from '@avicennapharmacy/managemymeds-shared';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const printNames = (orders: Order[], username: string) => {
  const content = [
    {
      style: ['table', 'small'],
      table: {
        widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
        body: [
          ['Date Ordered', "Patient's Name", 'Address', 'GP Surgery', 'No. Items'],
          ...orders.map(order => [
            formatDate(order.orderDt, 'dateAndTime'),
            order.patientName,
            order.patientAddress,
            order.patientSurgeryAddress ? order.patientSurgeryAddress.split('\n')[0] : '',
            order.items.length
          ])
        ]
      },
      layout: 'lightHorizontalLines'
    }
  ];
  pdfMake
    .createPdf({
      footer: getFooter(username),
      content,
      styles
    })
    .open();
};

export const printERDNames = (
  orders: Order[],
  erdSurveys: ERDSurveyAPIResponse | null | undefined,
  username: string
) => {
  const content = [
    {
      style: ['table', 'small'],
      table: {
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: [
          ['Date Ordered', "Patient's Name", 'Address', 'GP Surgery', 'Items', 'Issues'],
          ...orders.map(order => {
            const erdSurvey = erdSurveys ? erdSurveys[order.id] : null;
            return [
              formatDate(order.orderDt, 'dateAndTime'),
              order.patientName,
              order.patientAddress,
              order.patientSurgeryAddress ? order.patientSurgeryAddress.split('\n')[0] : '',
              order.items.map(item => {
                return {
                  text: item.description,
                  decoration:
                    erdSurvey && erdSurvey.rejectedItemIds.includes(item.orderItemId)
                      ? 'lineThrough'
                      : ''
                };
              }),
              !erdSurvey ||
              erdSurvey.rejectedItemIds.length > 0 ||
              erdSurvey.surveyResponses.some(s => s.responseRequiresIntervention)
                ? { svg: svgTick, width: 10, height: 10, alignment: 'center' }
                : ''
            ];
          })
        ]
      },
      layout: 'lightHorizontalLines'
    }
  ];
  pdfMake
    .createPdf({
      pageOrientation: 'landscape',
      footer: getFooter(username),
      content,
      styles
    })
    .open();
};

export const printOrders = (orders: Order[], pharmacy: any, username: string) => {
  const content: object[] = [];
  orders.forEach((order, index) => {
    content.push(getSurgeryTable(order));
    content.push(getOrderLayout(order));
    if (order.isEarlyReorder) {
      content.push(getEarlyReorderText(order));
    }
    content.push(getSignature(index !== orders.length - 1, pharmacy));
  });
  pdfMake
    .createPdf({
      footer: getFooter(username),
      content,
      styles
    })
    .open();
};

const splitAddressLines = (address: string) => {
  if (address) {
    return address.split('\n').map(line => ({ text: line }));
  }
  return [''];
};

const styles = {
  table: {
    margin: [0, 25, 0, 25]
  },
  textMargin: { margin: [0, 25, 0, 0] },
  small: { fontSize: 8 }
};

const svgTick = `<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="1" width="42" height="42" rx="6" stroke="#000000" stroke-width="2"/>
<path d="M13 23.4028L17.9218 30L30 11" stroke="#000000" stroke-width="3"/>
</svg>
`;

const getSurgeryTable = (order: Order) => ({
  style: 'table',
  table: {
    widths: [100, '*', 'auto'],
    body: [
      [
        'Surgery:',
        [
          { text: order.patientGPName, bold: true },
          ...splitAddressLines(order.patientSurgeryAddress)
        ],
        formatDate(new Date(), 'longDate')
      ]
    ]
  },
  layout: 'noBorders'
});

const getOrderLayout = (order: Order) => [
  { text: ['Dear Dr ', { text: order.patientDoctorName, bold: true }] },
  {
    text: 'Please supply the following repeat prescription for:',
    style: 'textMargin'
  },
  {
    style: 'table',
    table: {
      widths: ['auto', 'auto'],
      body: [
        ['Patient Name:', { text: order.patientName, bold: true }],
        ['D.o.B.:', formatDate(order.patientDateOfBirth, 'longDate')],
        ['Address:', order.patientAddress],
        ...order.items
          .filter(item => item.rejected === null)
          .map(item => [
            { svg: svgTick, width: 10, height: 10, alignment: 'center' },
            { text: item.option ?? item.description, bold: true }
          ])
      ]
    },
    layout: 'noBorders'
  }
];

const getEarlyReorderText = (order: Order) => [
  {
    text: 'This is an early reorder for the following reason:'
  },
  {
    text: order.earlyReorderReason,
    bold: true
  }
];

const getSignature = (pageBreakAfter: boolean, pharmacy: any) => [
  { text: 'Kind regards,', style: 'textMargin' },
  { text: pharmacy.businessName, bold: true },
  {
    text: `${pharmacy.address1} ${pharmacy.address2} ${pharmacy.address3} ${pharmacy.city} ${pharmacy.postcode}`,
    bold: true,
    pageBreak: pageBreakAfter ? 'after' : ''
  }
];

const getFooter = (username: string): pdfMake.Content => {
  return {
    columns: [
      {
        text: `This document contains sensitive data which must be kept secure or destroyed if no longer needed`,
        alignment: 'left',
        style: 'small',
        margin: [20, 0, 0, 0]
      },
      {
        text: `Printed ${formatDate(new Date(), 'dateAndTime')} by ${username}`,
        alignment: 'right',
        style: 'small',
        margin: [0, 0, 20, 0]
      }
    ]
  };
};

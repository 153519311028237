import {
  formatDate,
  getAdminConfigVar,
  Pharmacy,
  PharmacyPortalAccessLog,
  ServiceNamesLookup,
  ServiceName
} from '@avicennapharmacy/managemymeds-shared';
import Axios, { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../../Header.container';
import PageContainer from '../../layout/PageContainer';
import { H2 } from '../../typography/Headers';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import differenceInDays from 'date-fns/differenceInDays';
import BulkEditModal from './BulkEditModal';
import ResponseModal from '../../common/ResponseModal';

const PAGE_TITLE = 'Group Pharmacies';

type PharmaciesProps = {
  setSelectedPharmacy: (id: string) => void;
  getServiceNames: () => void;
  serviceNamesLookup: ServiceNamesLookup | null;
  serviceNames: ServiceName[] | null;
};

export default ({
  setSelectedPharmacy,
  getServiceNames,
  serviceNamesLookup,
  serviceNames
}: PharmaciesProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
  const [accessLogs, setAccessLogs] = useState<{ [pharmacyId: string]: PharmacyPortalAccessLog }>(
    {}
  );
  const [selectedPharmacyIds, setSelectedPharmacyIds] = useState<string[]>([]);
  const [selectedPharmacyGroupId, setSelectedPharmacyGroupId] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalTitle, setSuccessModalTitle] = useState('');
  const [successModalBody, setSuccessModalBody] = useState('');
  const history = useHistory();

  type GetGroupPharmaciesAPIResult = {
    pharmacies: Pharmacy[];
    portalAccessLogs: {
      [pharmacyId: string]: PharmacyPortalAccessLog;
    };
  };

  useEffect(() => {
    const getGroupPharmacies = async () => {
      setLoading(true);
      try {
        const res = await Axios.post<any, AxiosResponse<GetGroupPharmaciesAPIResult>>(
          getAdminConfigVar('getGroupPharmaciesEndpoint')
        );
        setPharmacies(res.data.pharmacies);
        setSelectedPharmacyGroupId(res.data.pharmacies[0].groupId);
        setAccessLogs(res.data.portalAccessLogs);
        setLoading(false);
        setError('');
      } catch {
        setError('There was an error loading pharmacies');
      }
    };

    getGroupPharmacies();
  }, []);

  if (loading) {
    return (
      <PageContainer>
        <Header />
        <Row>
          <Col>
            <H2>{PAGE_TITLE}</H2>
          </Col>
        </Row>
        <Row>
          <Spinner variant="primary" animation="border" role="status" />
        </Row>
      </PageContainer>
    );
  }

  if (error !== '') {
    return (
      <PageContainer>
        <Header />
        <Row>
          <Col>
            <H2>{PAGE_TITLE}</H2>
          </Col>
        </Row>
        <Row>
          <em>{error}</em>
        </Row>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header />
      <Row>
        <Col sm={8}>
          <H2>{PAGE_TITLE}</H2>
        </Col>
        <Col sm={4} className="text-right">
          <Button
            data-testid="bulk-edit"
            disabled={selectedPharmacyIds.length === 0}
            onClick={() => setShowModal(true)}
          >
            Bulk Edit
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {pharmacies.length === 0 ? (
            <em>No pharmacies found.</em>
          ) : (
            <Table hover>
              <thead>
                <tr>
                  <th>Pharmacy</th>
                  <th>Last Access Date</th>
                  <th className="text-center">View Portal</th>
                  <th className="text-center">
                    <Form.Check
                      type="checkbox"
                      checked={selectedPharmacyIds.length === pharmacies.length}
                      onChange={() => {
                        if (selectedPharmacyIds.length === pharmacies.length) {
                          setSelectedPharmacyIds([]);
                        } else {
                          setSelectedPharmacyIds(pharmacies.map(p => p.id));
                        }
                      }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {pharmacies.map(p => {
                  const accessLog = accessLogs[p.id];
                  return (
                    <tr key={p.id}>
                      <td>{p.businessName}</td>
                      <td
                        className={
                          accessLog &&
                          differenceInDays(Date.now(), new Date(accessLog.lastAccessDate)) > 1
                            ? 'text-danger'
                            : ''
                        }
                      >
                        {accessLog
                          ? formatDate(new Date(accessLog.lastAccessDate), 'dateAndTime')
                          : ''}
                      </td>
                      <td className="text-center">
                        <Button
                          variant="link"
                          onClick={() => {
                            setSelectedPharmacy(p.id);
                            history.push('/');
                          }}
                        >
                          <FontAwesomeIcon icon="sign-in-alt" />
                        </Button>
                      </td>
                      <td className="text-center">
                        <Form.Check
                          data-testid={p.id}
                          type="checkbox"
                          checked={selectedPharmacyIds.includes(p.id)}
                          onChange={() => {
                            if (selectedPharmacyIds.includes(p.id)) {
                              setSelectedPharmacyIds(selectedPharmacyIds.filter(id => id !== p.id));
                            } else {
                              setSelectedPharmacyIds([...selectedPharmacyIds, p.id]);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <BulkEditModal
        show={showModal}
        setShow={setShowModal}
        setShowSuccess={setShowSuccessModal}
        setSuccessModalTitle={setSuccessModalTitle}
        setSuccessModalBody={setSuccessModalBody}
        pharmacyIds={selectedPharmacyIds}
        selectedGroupId={selectedPharmacyGroupId}
        serviceNamesLookup={serviceNamesLookup}
        serviceNames={serviceNames}
        getServiceNames={getServiceNames}
      />
      <ResponseModal
        show={showSuccessModal}
        setShowSuccess={setShowSuccessModal}
        title={successModalTitle}
        message={successModalBody}
      />
    </PageContainer>
  );
};

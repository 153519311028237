import { userStateType } from '../reducers/user';

export const loadUserState = () => {
  try {
    const serialisedState = sessionStorage.getItem('userState');
    if (serialisedState === null) {
      return undefined;
    }
    return JSON.parse(serialisedState);
  } catch (e) {
    return undefined;
  }
};

export const saveUserState = ({ user }: { user: userStateType }) => {
  try {
    // Do not persist refresh token
    const serialisedState = JSON.stringify({
      user: {
        ...user,
        refreshToken: null
      }
    });
    sessionStorage.setItem('userState', serialisedState);
  } catch (e) {}
};

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export type Rejection = {
  reason: string;
  text: string;
};

export type RejectionProps = {
  onReject: (rejection: Rejection) => void;
  onCancel: () => void;
};

export default ({ onReject, onCancel }: RejectionProps) => {
  const [reason, setReason] = useState('');
  const [text, setText] = useState('');
  return (
    <Form>
      <Form.Row>
        <RejectionForm reason={reason} text={text} setReason={setReason} setText={setText} />
        <Col>
          <Button variant="secondary" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '0.5em' }}
            variant="primary"
            onClick={() => onReject({ reason, text })}
            disabled={reason === ''}
          >
            Submit
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};

type RejectionFormProps = {
  reason: string;
  text: string;
  setReason: (reason: string) => void;
  setText: (text: string) => void;
};

export const RejectionForm = ({ reason, text, setReason, setText }: RejectionFormProps) => (
  <>
    <Col>
      <Form.Control
        as="select"
        value={reason}
        onChange={({ target }: any) => setReason(target.value)}
      >
        <option value="">Rejection Reason</option>
        <option value="Manufacturer can't supply">Manufacturer can't supply</option>
        <option value="Other">Other</option>
      </Form.Control>
    </Col>
    <Col>
      <Form.Control
        placeholder="Further information (optional)"
        value={text}
        onChange={({ target }: any) => setText(target.value)}
      />
    </Col>
  </>
);

import React, { ReactNode } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../assets/logo.png';
import logoSmall from '../assets/logoSmall.png';
import { Pharmacy, PharmacyGroup } from '@avicennapharmacy/managemymeds-shared';

type HeaderProps = {
  username: string | null;
  selectedPharmacy: Pharmacy | undefined | null;
  selectedGroup: PharmacyGroup | undefined | null;
  signOut: () => void;
  switchSelection: () => void;
  smallLogo?: boolean;
  children?: ReactNode;
};

export default ({
  children,
  username,
  selectedPharmacy,
  selectedGroup,
  signOut,
  switchSelection,
  smallLogo = true
}: HeaderProps) => (
  <StyledRow>
    <Col sm="auto">
      <a href="/#">
        <img
          src={smallLogo ? logoSmall : logo}
          height={smallLogo ? 50 : 60}
          width={smallLogo ? 50 : 312}
          alt="Avicenna Logo"
        />
      </a>
    </Col>
    <IconCol sm="auto">
      <a href="/#">
        <FontAwesomeIcon icon="home" size="lg" />
      </a>
    </IconCol>
    {children ?? <Col />}
    <Col sm="auto">
      <UserAreaDiv>
        <DropdownButton id="user-dropdown" size="lg" variant="outline-secondary" title={username}>
          <Dropdown.Item onClick={() => switchSelection()}>
            {selectedPharmacy ? selectedPharmacy.businessName : `${selectedGroup?.name} (Group)`}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => switchSelection()}>Change pharmacy</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => signOut()}>Sign out</Dropdown.Item>
        </DropdownButton>
      </UserAreaDiv>
    </Col>
    <IconCol sm="auto">
      <a href="/#/Help">
        <FontAwesomeIcon icon="question-circle" size="lg" />
      </a>
    </IconCol>
  </StyledRow>
);

const StyledRow = styled(Row)`
  margin-bottom: 2em;
`;

const IconCol = styled(Col)`
  padding-top: 13px;
`;

const UserAreaDiv = styled.div`
  float: right;
  text-align: right;
`;

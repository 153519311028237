import keyMirror from 'keymirror';
import axios from 'axios';
import { getAdminConfigVar } from '@avicennapharmacy/managemymeds-shared';

export const actionTypes = keyMirror({
  GET_SERVICE_NAMES: null
});

export const getServiceNames = async (): Promise<Action> => {
  const serviceNames = await axios.post(getAdminConfigVar('getServiceNamesEndpoint'));

  return {
    type: actionTypes.GET_SERVICE_NAMES,
    payload: serviceNames.data
  };
};

import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
  getAdminConfigVar,
  Resource,
  Service,
  ServiceBookingGetAvailabilityAPIResponse,
  formatDate,
  CalendarCustomHeader,
  getServiceStartDate,
  getDaysOfMonth,
  Availability,
  ServiceNamesLookup
} from '@avicennapharmacy/managemymeds-shared';
import axios, { AxiosResponse } from 'axios';
import Alert from 'react-bootstrap/Alert';
import {
  isBefore,
  addDays,
  isSameDay,
  parse,
  isValid,
  getMonth,
  getYear,
  startOfMonth,
  endOfMonth,
  addMonths
} from 'date-fns';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import Spinner from 'react-bootstrap/Spinner';
import { InputGroup } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import Container from 'react-bootstrap/Container';

const DatePickerContainer = styled(Container)`
  width: 100%;
  padding: 0px;
  .react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none !important;
  }
`;

const StyledDatePicker = styled(ReactDatePicker)`
  color: black;
  font-size: 16px;
  background-color: white;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  ::placeholder {
    color: black;
  }
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const spinner = (
  <div
    style={{
      margin: 10,
      display: 'flex',
      justifyContent: 'center'
    }}
  >
    <Spinner variant="primary" animation="border" role="status" />
  </div>
);

type NewBookingProps = {
  onHide: () => void;
  loadAllBookings: () => void;
  show: boolean;
  resources: Resource[];
  selectedResourceId: string | null;
  serviceNamesLookup: ServiceNamesLookup | null;
};

type GuestBookingRequest = {
  resourceId: string | null;
  serviceId: string;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  contactNumber: string | undefined;
  startDate: string | undefined;
  dateOfBirth: string | undefined;
  serviceSubType: string | undefined;
  serviceName: string | undefined;
};

const defaultBookingRequest = (): GuestBookingRequest => ({
  resourceId: null,
  serviceId: '',
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  contactNumber: undefined,
  startDate: undefined,
  dateOfBirth: undefined,
  serviceSubType: undefined,
  serviceName: undefined
});

const NewBookingModal = ({
  onHide,
  loadAllBookings,
  resources,
  selectedResourceId,
  show,
  serviceNamesLookup
}: NewBookingProps) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [services, setServices] = useState<Service[]>([]);
  const [servicesLoading, setServicesLoading] = useState(false);
  const [bookingRequest, setBookingRequest] = useState<GuestBookingRequest>(
    defaultBookingRequest()
  );
  const [availability, setAvailability] = useState<Availability>({});
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [dd, setDd] = useState<string>('');
  const [mm, setMm] = useState<string>('');
  const [yyyy, setYyyy] = useState<string>('');
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [hasAvailability, setHasAvailability] = useState(false);
  const [lastLoadedMonth, setLastLoadedMonth] = useState<Date | null>(null);
  const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        setServicesLoading(true);
        const url = getAdminConfigVar('serviceBookingGetServicesEndpoint');
        const result = await axios.post<any, AxiosResponse<{ result: Service[] }>>(url);
        setServices(result.data.result);
        setBookingRequest({
          ...bookingRequest,
          resourceId: selectedResourceId
        });
      } catch (err) {
        setError(true);
      } finally {
        setServicesLoading(false);
      }
    };

    if (show && !services.length) fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResourceId, show]);

  const getAvailability = async (dateFrom: Date) => {
    try {
      setLoading(true);

      if (lastLoadedMonth && isBefore(new Date(dateFrom), endOfMonth(lastLoadedMonth))) {
        setLoading(false);
        return;
      }

      const daysOfMonth = getDaysOfMonth(new Date(dateFrom));

      setDisabledDates(daysOfMonth);

      const url = getAdminConfigVar('serviceBookingGetAvailabilityEndpoint');
      const res = await axios.post<any, AxiosResponse<ServiceBookingGetAvailabilityAPIResponse>>(
        url,
        {
          resourceId: bookingRequest.resourceId,
          serviceId: bookingRequest.serviceId,
          dateFrom: dateFrom,
          dateTo: endOfMonth(new Date(dateFrom))
        }
      );

      res.data.days.map(({ availableDate }) => {
        const index = daysOfMonth.findIndex(x => isSameDay(x, new Date(availableDate)));
        if (index !== -1) {
          daysOfMonth.splice(index, 1);
        }
      });

      availability[`${getMonth(dateFrom)}${getYear(dateFrom)}`] = {
        availability: res.data.availability,
        days: res.data.days,
        unavailableDates: daysOfMonth
      };

      unavailableDates.push(...daysOfMonth);
      setUnavailableDates(unavailableDates);
      setAvailability(availability);

      setLastLoadedMonth(startOfMonth(new Date(dateFrom)));
      if (!hasAvailability) setHasAvailability(!!res.data.days.length);
    } catch {
      setError(true);
    } finally {
      setDisabledDates([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedResourceId && bookingRequest.serviceId) {
      getAvailability(fromDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    let service = services.filter(s => s.id === bookingRequest.serviceId)[0];
    setHasAvailability(false);
    setLastLoadedMonth(null);
    setAvailability({});
    setUnavailableDates([]);

    setBookingRequest({
      ...bookingRequest,
      resourceId: selectedResourceId
    });

    if (bookingRequest.resourceId && bookingRequest.serviceId) {
      setAvailability({});
      setSelectedService(service);
      if (
        !service.hasServiceSubTypes ||
        (service.hasServiceSubTypes && bookingRequest.serviceSubType)
      ) {
        let startDate = getServiceStartDate(service, bookingRequest?.serviceSubType ?? null);
        setFromDate(startDate);
      }
    }
  }, [
    bookingRequest.resourceId,
    bookingRequest.serviceId,
    bookingRequest.serviceSubType,
    services
  ]);

  const resource = resources.find(r => r.id === selectedResourceId);

  const closeModal = () => {
    setBookingRequest(defaultBookingRequest());
    setDd('');
    setMm('');
    setYyyy('');
    setAvailability({});
    setSelectedDay(null);
    setSelectedService(null);
    onHide();
  };

  const filterAvailableServices = (s: Service) => {
    const filterStartDate = addDays(
      new Date(),
      s?.daysBookableInAdvance ? s?.daysBookableInAdvance : 0
    );
    return (
      s.active &&
      s.resourceId === selectedResourceId &&
      (!s.endDt || isBefore(filterStartDate, new Date(s.endDt)))
    );
  };

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const monthChange = (date: Date, compensate: number) => {
    if (selectedService) {
      const serviceStartDate = getServiceStartDate(
        selectedService,
        bookingRequest.serviceSubType ? bookingRequest.serviceSubType : null
      );
      const calendarDate = addMonths(date, compensate);
      const dateFrom = isBefore(calendarDate, serviceStartDate)
        ? serviceStartDate
        : startOfMonth(calendarDate);
      setFromDate(dateFrom);
      getAvailability(dateFrom);
    }
  };

  return (
    <>
      <Modal show={show} backdrop="static">
        <Modal.Header>
          <Modal.Title>Add new booking {resource && `- ${resource.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">An error has occurred</Alert>}
          <Form>
            {servicesLoading ? (
              <>
                <Form.Label className="mr-3"> Loading Services </Form.Label>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              </>
            ) : (
              <Form.Group>
                <Form.Label>
                  Service:{' '}
                  {selectedService && serviceNamesLookup
                    ? serviceNamesLookup[selectedService.serviceNameId].name[1]
                    : null}
                  <br />
                  {selectedService?.startDt &&
                    `Start date: ${formatDate(selectedService?.startDt, 'date')}`}
                  {selectedService?.endDt &&
                    ` End date: ${formatDate(selectedService?.endDt, 'date')}`}
                  {selectedService?.daysBookableInAdvance && <br /> &&
                    ` DaysBookableInAdvance: ${selectedService.daysBookableInAdvance}`}
                </Form.Label>
                <Form.Control
                  disabled={saving}
                  as="select"
                  value={bookingRequest.serviceId ?? undefined}
                  onChange={(e: any) => {
                    setBookingRequest({
                      ...bookingRequest,
                      serviceId: e.target.value,
                      startDate: undefined,
                      serviceSubType: undefined
                    });
                    setSelectedDay(null);
                  }}
                >
                  <option value="">Select a service...</option>
                  {services
                    .filter(s => filterAvailableServices(s))
                    .map(s => (
                      <option key={s.id} value={s.id}>
                        {serviceNamesLookup ? serviceNamesLookup[s.serviceNameId].name[0] : ''}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}
            <Form.Group>
              {selectedService?.hasServiceSubTypes && (
                <Form.Control
                  disabled={saving}
                  as="select"
                  value={bookingRequest.serviceSubType ?? ''}
                  onChange={(e: any) => {
                    setBookingRequest({
                      ...bookingRequest,
                      startDate: undefined,
                      serviceSubType: e.target.value
                    });
                    setSelectedDay(null);
                  }}
                >
                  <option value="">Select a service sub type...</option>
                  {selectedService.subServices?.map(subtype => (
                    <option key={subtype.serviceNameId} value={subtype.serviceNameId}>
                      {subtype.service}
                    </option>
                  ))}
                </Form.Control>
              )}
            </Form.Group>
            {selectedService && (
              <Form.Group>
                <Form.Label>
                  Date
                  <br />
                  {`Filtering from: ${formatDate(
                    getServiceStartDate(
                      selectedService,
                      bookingRequest.serviceSubType ? bookingRequest.serviceSubType : null
                    ),
                    'date'
                  )} ${
                    selectedService.endDt ? `to: ` + formatDate(selectedService.endDt, 'date') : ``
                  }`}
                </Form.Label>
                <DatePickerContainer>
                  <StyledDatePicker
                    renderCustomHeader={({
                      date,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled
                    }: CalendarCustomHeader) => (
                      <>
                        <div
                          style={{
                            margin: 10,
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <button
                            style={{
                              border: 'none',
                              margin: '0px 30px',
                              fontSize: 16,
                              background: 'none'
                            }}
                            onClick={() => {
                              decreaseMonth();
                              monthChange(date, -1);
                            }}
                            type="button"
                            disabled={loading || prevMonthButtonDisabled}
                          >
                            {'<'}
                          </button>
                          {months[getMonth(date)] + ' ' + getYear(date)}

                          <button
                            style={{
                              border: 'none',
                              margin: '0px 30px',
                              fontSize: 16,
                              background: 'none'
                            }}
                            onClick={() => {
                              increaseMonth();
                              monthChange(date, 1);
                            }}
                            type="button"
                            disabled={loading || nextMonthButtonDisabled}
                          >
                            {'>'}
                          </button>
                        </div>
                        {loading && spinner}
                      </>
                    )}
                    dateFormat="dd/MM/yyyy"
                    disabled={
                      saving ||
                      !selectedService ||
                      (!!selectedService.hasServiceSubTypes && !bookingRequest.serviceSubType)
                    }
                    placeholderText="Select date..."
                    wrapperClassName="container paddingless"
                    showPopperArrow={false}
                    selected={selectedDay}
                    minDate={
                      selectedService
                        ? getServiceStartDate(
                            selectedService,
                            bookingRequest.serviceSubType ? bookingRequest.serviceSubType : null
                          )
                        : undefined
                    }
                    maxDate={selectedService.endDt ? new Date(selectedService.endDt) : undefined}
                    excludeDates={loading ? disabledDates : unavailableDates}
                    onChange={(date: any) => {
                      setBookingRequest({
                        ...bookingRequest,
                        startDate: undefined
                      });
                      setSelectedDay(date);
                    }}
                  />
                </DatePickerContainer>
              </Form.Group>
            )}
            {selectedDay && availability[`${getMonth(selectedDay)}${getYear(selectedDay)}`] && (
              <Form.Group>
                <Form.Label>Time</Form.Label>
                <Form.Control
                  disabled={saving}
                  as="select"
                  value={bookingRequest.startDate}
                  onChange={(e: any) =>
                    setBookingRequest({
                      ...bookingRequest,
                      startDate: e.target.value
                    })
                  }
                >
                  <option value="">Select a time...</option>
                  {availability[`${getMonth(selectedDay)}${getYear(selectedDay)}`] &&
                    availability[`${getMonth(selectedDay)}${getYear(selectedDay)}`].availability
                      .filter(
                        a => a.isAvailable && isSameDay(new Date(selectedDay), new Date(a.start))
                      )
                      .map(a => (
                        <option key={a.start} value={a.start}>
                          {formatDate(a.start, 'time')}
                        </option>
                      ))}
                </Form.Control>
              </Form.Group>
            )}
            {bookingRequest.startDate && (
              <>
                <Form.Group>
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    disabled={saving}
                    type="text"
                    value={bookingRequest.firstName}
                    onChange={(e: any) =>
                      setBookingRequest({ ...bookingRequest, firstName: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    disabled={saving}
                    type="text"
                    value={bookingRequest.lastName}
                    onChange={(e: any) =>
                      setBookingRequest({ ...bookingRequest, lastName: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Date of birth</Form.Label>
                  <Form.Row>
                    <Col xs={2}>
                      <Form.Control
                        disabled={saving}
                        type="number"
                        value={dd}
                        placeholder="dd"
                        onChange={(e: any) => setDd(e.target.value)}
                      />
                    </Col>
                    <Col xs={2}>
                      <Form.Control
                        disabled={saving}
                        type="number"
                        value={mm}
                        placeholder="mm"
                        onChange={(e: any) => setMm(e.target.value)}
                      />
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        disabled={saving}
                        type="number"
                        value={yyyy}
                        placeholder="yyyy"
                        onChange={(e: any) => setYyyy(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <InputGroup>
                    <Form.Control
                      disabled={saving}
                      type="text"
                      value={bookingRequest.email}
                      isInvalid={
                        !isValidEmail(bookingRequest.email ?? '') &&
                        bookingRequest?.email?.length! > 0
                      }
                      onChange={(e: any) =>
                        setBookingRequest({ ...bookingRequest, email: e.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid" role="alert">
                      Please enter a valid email address.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    disabled={saving}
                    type="text"
                    value={bookingRequest.contactNumber}
                    onChange={(e: any) =>
                      setBookingRequest({ ...bookingRequest, contactNumber: e.target.value })
                    }
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              try {
                setSaving(true);
                const url = getAdminConfigVar('serviceBookingCreateGuestBookingEndpoint');
                await axios.post<any>(url, {
                  ...bookingRequest,
                  dateOfBirth: `${yyyy}-${mm}-${dd}`,
                  serviceName: selectedService?.name,
                  ServiceSubType:
                    bookingRequest.serviceSubType && serviceNamesLookup && selectedService
                      ? serviceNamesLookup[selectedService.serviceNameId].subs[
                          bookingRequest.serviceSubType
                        ].name
                      : ''
                });
                await loadAllBookings();
              } catch (err) {
                setError(true);
              } finally {
                setSaving(false);
                closeModal();
              }
            }}
            disabled={
              saving ||
              !bookingRequest.serviceId ||
              !bookingRequest.resourceId ||
              !bookingRequest.startDate ||
              !bookingRequest.firstName ||
              !bookingRequest.lastName ||
              !bookingRequest.email ||
              !bookingRequest.contactNumber ||
              !dd ||
              !mm ||
              !yyyy ||
              !isValid(parse(`${yyyy}-${mm}-${dd}`, 'yyyy-MM-dd', new Date())) ||
              !isValidEmail(bookingRequest.email) ||
              (selectedService?.hasServiceSubTypes === true &&
                !bookingRequest.serviceSubType?.length)
            }
          >
            {saving ? 'Saving...' : 'Add booking'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewBookingModal;

import keyMirror from 'keymirror';
import {
  BookingWithPatient,
  getAdminConfigVar,
  formatDate,
  Resource
} from '@avicennapharmacy/managemymeds-shared';
import axios, { AxiosResponse } from 'axios';

export const actionTypes = keyMirror({
  SET_LOADING_BOOKINGS: null,
  SET_LOADING_BOOKINGS_COMPLETE: null,
  SET_SELECTED_RESOURCE: null,
  SET_LOADED_PHARMACY: null,
  SET_LAST_LOADED_MONTH: null,
  LOAD_BOOKINGS_FOR_RESOURCE: null,
  LOAD_RESOURCES: null,
  RESET_DIARY: null,
  LOAD_BOOKINGS: null,
  SELECT_BOOKING: null,
  CLEAR_SELECTED_BOOKINGS: null
});

export const setLoadingBookings = (loading: boolean = true): Action => ({
  type: actionTypes.SET_LOADING_BOOKINGS,
  payload: loading
});

export const setLoadingBookingsComplete = (loading: boolean = false): Action => ({
  type: actionTypes.SET_LOADING_BOOKINGS_COMPLETE,
  payload: loading
});

export const loadResources = async (): Promise<Action> => {
  const { data } = await axios.post<any, AxiosResponse<{ result: Resource[] }>>(
    getAdminConfigVar('serviceBookingGetResourcesEndpoint')
  );
  return {
    type: actionTypes.LOAD_RESOURCES,
    payload: data.result
  };
};

export const resetDiary = (): Action => ({
  type: actionTypes.RESET_DIARY
});

export const loadBookingsForResource = async ({
  pharmacyId,
  resourceId,
  fromDate,
  toDate,
  clear = false
}: any): Promise<Action> => {
  const { data } = await axios.post<any, AxiosResponse<{ result: BookingWithPatient[] }>>(
    getAdminConfigVar('getPharmacyBookingsEndpoint'),
    {
      fromDate: formatDate(fromDate, 'dob'),
      ToDate: toDate.toISOString(),
      ExternalOwnerId: pharmacyId,
      ResourceId: resourceId
    }
  );
  const result = {
    bookings: data.result,
    resourceId,
    clear
  };
  return {
    type: actionTypes.LOAD_BOOKINGS_FOR_RESOURCE,
    payload: result
  };
};

export const setSelectedResource = (id: string | null): Action => {
  return {
    type: actionTypes.SET_SELECTED_RESOURCE,
    payload: { id }
  };
};

export const setLoadedPharmacy = (id: string | null): Action => {
  return {
    type: actionTypes.SET_LOADED_PHARMACY,
    payload: { id }
  };
};

export const setLastLoadedMonth = (month: string | null): Action => {
  return {
    type: actionTypes.SET_LAST_LOADED_MONTH,
    payload: { month }
  };
};

export const selectBooking = (id: string): Action => ({
  type: actionTypes.SELECT_BOOKING,
  payload: id
});

export const clearSelectedBookings = (): Action => ({
  type: actionTypes.CLEAR_SELECTED_BOOKINGS
});

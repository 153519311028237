import { connect } from 'react-redux';
import { GlobalState } from '../../../reducers';
import { getSelectedGroup } from '../../../selectors/user';
import Settings from './';

const mapStateToProps = (state: GlobalState) => ({
  group: getSelectedGroup(state)
});

export default connect(mapStateToProps)(Settings);

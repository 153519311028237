import keyMirror from 'keymirror';
import axios from 'axios';
import addDays from 'date-fns/addDays';
import {
  Order,
  REJECTED,
  COLLECTED,
  CANCELLED_IM1,
  getAdminConfigVar
} from '@avicennapharmacy/managemymeds-shared';

export const actionTypes = keyMirror({
  SET_LOADING_ORDERS: null,
  LOAD_ORDERS: null,
  LOAD_ERD_SURVEYS: null,
  LOAD_REJECTED_ORDERS: null,
  LOAD_COLLECTED_ORDERS: null,
  SELECT_ORDER: null,
  SELECT_ALL_ORDERS: null,
  SET_MODAL_ORDER: null,
  SET_SEARCH_STRING: null,
  SET_ORDER_SORT: null,
  DESELECT_ALL_ORDERS: null,
  CLEAR_ALL_ORDERS: null
});

export const setLoadingOrders = (loading: boolean = true): Action => ({
  type: actionTypes.SET_LOADING_ORDERS,
  payload: loading
});

export const loadOrders = async (statuses: string[], rangeFrom?: Date): Promise<Action> => {
  const { data } = await axios.post(getAdminConfigVar('ordersListEndpoint'), {
    statuses,
    rangeFrom,
    logPharmacyAccess: true
  });
  return {
    type: actionTypes.LOAD_ORDERS,
    payload: data
  };
};

export const loadErdSurveys = async (): Promise<Action> => {
  const { data } = await axios.post(getAdminConfigVar('erdSurveyEndpoint'));
  return {
    type: actionTypes.LOAD_ERD_SURVEYS,
    payload: data
  };
};

export const loadRejectedOrders = async (): Promise<Action> => {
  const { data } = await axios.post(getAdminConfigVar('ordersListEndpoint'), {
    statuses: [REJECTED, CANCELLED_IM1],
    rangeFrom: addDays(new Date(), -84)
  });
  return {
    type: actionTypes.LOAD_REJECTED_ORDERS,
    payload: data
  };
};

export const loadCollectedOrders = async (
  offset?: number,
  limit?: number,
  orderByDesc?: boolean
): Promise<Action> => {
  const url = getAdminConfigVar('ordersListEndpoint');
  const { data } = await axios.post(
    `${url}?offset=${offset}&limit=${limit}&orderbydesc=${orderByDesc}`,
    {
      statuses: [COLLECTED],
      rangeFrom: addDays(new Date(), -84)
    }
  );
  return {
    type: actionTypes.LOAD_COLLECTED_ORDERS,
    payload: data
  };
};

export const setSearchString = (searchString: string): Action => ({
  type: actionTypes.SET_SEARCH_STRING,
  payload: searchString
});

export const setOrderSort = (sortFn: (a: Order, b: Order) => number): Action => ({
  type: actionTypes.SET_ORDER_SORT,
  payload: sortFn
});

export const setModalOrder = (orderId: string | null): Action => ({
  type: actionTypes.SET_MODAL_ORDER,
  payload: orderId
});

export const selectOrder = (id: string): Action => ({
  type: actionTypes.SELECT_ORDER,
  payload: id
});

export const selectAllOrders = (statuses: string[]): Action => ({
  type: actionTypes.SELECT_ALL_ORDERS,
  payload: statuses
});

export const deselectAllOrders = (): Action => ({
  type: actionTypes.DESELECT_ALL_ORDERS
});

export const clearOrders = (): Action => ({
  type: actionTypes.CLEAR_ALL_ORDERS
});

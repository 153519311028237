import {
  AdHocOpeningTimes,
  capitalize,
  getAdminConfigVar,
  PharmacyOpeningTimes,
  ServiceNamesLookup,
  ServiceName
} from '@avicennapharmacy/managemymeds-shared';
import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DatePicker from 'react-datepicker';
import Spinner from 'react-bootstrap/Spinner';
import Axios from 'axios';
import OffersTab from './OffersTab';
import BulkServiceInsertTab from './BulkServiceInsertTab';
import BulkPushNotificationSendTab from './BulkPushNotificationSendTab';

const CUSTOM_LOGO = 'Custom Logo';
const OPENING_HOURS = 'Opening Hours';
const CLOSED_DATES = 'Closed Dates';
const OFFERS = 'Offers';
const BULK_SERVICE_INSERT = 'Bulk Service Insert';
const BULK_PUSH_NOTIFICATION = 'Bulk Push Notification';

export type BulkEditModalProps = {
  pharmacyIds: string[];
  show?: boolean;
  setShow: (show: boolean) => void;
  setShowSuccess?: (show: boolean) => void;
  setSuccessModalTitle?: (title: string) => void;
  setSuccessModalBody?: (body: string) => void;
  getServiceNames: () => void;
  selectedGroupId?: string | null;
  serviceNamesLookup: ServiceNamesLookup | null;
  serviceNames: ServiceName[] | null;
};

type DayOfWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export default ({
  setShow,
  setShowSuccess,
  setSuccessModalTitle,
  setSuccessModalBody,
  getServiceNames,
  pharmacyIds,
  show,
  selectedGroupId,
  serviceNamesLookup,
  serviceNames
}: BulkEditModalProps) => {
  const [loading, setLoading] = useState(false);
  const [serviceNamesLoading, setServiceNamesLoading] = useState(false);
  const [customLogoAction, setCustomLogoAction] = useState('');
  const [openingHoursAction, setOpeningHoursAction] = useState(false);
  const [closedDatesAction, setClosedDatesAction] = useState(false);
  const [times, setTimes] = useState<PharmacyOpeningTimes>({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  });
  const [adHocDates, setAdHocDates] = useState<AdHocOpeningTimes[]>([]);

  const updateTime = (
    day: DayOfWeek,
    intervalIndex: number,
    point: 'from' | 'to',
    newTime: string
  ) => {
    const newTimes = Array.from(times[day]);
    newTimes[intervalIndex][point] = newTime;

    setTimes({
      ...times,
      [day]: newTimes
    });
  };

  const fetchServiceNames = async () => {
    await getServiceNames();
    setServiceNamesLoading(false);
  };

  useEffect(() => {
    setServiceNamesLoading(true);
    fetchServiceNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || serviceNamesLoading) {
    return (
      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Applying changes...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Spinner variant="primary" animation="border" role="status" />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Bulk Edit Pharmacies</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Use this tool to manage your group's pharmacies in bulk.</p>
        <Tabs defaultActiveKey={CUSTOM_LOGO} id="service-configuration-tab" className="my-3">
          <Tab eventKey={CUSTOM_LOGO} title={CUSTOM_LOGO} className="my-3 mx-2">
            {customLogoAction !== 'off' && (
              <p>
                <Button className="mt-2" onClick={() => setCustomLogoAction('on')}>
                  Turn <strong>on</strong> for all pharmacies
                </Button>
              </p>
            )}
            {customLogoAction === 'on' && (
              <>
                Are you sure?{' '}
                <Button
                  variant="danger"
                  onClick={async () => {
                    setLoading(true);
                    await Axios.post(getAdminConfigVar('bulkUpdateCustomLogoEndpoint'), {
                      pharmacyIds,
                      allowCustomLogo: true
                    });
                    setLoading(false);
                  }}
                >
                  Yes
                </Button>{' '}
                <Button variant="secondary" onClick={() => setCustomLogoAction('')}>
                  No
                </Button>
              </>
            )}
            {customLogoAction !== 'on' && (
              <p>
                <Button variant="secondary" className="mt-2">
                  Turn <strong>off</strong> for all pharmacies
                </Button>
              </p>
            )}
            {customLogoAction === 'off' && (
              <>
                Are you sure?{' '}
                <Button
                  variant="danger"
                  onClick={async () => {
                    setLoading(true);
                    await Axios.post(getAdminConfigVar('bulkUpdateCustomLogoEndpoint'), {
                      pharmacyIds,
                      allowCustomLogo: false
                    });
                    setLoading(false);
                  }}
                >
                  Yes
                </Button>{' '}
                <Button variant="secondary" onClick={() => setCustomLogoAction('')}>
                  No
                </Button>
              </>
            )}
          </Tab>
          <Tab eventKey={OPENING_HOURS} title={OPENING_HOURS} className="my-3 mx-2">
            <Alert variant="warning">
              These changes will override custom opening times for the selected pharmacies.
            </Alert>
            {Object.entries(times).map(([day, dayTimes], i) => {
              if (!dayTimes.length) {
                return (
                  <Form.Group key={day} as={Row} className={i % 2 === 0 ? 'bg-light py-2' : 'py-2'}>
                    <Form.Label column sm={2}>
                      {capitalize(day)}
                    </Form.Label>
                    <Col sm={8}>
                      <Row>
                        <Form.Label column sm={1}>
                          Closed:
                        </Form.Label>
                        <Col sm={2}>
                          <Form.Control
                            type="checkbox"
                            style={{ width: 'auto' }}
                            checked={dayTimes.length === 0}
                            onChange={() =>
                              setTimes({
                                ...times,
                                [day]: [
                                  {
                                    from: '09:00',
                                    to: '17:00'
                                  }
                                ]
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                );
              }

              return (
                <Form.Group key={day} as={Row} className={i % 2 === 0 ? 'bg-light py-2' : 'py-2'}>
                  <Form.Label column sm={2}>
                    {day.charAt(0).toLocaleUpperCase() + day.slice(1)}
                  </Form.Label>
                  <Col sm={8}>
                    <Row>
                      <Form.Label column sm={1}>
                        Closed:
                      </Form.Label>
                      <Col sm={2}>
                        <Form.Control
                          type="checkbox"
                          style={{ width: 'auto' }}
                          checked={dayTimes.length === 0}
                          onChange={() =>
                            setTimes({
                              ...times,
                              [day]: []
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      {dayTimes.map((t, i) => (
                        <Col key={i} sm={8}>
                          <Row>
                            <Form.Label column sm={4}>
                              From:
                            </Form.Label>
                            <Col sm={8}>
                              <Form.Control
                                type="time"
                                min="0:00"
                                max={t.to}
                                value={t.from}
                                onChange={(e: any) =>
                                  updateTime(day as DayOfWeek, i, 'from', e.target.value)
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Form.Label column sm={4}>
                              To:
                            </Form.Label>
                            <Col sm={8}>
                              <Form.Control
                                type="time"
                                min={t.from}
                                max="23:59"
                                value={t.to}
                                onChange={(e: any) =>
                                  updateTime(day as DayOfWeek, i, 'to', e.target.value)
                                }
                              />
                            </Col>
                          </Row>
                          {i === 0 && (
                            <Row className="mt-3 mb-2 ml-1">
                              {dayTimes.length === 1 ? (
                                <Button
                                  size="sm"
                                  variant="outline-primary"
                                  onClick={() =>
                                    setTimes({
                                      ...times,
                                      [day]: [
                                        ...times[day as DayOfWeek],
                                        {
                                          from: times[day as DayOfWeek][0].to,
                                          to: '20:00'
                                        }
                                      ]
                                    })
                                  }
                                >
                                  Add second opening slot
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  variant="outline-danger"
                                  onClick={() =>
                                    setTimes({
                                      ...times,
                                      [day]: times[day as DayOfWeek].filter((p, i) => i < 1)
                                    })
                                  }
                                >
                                  Remove second opening slot
                                </Button>
                              )}
                              <Button
                                size="sm"
                                className="ml-4"
                                variant="outline-primary"
                                onClick={() => {
                                  const json = JSON.stringify(times[day as DayOfWeek]);
                                  setTimes({
                                    ...times,
                                    monday: JSON.parse(json),
                                    tuesday: JSON.parse(json),
                                    wednesday: JSON.parse(json),
                                    thursday: JSON.parse(json),
                                    friday: JSON.parse(json),
                                    saturday: JSON.parse(json),
                                    sunday: JSON.parse(json)
                                  });
                                }}
                              >
                                Copy to other days
                              </Button>
                            </Row>
                          )}
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Form.Group>
              );
            })}
            <p>
              <Button className="mt-2" onClick={() => setOpeningHoursAction(true)}>
                Apply changes
              </Button>
            </p>
            {openingHoursAction && (
              <>
                Are you sure?{' '}
                <Button
                  variant="danger"
                  onClick={async () => {
                    setLoading(true);
                    await Axios.post(getAdminConfigVar('bulkUpdateOpeningHoursEndpoint'), {
                      pharmacyIds,
                      pharmacyOpeningTimes: times
                    });
                    setLoading(false);
                  }}
                >
                  Yes
                </Button>{' '}
                <Button variant="secondary" onClick={() => setOpeningHoursAction(false)}>
                  No
                </Button>
              </>
            )}
          </Tab>
          <Tab eventKey={CLOSED_DATES} title={CLOSED_DATES} className="my-3 mx-2">
            <Row className="mt-5">
              <Col sm={4}>
                <h4 className="mb-4">Bank Holidays</h4>
              </Col>
              <Col sm={8}>
                <Button
                  variant="primary"
                  onClick={() => {
                    setAdHocDates([
                      {
                        adHocDt: new Date().toISOString(),
                        closed: true,
                        times: [
                          {
                            from: '09:00',
                            to: '17:00'
                          }
                        ]
                      },
                      ...adHocDates
                    ]);
                  }}
                >
                  Add new date
                </Button>
              </Col>
            </Row>
            {adHocDates.map((opening: AdHocOpeningTimes, i: number) =>
              mapBankHoliday(opening, i, adHocDates, setAdHocDates)
            )}
            {adHocDates.length > 0 && (
              <p>
                <Button className="mt-2" onClick={() => setClosedDatesAction(true)}>
                  Apply changes
                </Button>
              </p>
            )}

            {closedDatesAction && (
              <>
                Are you sure?{' '}
                <Button
                  variant="danger"
                  onClick={async () => {
                    setLoading(true);
                    await Axios.post(getAdminConfigVar('bulkUpdateClosedDatesEndpoint'), {
                      pharmacyIds,
                      adHocOpeningTimes: adHocDates
                    });
                    setLoading(false);
                  }}
                >
                  Yes
                </Button>{' '}
                <Button variant="secondary" onClick={() => setClosedDatesAction(false)}>
                  No
                </Button>
              </>
            )}
          </Tab>
          {process.env.REACT_APP_FEATURE_BULK_OFFERS === 'true' && (
            <Tab eventKey={OFFERS} title={OFFERS} className="my-3 mx-2">
              <OffersTab pharmacyIds={pharmacyIds} setShow={setShow} />
            </Tab>
          )}
          <Tab eventKey={BULK_SERVICE_INSERT} title={BULK_SERVICE_INSERT} className="my-3 mx-2">
            <BulkServiceInsertTab
              pharmacyIds={pharmacyIds}
              setShow={setShow}
              setShowSuccess={setShowSuccess}
              setSuccessModalTitle={setSuccessModalTitle}
              setSuccessModalBody={setSuccessModalBody}
              selectedGroupId={selectedGroupId}
              serviceNamesLookup={serviceNamesLookup}
              serviceNames={serviceNames}
            />
          </Tab>
          <Tab
            eventKey={BULK_PUSH_NOTIFICATION}
            title={BULK_PUSH_NOTIFICATION}
            className="my-3 mx-2"
          >
            <BulkPushNotificationSendTab
              pharmacyIds={pharmacyIds}
              setShow={setShow}
              setShowSuccess={setShowSuccess}
              setSuccessModalTitle={setSuccessModalTitle}
              setSuccessModalBody={setSuccessModalBody}
              selectedGroupId={selectedGroupId}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapBankHoliday = (
  adHoc: AdHocOpeningTimes,
  i: number,
  adHocDates: AdHocOpeningTimes[],
  setAdHocDates: (adHocDates: AdHocOpeningTimes[]) => void
) => (
  <Form.Group key={i} as={Row} className={i % 2 === 0 ? 'bg-light py-2' : 'py-2'}>
    <Col sm={8}>
      <Row>
        <Form.Label column sm={1}>
          Date:
        </Form.Label>
        <Col sm={3}>
          <DatePicker
            required
            dateFormat="dd/MM/yyyy"
            selected={new Date(adHoc.adHocDt)}
            onChange={(date: Date) => {
              if (date) {
                const newArr = Array.from(adHocDates);
                newArr[i] = {
                  ...adHocDates[i],
                  adHocDt: date.toISOString()
                };
                setAdHocDates(newArr);
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={1}>
          Closed:
        </Form.Label>
        <Col sm={2}>
          <Form.Control
            type="checkbox"
            checked={adHoc.closed}
            style={{ width: 'auto' }}
            onChange={() => {
              const newArr = Array.from(adHocDates);
              newArr[i] = {
                ...adHocDates[i],
                closed: !adHocDates[i].closed
              };
              setAdHocDates(newArr);
            }}
          />
        </Col>
      </Row>
      {!adHocDates[i].closed && (
        <Row>
          {adHoc.times.map((t, timeIdx) => (
            <Col key={timeIdx} sm={3}>
              <Row>
                <Form.Label column sm={4}>
                  From:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="time"
                    min="0:00"
                    max={t.to}
                    value={t.from}
                    onChange={(e: any) => {
                      const newArr = Array.from(adHocDates);
                      const newTimes = Array.from(newArr[i].times);
                      newTimes[timeIdx].from = e.target.value;
                      newArr[i] = {
                        ...adHocDates[i],
                        times: newTimes
                      };
                      setAdHocDates(newArr);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Form.Label column sm={4}>
                  To:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="time"
                    min={t.from}
                    max="23:59"
                    value={t.to}
                    onChange={(e: any) => {
                      const newArr = Array.from(adHocDates);
                      const newTimes = Array.from(newArr[i].times);
                      newTimes[timeIdx].to = e.target.value;
                      newArr[i] = {
                        ...adHocDates[i],
                        times: newTimes
                      };
                      setAdHocDates(newArr);
                    }}
                  />
                </Col>
              </Row>
              {timeIdx === 0 && (
                <Row className="mt-3 mb-2 ml-1">
                  {adHoc.times.length === 1 ? (
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        const newArr = Array.from(adHocDates);
                        const newTimes = Array.from(newArr[i].times);
                        newTimes.push({
                          from: newTimes[0].to,
                          to: '20:00'
                        });
                        newArr[i] = {
                          ...adHocDates[i],
                          times: newTimes
                        };
                        setAdHocDates(newArr);
                      }}
                    >
                      Add second opening slot
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => {
                        const newArr = Array.from(adHocDates);
                        const newTimes = Array.from(newArr[i].times);
                        newArr[i] = {
                          ...adHocDates[i],
                          times: [newTimes[0]]
                        };
                        setAdHocDates(newArr);
                      }}
                    >
                      Remove second opening slot
                    </Button>
                  )}
                </Row>
              )}
            </Col>
          ))}
        </Row>
      )}
      <Row className="mt-3 mb-2 ml-1">
        <Button
          size="sm"
          variant="danger"
          onClick={() => {
            const newArr = adHocDates.filter((_: any, fIdx: number) => i !== fIdx);
            setAdHocDates(newArr);
          }}
        >
          Remove date
        </Button>
      </Row>
    </Col>
  </Form.Group>
);

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PageContainer from './layout/PageContainer';
import Header from './Header.container';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Row from './layout/Row';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Spinner from 'react-bootstrap/Spinner';
import {
  Pharmacy,
  getAdminConfigVar,
  Owner,
  PharmacyGroup
} from '@avicennapharmacy/managemymeds-shared';

type DashboardProps = {
  pharmacy?: Pharmacy | null | undefined;
  group?: PharmacyGroup | null | undefined;
  owner?: Owner | null;
  loadOwner?: (callback?: Function) => void;
  loadedOwner?: boolean;
  pharmacyId?: string | null;
};

type StatsResponse = {
  newOrdersCount: number;
  newERDOrdersCount: number;
  withGPOrdersCount: number;
  stuckOrdersWithPharmacyCount: number;
  stuckOrdersWithGPCount: number;
  usersCount: number;
  newUsersCount: number;
  im1UsersCount: number;
  accountsCount: number;
  newAccountsCount: number;
  withPharmacyCount: number;
};

const Dashboard = ({
  pharmacy,
  group,
  owner,
  loadOwner,
  loadedOwner,
  pharmacyId
}: DashboardProps) =>
  pharmacy ? (
    <PharmacyDashboard
      pharmacy={pharmacy}
      owner={owner}
      loadOwner={loadOwner}
      loadedOwner={loadedOwner}
      pharmacyId={pharmacyId}
    />
  ) : (
    <GroupDashboard group={group} />
  );

const PharmacyDashboard = ({ pharmacy, owner, loadOwner, loadedOwner }: DashboardProps) => {
  const [stats, setStats] = useState<StatsResponse | null>(null);
  const [hasServiceBooking, setHasServiceBooking] = useState(false);
  const [canEditServiceBooking, setCanEditServiceBooking] = useState(false);

  useEffect(() => {
    const getStats = async () => {
      const statsRes = await axios.post<StatsResponse>(getAdminConfigVar('portalStatsEndpoint'));
      setStats(statsRes.data);
    };
    getStats();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loadOwner && !loadedOwner) loadOwner();
  }, [loadedOwner]);

  useEffect(() => {
    setHasServiceBooking(Boolean(owner) && Boolean(owner?.active) && Boolean(pharmacy?.groupId));
    setCanEditServiceBooking(Boolean(owner) && Boolean(pharmacy?.groupId));
  }, [owner]);

  return (
    <PageContainer>
      <Header />
      <Row>
        <Col sm="4">
          <ToolsJumbo>
            <DashboardHeader>Tools</DashboardHeader>
            <StyledButtonGroup vertical size="lg">
              <StyledButton
                href={`/#/Orders/${pharmacy?.allowManualReordering ? 'New' : 'Raised'}`}
              >
                Orders
              </StyledButton>
              <StyledButton href="/#/Patients">Patients</StyledButton>
              {!loadedOwner && (
                <StyledButton href="/#/">
                  <Spinner variant="light" animation="border" role="status" />
                </StyledButton>
              )}
              {getAdminConfigVar('featurePharmacyServiceBooking')?.toLowerCase() === 'true' &&
                hasServiceBooking && (
                  <>
                    <StyledButton href="/#/Diary">Service Diary</StyledButton>
                  </>
                )}
              {getAdminConfigVar('featurePharmacyServiceBooking')?.toLowerCase() === 'true' &&
                canEditServiceBooking && (
                  <>
                    <StyledButton href="/#/Services">Service Booking Configuration</StyledButton>
                  </>
                )}
              <StyledButton href="/#/Details">Edit Pharmacy Details</StyledButton>
              <StyledButton href="/#/Help">Help</StyledButton>
            </StyledButtonGroup>
          </ToolsJumbo>
        </Col>
        <Col sm="8">
          <StatsJumbo>
            <DashboardHeader>{pharmacy?.businessName}</DashboardHeader>
            {stats ? (
              <>
                {pharmacy?.allowManualReordering && (
                  <a href="/#/Orders/New">
                    <StatHeader>
                      <Stat>{stats.newOrdersCount}</Stat> new orders
                    </StatHeader>
                  </a>
                )}
                <a href="/#/Orders/NewRepeatDispensing">
                  <StatHeader>
                    <Stat>{stats.newERDOrdersCount}</Stat> new ERD orders
                  </StatHeader>
                </a>
                <a href="/#/Orders/Raised">
                  <StatHeader>
                    <Stat>{stats.withGPOrdersCount}</Stat> orders with GPs
                  </StatHeader>
                </a>
                <a href="/#/Orders/Raised">
                  <StatHeader>
                    <Stat>{stats.stuckOrdersWithGPCount}</Stat> orders stuck with GP
                  </StatHeader>
                </a>
                <a href="/#/Orders/GPApproved">
                  <StatHeader>
                    <Stat>{stats.withPharmacyCount}</Stat> with pharmacy
                  </StatHeader>
                </a>
                <a href="/#/Orders/GPApproved">
                  <StatHeader>
                    <Stat>{stats.stuckOrdersWithPharmacyCount}</Stat> orders stuck with pharmacy
                  </StatHeader>
                </a>
                <a href="/#/Patients">
                  <StatHeader>
                    <Stat>{stats.newUsersCount}</Stat> new patients this week
                  </StatHeader>
                </a>
                <a href="/#/Patients">
                  <StatHeader>
                    <Stat>{stats.usersCount}</Stat> total patients
                  </StatHeader>
                </a>
                <StatHeader>
                  <Stat>{stats.im1UsersCount}</Stat> patients connected to GP
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.newAccountsCount}</Stat> new user accounts this week
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.accountsCount}</Stat> total user accounts
                </StatHeader>
              </>
            ) : (
              <Spinner variant="primary" animation="border" role="status" />
            )}
          </StatsJumbo>
        </Col>
      </Row>
    </PageContainer>
  );
};

const GroupDashboard = ({ group }: DashboardProps) => {
  const [stats, setStats] = useState<StatsResponse | null>(null);

  useEffect(() => {
    const getStats = async () => {
      const res = await axios.post<StatsResponse>(getAdminConfigVar('portalStatsEndpoint'));
      setStats(res.data);
    };
    getStats();
    // eslint-disable-next-line
  }, []);

  return (
    <PageContainer>
      <Header />
      <Row>
        <Col sm="4">
          <ToolsJumbo>
            <DashboardHeader>Tools</DashboardHeader>
            <StyledButtonGroup vertical size="lg">
              <StyledButton href="/#/Group/Pharmacies">Pharmacies</StyledButton>
              <StyledButton href="/#/Group/Settings">Group Settings</StyledButton>
              <StyledButton href="/#/Help">Help</StyledButton>
            </StyledButtonGroup>
          </ToolsJumbo>
        </Col>
        <Col sm="8">
          <StatsJumbo>
            <DashboardHeader>Group - {group?.name}</DashboardHeader>
            {stats ? (
              <>
                <StatHeader>
                  <Stat>{stats.newERDOrdersCount}</Stat> new ERD orders
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.withGPOrdersCount}</Stat> orders with GPs
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.stuckOrdersWithGPCount}</Stat> orders stuck with GP
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.withPharmacyCount}</Stat> with pharmacy
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.stuckOrdersWithPharmacyCount}</Stat> orders overdue
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.newUsersCount}</Stat> new patients this week
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.usersCount}</Stat> total patients
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.im1UsersCount}</Stat> IM1 patients
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.newAccountsCount}</Stat> new user accounts this week
                </StatHeader>
                <StatHeader>
                  <Stat>{stats.accountsCount}</Stat> total user accounts
                </StatHeader>
              </>
            ) : (
              <Spinner variant="primary" animation="border" role="status" />
            )}
          </StatsJumbo>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Dashboard;

const DashboardHeader = styled.h2`
  margin-bottom: 1em;
  font-weight: 700;
`;

const StatHeader = styled.h3`
  margin-bottom: 0.75em;
  color: gray;
`;

const Stat = styled.span`
  font-weight: 700;
  color: black;
`;

const ToolsJumbo = styled(Jumbotron)`
  background-color: #c8d4d4;
`;

const StatsJumbo = styled(Jumbotron)`
  background-color: #dbe9e9;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  text-align: left;
`;

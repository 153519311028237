import { Patient, getAdminConfigVar } from '@avicennapharmacy/managemymeds-shared';
import {
  PUSH_NOTIFICATION_TITLE_MAX_LENGTH,
  PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH
} from '../../config/constants';
import React, { useState } from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';

type SendPushNotificationModalProps = {
  patient: Patient | null;
  patients: Patient[] | null;
  resetProcess?: (result: boolean) => void;
};

const SendPushNotificationModal = ({
  patient,
  patients,
  resetProcess
}: SendPushNotificationModalProps) => {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const [pushNotificationAction, setPushNotificationAction] = useState(false);

  return (
    <>
      {success && <Alert variant="primary">Successfully sent push notification</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Row>
        <Col sm={3}>
          <strong>Title</strong>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Form.Control
              type="text"
              data-testid="push-notification-title"
              value={title}
              isInvalid={title.length > PUSH_NOTIFICATION_TITLE_MAX_LENGTH}
              onChange={(e: any) => setTitle(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please keep the title of the notification to less than{' '}
              {PUSH_NOTIFICATION_TITLE_MAX_LENGTH} characters
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <strong>Message</strong>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Form.Control
              data-testid="push-notification-message"
              as="textarea"
              rows={3}
              value={message}
              isInvalid={message.length > PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH}
              onChange={(e: any) => setMessage(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please keep the message body of the notification to less than{' '}
              {PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH} characters
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 9, offset: 3 }}>
          <Row>
            <Col sm={5}>
              <Button
                data-testid="push-notification-action-button"
                className="mt-1"
                variant="primary"
                onClick={async () => {
                  setPushNotificationAction(true);
                }}
                disabled={
                  loading ||
                  title.trim() === '' ||
                  title.length > PUSH_NOTIFICATION_TITLE_MAX_LENGTH ||
                  message.trim() === '' ||
                  message.length > PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH
                }
              >
                {loading ? (
                  <Spinner variant="primary" animation="border" role="status" />
                ) : (
                  'Send push notification'
                )}{' '}
              </Button>
            </Col>
            <Col sm={6}>
              {pushNotificationAction && (
                <>
                  <Row className="ml-3">
                    <label className="mt-3" data-testid="confirmation-label">
                      Are you sure?
                    </label>
                    <Button
                      className="ml-3 mr-2"
                      variant="danger"
                      onClick={async () => {
                        try {
                          setLoading(true);
                          setSuccess(false);

                          if (patient !== null && patient !== undefined) {
                            await axios.post(getAdminConfigVar('sendPushNotificationEndpoint'), {
                              accountId: patient?.accountId,
                              patientId: patient?.id,
                              title,
                              message
                            });
                          } else {
                            await axios.post(
                              getAdminConfigVar('sendBulkPushNotificationEndpoint'),
                              {
                                accountIds: patients?.map(x => x.accountId),
                                title,
                                message
                              }
                            );
                          }

                          setSuccess(true);
                        } catch (e) {
                          setError('Sorry! There was an error processing your request.');
                        } finally {
                          setTitle('');
                          setMessage('');
                          setLoading(false);
                          setPushNotificationAction(false);
                          if (resetProcess !== undefined) {
                            resetProcess(true);
                          }
                        }
                      }}
                    >
                      Yes
                    </Button>{' '}
                    <Button variant="secondary" onClick={() => setPushNotificationAction(false)}>
                      No
                    </Button>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SendPushNotificationModal;

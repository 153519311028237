import React, { useState } from 'react';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import {
  PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH,
  PUSH_NOTIFICATION_TITLE_MAX_LENGTH
} from '../../../config/constants';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { getAdminConfigVar } from '@avicennapharmacy/managemymeds-shared';

const StyledForm = styled(Form)`
  padding-top: 20px;
  margin-left: 10px;
`;

export type BulkPushNotificationModalProps = {
  pharmacyIds: string[];
  show?: boolean;
  setShow: (show: boolean) => void;
  setShowSuccess?: (show: boolean) => void;
  setSuccessModalTitle?: (title: string) => void;
  setSuccessModalBody?: (body: string) => void;
  selectedGroupId?: string | null;
};

export default ({
  pharmacyIds,
  show,
  setShow,
  setShowSuccess,
  setSuccessModalTitle,
  setSuccessModalBody,
  selectedGroupId
}: BulkPushNotificationModalProps) => {
  const [loading, setLoading] = useState(false);
  const [pushNotificationTitle, setPushNotificationTitle] = useState('');
  const [pushNotificationBody, setPushNotificationBody] = useState('');
  const [pushNotificationAction, setPushNotificationAction] = useState(false);

  return (
    <>
      <StyledForm>
        <Form.Group controlId="validationPushNotificationTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            data-testid="push-notification-title"
            value={pushNotificationTitle}
            isInvalid={pushNotificationTitle.length > PUSH_NOTIFICATION_TITLE_MAX_LENGTH}
            onChange={(e: any) => {
              setPushNotificationTitle(e.target.value);
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please keep the title of the notification to less than{' '}
            {PUSH_NOTIFICATION_TITLE_MAX_LENGTH} characters
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="validationPushNotificationBody">
          <Form.Label>Body</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            data-testid="push-notification-body"
            value={pushNotificationBody}
            isInvalid={pushNotificationBody.length > PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH}
            onChange={(e: any) => {
              setPushNotificationBody(e.target.value);
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please keep the message body of the notification to less than{' '}
            {PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH} characters
          </Form.Control.Feedback>
        </Form.Group>
        <Row>
          <Col sm={3}>
            <Button
              className="mt-1"
              variant="primary"
              data-testid="group-push-send-button"
              onClick={async () => {
                setPushNotificationAction(true);
              }}
              disabled={
                loading ||
                pushNotificationTitle.trim() === '' ||
                pushNotificationTitle.length > PUSH_NOTIFICATION_TITLE_MAX_LENGTH ||
                pushNotificationBody.trim() === '' ||
                pushNotificationBody.length > PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH
              }
            >
              {loading ? (
                <Spinner variant="primary" animation="border" role="status" />
              ) : (
                'Send push notification'
              )}{' '}
            </Button>
          </Col>
          <Col sm={6}>
            {pushNotificationAction && (
              <>
                <Row className="ml-3" data-testid="confirmation-label">
                  <label className="mt-3">Are you sure?</label>
                  <Button
                    className="ml-3 mr-2"
                    variant="danger"
                    data-testid="group-push-confirm-button"
                    onClick={async () => {
                      try {
                        setLoading(true);

                        await axios.post(getAdminConfigVar('groupPushNotificationEndpoint'), {
                          groupId: selectedGroupId,
                          pharmacies: pharmacyIds,
                          title: pushNotificationTitle,
                          body: pushNotificationBody
                        });

                        if (setSuccessModalTitle !== undefined) {
                          setSuccessModalTitle('Bulk Push Notification Request Update');
                        }

                        if (setSuccessModalBody !== undefined) {
                          setSuccessModalBody(
                            'The system started to send push notifications. It will be completed shortly.'
                          );
                        }

                        if (setShowSuccess !== undefined) {
                          setShowSuccess(true);
                        }
                      } catch (e) {
                        if (setShowSuccess !== undefined) {
                          setShowSuccess(true);
                        }

                        if (setSuccessModalTitle !== undefined) {
                          setSuccessModalTitle('Bulk Push Notification Request Update');
                        }
                        if (setSuccessModalBody !== undefined) {
                          setSuccessModalBody('Sorry! There was an error processing your request.');
                        }
                      } finally {
                        setPushNotificationTitle('');
                        setPushNotificationBody('');
                        setLoading(false);
                        setPushNotificationAction(false);
                        setShow(false);
                      }
                    }}
                  >
                    Yes
                  </Button>{' '}
                  <Button variant="secondary" onClick={() => setPushNotificationAction(false)}>
                    No
                  </Button>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </StyledForm>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../Header.container';
import PageContainer from '../layout/PageContainer';
import { H2 } from '../typography/Headers';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import GeneralTab from './GeneralTab';
import ServicesTab from './ServicesTab';
import RoomsTab from './RoomsTab';
import WidgetTab from './WidgetTab';
import {
  getAdminConfigVar,
  Owner,
  Pharmacy,
  Resource,
  Group
} from '@avicennapharmacy/managemymeds-shared';
import axios, { AxiosResponse } from 'axios';

const GENERAL = 'General',
  SERVICES = 'Services',
  RESOURCES = 'Rooms',
  WIDGET = 'Booking Widget';

type EditServiceProps = {
  pharmacy: Pharmacy | null | undefined;
  owner: Owner | null;
  serviceNames: any;
  serviceNamesLookup: any;
  updateOwner: (owner: Owner | null) => void;
  getUserPharmacies: () => { pharmacies: Pharmacy[]; groups: Group[] };
  getServiceNames: () => void;
};

export default ({
  pharmacy,
  owner,
  serviceNames,
  serviceNamesLookup,
  updateOwner,
  getUserPharmacies,
  getServiceNames
}: EditServiceProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resources, setResources] = useState<Resource[]>([]);

  const fetchResources = async () => {
    try {
      setLoading(true);
      const url = getAdminConfigVar('serviceBookingGetResourcesEndpoint');
      const result = await axios.post<any, AxiosResponse<{ result: Resource[] }>>(url);
      setResources(result.data.result);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <Header />
      <Row>
        <Col>
          <H2>Service Booking Configuration</H2>
        </Col>
      </Row>
      <Tabs defaultActiveKey={GENERAL} id="service-configuration-tab" className="my-3">
        <Tab eventKey={GENERAL} title={GENERAL} className="my-3 mx-2">
          <GeneralTab
            pharmacy={pharmacy}
            owner={owner}
            updateOwner={updateOwner}
            getUserPharmacies={getUserPharmacies}
          />
        </Tab>
        {owner && owner.active && (
          <Tab eventKey={SERVICES} title={SERVICES} className="my-3 mx-2">
            <ServicesTab
              owner={owner}
              selectedPharmacy={pharmacy}
              resources={resources}
              loading={loading}
              setLoading={setLoading}
              getServiceNames={getServiceNames}
              serviceNames={serviceNames}
              serviceNamesLookup={serviceNamesLookup}
            />
          </Tab>
        )}
        {owner && owner.active && (
          <Tab eventKey={RESOURCES} title={RESOURCES} className="my-3 mx-2">
            <RoomsTab
              owner={owner}
              fetchResources={fetchResources}
              resources={resources}
              loading={loading}
            />
          </Tab>
        )}
        {owner && owner.active && (
          <Tab eventKey={WIDGET} title={WIDGET} className="my-3 mx-2">
            <WidgetTab />
          </Tab>
        )}
      </Tabs>
    </PageContainer>
  );
};

import React, { useState, ReactNode } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import StatusInfoButton from './StatusButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Rejection from './Rejection';
import {
  rejectEntireOrders,
  rejectOrderItem,
  updateOrderStatus,
  getStatusUpdateText
} from '../../utils';
import {
  AMEND,
  NEW,
  REJECTED,
  COLLECTED,
  CANCELLED_IM1,
  formatDate,
  Order,
  Item,
  Status,
  ERDSurveyResponse,
  RAISED_IM1,
  IM1ProcessType
} from '@avicennapharmacy/managemymeds-shared';

type OrderInfoProps = {
  order: Order | null;
  erdSurvey: ERDSurveyResponse | null;
  setModalOrder: (orderId: string | null) => void;
  relatedOrders: Order[];
};

export default ({ order, erdSurvey, setModalOrder, relatedOrders }: OrderInfoProps) => {
  if (!order) {
    return null;
  }

  return (
    <Modal
      show={Boolean(order)}
      onHide={() => setModalOrder(null)}
      size="lg"
      dialogClassName="modal80w"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Order for {order.patientName}{' '}
          <OverlayTrigger
            placement={'right'}
            overlay={<Tooltip id={order.id}>ID: {order.id}</Tooltip>}
          >
            <FontAwesomeIcon icon="info-circle" />
          </OverlayTrigger>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledContainer>
          <InfoGroup label="Status">{getStatusUpdateText(order.orderStatus)}</InfoGroup>
          {order.rejected && (
            <InfoGroup label="Rejection Reason">
              {order.rejected.rejectionReason} - {order.rejected.rejectionText}
            </InfoGroup>
          )}
          <InfoGroup label="Date Order Received">
            {formatDate(order.orderDt, 'dateAndTime')}
          </InfoGroup>
          <InfoGroup label="Date Printed">
            {order.printDt ? formatDate(order.printDt, 'dateAndTime') : ''}
          </InfoGroup>
          <InfoGroup label="Est. Collection">
            {order.estimatedCollectionDate
              ? formatDate(order.estimatedCollectionDate, 'dateAndTime')
              : ''}
          </InfoGroup>
          <InfoGroup label="Name">{order.patientName}</InfoGroup>
          <InfoGroup label="Address">{order.patientAddress}</InfoGroup>
          <InfoGroup label="Primary Tel.">{order.patientPhoneNumber}</InfoGroup>
          <InfoGroup label="Secondary Tel."></InfoGroup>
          <InfoGroup label="DOB">{order.patientDateOfBirth}</InfoGroup>
          <InfoGroup label="GP">{`${order.patientDoctorName}${order.patientDoctorName ? ',' : ''} ${
            order.patientGPName
          }`}</InfoGroup>
          <InfoGroup label="GP Address">
            {order.patientSurgeryAddress?.replace(/\n/gi, ', ')}
          </InfoGroup>
          <InfoGroup label="GP Tel.">{order.patientGPContactNumber}</InfoGroup>
          <InfoGroup label="Delivery">
            {order.isDelivery ? <FontAwesomeIcon icon="check-circle" /> : ''}
          </InfoGroup>
          {order.isEarlyReorder && (
            <InfoGroup label="Early Reorder" highlighted>
              {order.earlyReorderReason}
            </InfoGroup>
          )}
        </StyledContainer>
        <StyledContainer>
          <ItemsTable
            items={order.items}
            order={order}
            erdSurvey={erdSurvey}
            setModalOrder={setModalOrder}
          />
        </StyledContainer>
        <StyledContainer>
          <Tabs
            defaultActiveKey={order.orderType === 'RepeatDispensing' ? 'erdQuestions' : 'history'}
            id="order-info-tabs"
          >
            <Tab eventKey="actions" title="Order Actions">
              <ActionsDiv>
                <OrderChanges order={order} setModalOrder={setModalOrder} />
              </ActionsDiv>
            </Tab>
            <Tab eventKey="history" title="Order Status History">
              <OrderStatusHistory updates={order.statusUpdates} />
            </Tab>
            <Tab eventKey="related" title="Related Orders">
              <RelatedOrders orders={relatedOrders} setModalOrder={setModalOrder} />
            </Tab>
            {order.orderType === 'RepeatDispensing' && (
              <Tab eventKey="erdQuestions" title="ERD Questions">
                <ERDTab order={order} erdSurvey={erdSurvey} />
              </Tab>
            )}
          </Tabs>
        </StyledContainer>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalOrder(null)}>
          Close
        </Button>
        {![REJECTED, COLLECTED, CANCELLED_IM1].includes(order.orderStatus) && (
          <StatusInfoButton onClick={() => setModalOrder(null)} order={order} />
        )}
      </Modal.Footer>
    </Modal>
  );
};

type InfoGroupProps = {
  label: string;
  children?: ReactNode;
  highlighted?: boolean;
};

const InfoGroup = ({ label, children, highlighted = false }: InfoGroupProps) => (
  <InfoRow highlighted={highlighted}>
    <Col sm={3}>
      <strong>{label}</strong>
    </Col>
    <Col sm={9}>{children}</Col>
  </InfoRow>
);

type ItemsTableProps = {
  items: Item[];
  order: Order;
  erdSurvey: ERDSurveyResponse | null;
  setModalOrder: (orderId: string | null) => void;
};

const ItemsTable = ({ items, order, erdSurvey }: ItemsTableProps) => {
  const [rejectionItem, setRejectionItem] = useState<Item | null>(null);

  if (!items?.length) {
    return <em>No items</em>;
  }

  if (rejectionItem) {
    return (
      <>
        <p>Rejection: {rejectionItem?.option ?? rejectionItem.description}</p>
        <Rejection
          onReject={async ({ reason, text }) => {
            await rejectOrderItem({
              orderId: order.id,
              itemId: rejectionItem.orderItemId,
              reason,
              text
            });
            setRejectionItem(null);
          }}
          onCancel={() => setRejectionItem(null)}
        />
      </>
    );
  }

  return (
    <Table hover size="sm">
      <thead>
        <tr>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <tr key={item.orderItemId}>
            <StyledRejectionCell
              rejected={
                Boolean(item.rejected) ||
                Boolean(erdSurvey && erdSurvey.rejectedItemIds.includes(item.orderItemId))
              }
            >
              {item.option?.length > 0 ? item.option : item.description}
            </StyledRejectionCell>
            <td>
              {item.rejected &&
                String(item?.iM1ProcessType) === String(IM1ProcessType.Rejected) && (
                  <span className="badge bg-danger p-2 text-white">
                    {'Rejected - ' + item?.rejected.rejectionReason}
                  </span>
                )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

type OrderStatusHistoryProps = {
  updates: Status[];
};

const OrderStatusHistory = ({ updates }: OrderStatusHistoryProps) => {
  if (!updates?.length) {
    return <em>No history</em>;
  }

  // Don't mutate original array
  const updatesRev = Array.from(updates).reverse();

  return (
    <Table hover size="sm">
      <thead>
        <tr>
          <th>Status</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {updatesRev.map((update, index) => (
          <tr key={index}>
            <td>{getStatusUpdateText(update.trackingStatus)}</td>
            <td>{formatDate(update.statusUpdateDt, 'dateAndTime')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

type OrderChangesProps = {
  order: Order;
  setModalOrder: (orderId: string | null) => void;
};

const OrderChanges = ({ order: { id, orderStatus }, setModalOrder }: OrderChangesProps) => {
  const [showOrderRejection, setShowOrderRejection] = useState(false);
  const [showOrderAmend, setShowOrderAmend] = useState(false);

  if (orderStatus === REJECTED || orderStatus === COLLECTED || orderStatus === RAISED_IM1) {
    return null;
  }

  if (!showOrderRejection && !showOrderAmend) {
    return (
      <ActionsDiv>
        {orderStatus === NEW && (
          <Button
            variant="warning"
            onClick={() => {
              setShowOrderAmend(true);
              setShowOrderRejection(false);
            }}
          >
            Request amend
          </Button>
        )}
        <StyledButton
          variant="danger"
          onClick={() => {
            setShowOrderRejection(true);
            setShowOrderAmend(false);
          }}
        >
          Reject entire order
        </StyledButton>
      </ActionsDiv>
    );
  }

  if (showOrderRejection) {
    return (
      <ActionsDiv>
        <Rejection
          onReject={({ reason, text }) => {
            rejectEntireOrders([{ orderId: id, reason, text }]);
            setModalOrder(null);
          }}
          onCancel={() => setShowOrderRejection(false)}
        />
      </ActionsDiv>
    );
  }

  if (orderStatus === NEW) {
    return (
      <ActionsDiv>
        <span>Request amend of this order? </span>
        <Button variant="secondary" onClick={() => setShowOrderAmend(false)}>
          No
        </Button>
        <StyledButton
          onClick={() => {
            updateOrderStatus({ ids: [id], orderStatus: AMEND });
            setModalOrder(null);
          }}
        >
          Yes
        </StyledButton>
      </ActionsDiv>
    );
  }

  return null;
};

type RelatedOrdersProps = {
  orders: Order[];
  setModalOrder: (orderId: string | null) => void;
};

const RelatedOrders = ({ orders, setModalOrder }: RelatedOrdersProps) => (
  <Table hover size="sm">
    <thead>
      <tr>
        <th>Date</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {orders.map((order, index) => (
        <tr key={index}>
          <td>{formatDate(order.orderDt, 'dateAndTime')}</td>
          <td>{order.orderStatus}</td>
          <td>
            <Button
              onClick={() => {
                // Hard for user to tell modal has changed without an artificial wait
                setModalOrder(null);
                setTimeout(() => setModalOrder(order.id), 200);
              }}
              size="sm"
              variant="outline-primary"
            >
              Open
            </Button>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

type ERDTabProps = {
  order: Order;
  erdSurvey: ERDSurveyResponse | null;
};

const ERDTab = ({ order, erdSurvey }: ERDTabProps) => {
  if (!erdSurvey) {
    return <p>No responses submitted for the ERD questions.</p>;
  }
  return (
    <>
      <Table>
        <thead>
          <th>Question</th>
          <th>Response</th>
        </thead>
        <tbody>
          {erdSurvey.surveyResponses.map((r, i) => (
            <tr key={i}>
              <td>{r.questionText}</td>
              <td>{r.responseText}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {erdSurvey.rejectedItemIds.length > 0 && (
        <Table>
          <thead>
            <th>Rejected items</th>
          </thead>
          <tbody>
            {erdSurvey.rejectedItemIds.map((id, i) => (
              <tr key={i}>
                <td>{order.items.find(item => item.orderItemId === id)?.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

const RejectionCell = ({
  children,
  className
}: {
  rejected: boolean;
  children: ReactNode;
  className?: string;
}) => {
  return <td className={className}>{children}</td>;
};

const InfoRow = styled(Row)`
  margin-top: 0.5em;
  background-color: ${props => (props.highlighted ? 'rgb(251, 238, 242)' : 'transparent')};
`;

const StyledContainer = styled(Container)`
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #dee2e6;
`;

const StyledButton = styled(Button)`
  margin-left: 0.5em;
`;

const ActionsDiv = styled.div`
  margin-top: 0.5em;
`;

const StyledRejectionCell = styled(RejectionCell)`
  text-decoration: ${props => (props.rejected ? 'line-through' : 'none')};
`;

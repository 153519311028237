import { connect } from 'react-redux';
import { GlobalState } from '../../reducers';
import { setPharmacies } from '../../actions/user';
import { getSelectedPharmacy, getOwner } from '../../selectors/user';
import EditDetails from '.';

const mapStateToProps = (state: GlobalState) => ({
  pharmacy: getSelectedPharmacy(state),
  owner: getOwner(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setPharmacies: (pharmacies: any[]) => dispatch(setPharmacies(pharmacies))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);

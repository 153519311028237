import {
  NEW,
  NEW_REPEAT_DISPENSING,
  AMEND,
  RAISED,
  RAISED_IM1,
  IM1_WITH_GP_TOO_LONG,
  READY_FOR_COLLECTION,
  GPAPPROVED,
  BookingStatus,
  BookingType
} from '@avicennapharmacy/managemymeds-shared';

const bookingStatuses = [
  {
    label: 'Upcoming',
    value: BookingStatus.Upcoming
  }
];

export const availableAppointmentTypes = [
  { value: BookingType.InPerson, label: 'In-person' },
  { value: BookingType.Telephone, label: 'Telephone' }
];

export const EXTERNAL_DESCRIPTION_MAX_LENGTH = 1000;

const defaultOrdersToLoad = (includeIM1: boolean): string[] => {
  const statuses = [NEW, AMEND, RAISED, READY_FOR_COLLECTION];
  if (includeIM1) {
    statuses.push(NEW_REPEAT_DISPENSING, RAISED_IM1, IM1_WITH_GP_TOO_LONG, GPAPPROVED);
  }
  return statuses;
};

export { defaultOrdersToLoad, bookingStatuses };

export const PUSH_NOTIFICATION_TITLE_MAX_LENGTH = 30;
export const PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH = 150;

export const bookingFormTypes = [
  { value: 'Online', label: 'Default - Available to book via All Platforms.' },
  {
    value: 'Offline',
    label:
      'Offline - Just available for Pharmacy Portal. It does not appear in the widget and mobile app.'
  },
  {
    value: 'PersonalData',
    label:
      'Personal Data - To collect customer data. Just available via Link. It does not appear in the diary.'
  }
];

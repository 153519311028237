import { BookingWithPatient, Resource } from '@avicennapharmacy/managemymeds-shared';
import { actionTypes } from '../actions/bookings';

export type bookingsStateType = {
  loadingBookings: boolean;
  bookings: {
    [key: string]: BookingWithPatient[];
  };
  resources: Resource[];
  selectedResource: string | null;
  loadedPharmacy: string | null;
  lastLoadedMonth: string | null;
  selectedBookings: Set<string>;
};

export const bookingsState: bookingsStateType = {
  loadingBookings: false,
  bookings: {},
  resources: [],
  selectedResource: null,
  loadedPharmacy: null,
  lastLoadedMonth: null,
  selectedBookings: new Set()
};

export default (state = bookingsState, action: Action): bookingsStateType => {
  switch (action.type) {
    case actionTypes.SET_LOADING_BOOKINGS: {
      return {
        ...state,
        loadingBookings: action.payload
      };
    }
    case actionTypes.SET_LOADING_BOOKINGS_COMPLETE: {
      return {
        ...state,
        loadingBookings: action.payload
      };
    }
    case actionTypes.SET_SELECTED_RESOURCE:
      return {
        ...state,
        selectedResource: action.payload.id
      };
    case actionTypes.SET_LOADED_PHARMACY:
      return {
        ...state,
        loadedPharmacy: action.payload.id
      };
    case actionTypes.SET_LAST_LOADED_MONTH:
      return {
        ...state,
        lastLoadedMonth: action.payload.month
      };
    case actionTypes.LOAD_BOOKINGS_FOR_RESOURCE:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.resourceId]:
            state.bookings[action.payload.resourceId] && !action.payload.clear
              ? [...state.bookings[action.payload.resourceId], ...action.payload.bookings]
              : action.payload.bookings
        }
      };
    case actionTypes.LOAD_RESOURCES:
      return {
        ...state,
        resources: action.payload
      };
    case actionTypes.RESET_DIARY:
      return {
        ...state,
        bookings: {},
        resources: [],
        selectedResource: null,
        lastLoadedMonth: null,
        selectedBookings: new Set()
      };
    case actionTypes.SELECT_BOOKING: {
      if (state.selectedBookings.delete(action.payload)) {
        return {
          ...state,
          selectedBookings: new Set(state.selectedBookings)
        };
      } else {
        return {
          ...state,
          selectedBookings: new Set(state.selectedBookings.add(action.payload))
        };
      }
    }
    case actionTypes.CLEAR_SELECTED_BOOKINGS:
      return {
        ...state,
        selectedBookings: new Set()
      };
    default:
      return state;
  }
};

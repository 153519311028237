import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import OrderInfo from './OrderInfo.container';
import Orders from './OrderTabs.container';
import {
  NEW,
  REJECTED,
  AMEND,
  NEW_REPEAT_DISPENSING,
  Order,
  RAISED
} from '@avicennapharmacy/managemymeds-shared';
import { useParams } from 'react-router-dom';
import {
  PrintConfirmationModal,
  PrintListConfirmationModal,
  PrintERDListConfirmationModal,
  UpdateConfirmationModal
} from './ConfirmationModal';
import Header from '../Header.container';
import Row from '../layout/Row';
import PageContainer from '../layout/PageContainer';

export type AppProps = {
  searchString: string;
  selectedOrders: Order[];
  selectedPharmacy: any;
  setSearchString: (searchString: string) => string;
  deselectAllOrders: () => void;
  loadingOrders: boolean;
  loadOrders: (callback?: Function) => void;
  loadRejectedOrders: () => void;
  loadCollectedOrders: (offset?: number, limit?: number, orderByDesc?: boolean) => void;
  loadErdSurveys: () => void;
};

export default ({
  searchString,
  selectedOrders,
  selectedPharmacy,
  setSearchString,
  deselectAllOrders,
  loadingOrders,
  loadOrders,
  loadRejectedOrders,
  loadCollectedOrders,
  loadErdSurveys
}: AppProps) => {
  const [confirmationType, setConfirmationType] = useState('');
  let { tabKey } = useParams<{ tabKey: string }>();

  useEffect(() => {
    deselectAllOrders();
  }, [deselectAllOrders, tabKey]);

  return (
    <>
      <PageContainer>
        <Header smallLogo>
          <Col>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Search by name, address or phone number"
              value={searchString}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                setSearchString(target.value)
              }
            />
          </Col>
          <Col sm="auto">
            <Button
              variant="primary"
              size="lg"
              disabled={loadingOrders}
              onClick={() => {
                loadOrders();
                loadRejectedOrders();
                loadCollectedOrders(0, 50, true);
                loadErdSurveys();
              }}
            >
              {loadingOrders ? 'Loading...' : 'Fetch latest orders'}
            </Button>
          </Col>
          <Col sm="auto">
            <DropdownButton
              id="bulk-dropdown"
              size="lg"
              variant="success"
              title="Bulk actions"
              disabled={selectedOrders.length === 0}
            >
              {(tabKey === NEW || tabKey === NEW_REPEAT_DISPENSING || tabKey === RAISED) && (
                <Dropdown.Item
                  onClick={() => {
                    setConfirmationType('print');
                  }}
                >
                  Print off repeat requests
                </Dropdown.Item>
              )}
              {tabKey === NEW && (
                <Dropdown.Item
                  onClick={() => {
                    setConfirmationType('print-names');
                  }}
                >
                  Print off summary name list
                </Dropdown.Item>
              )}
              {tabKey === NEW_REPEAT_DISPENSING && (
                <Dropdown.Item
                  onClick={() => {
                    setConfirmationType('print-erd');
                  }}
                >
                  Print off ERD name list
                </Dropdown.Item>
              )}
              {![REJECTED, AMEND].includes(tabKey as string) && (
                <Dropdown.Item
                  onClick={() => {
                    setConfirmationType('status');
                  }}
                >
                  Move selected orders to next status
                </Dropdown.Item>
              )}
            </DropdownButton>
          </Col>
        </Header>
        <Row>
          <Col>
            <Orders tabKey={tabKey} />
          </Col>
        </Row>
      </PageContainer>
      <OrderInfo />
      <PrintConfirmationModal
        show={confirmationType === 'print'}
        selectedOrders={selectedOrders}
        onHide={() => setConfirmationType('')}
        selectedPharmacy={selectedPharmacy}
      />
      <PrintListConfirmationModal
        show={confirmationType === 'print-names'}
        selectedOrders={selectedOrders}
        onHide={() => setConfirmationType('')}
      />
      <PrintERDListConfirmationModal
        show={confirmationType === 'print-erd'}
        selectedOrders={selectedOrders}
        onHide={() => setConfirmationType('')}
      />
      <UpdateConfirmationModal
        show={confirmationType === 'status'}
        selectedOrders={selectedOrders}
        onHide={() => setConfirmationType('')}
      />
    </>
  );
};

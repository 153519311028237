import { createSelector } from 'reselect';
import { GlobalState } from '../reducers/index';
import { BookingWithPatient } from '@avicennapharmacy/managemymeds-shared';

export const getBookings = (state: GlobalState) => state.bookings.bookings;
export const getResources = (state: GlobalState) => state.bookings.resources;
export const getSelectedResource = (state: GlobalState) => state.bookings.selectedResource;
export const getLoadedPharmacy = (state: GlobalState) => state.bookings.loadedPharmacy;
export const getlastLoadedMonth = (state: GlobalState) => state.bookings.lastLoadedMonth;
export const getSelectedBookingIds = (state: GlobalState) => state.bookings.selectedBookings;

export const getSelectedBookings = createSelector(
  [getBookings, getSelectedResource, getSelectedBookingIds],
  (bookings, selectedResource, selectedIds) => {
    if (!selectedResource) {
      return null;
    }
    return bookings[selectedResource]?.filter(booking => selectedIds.has(booking.id));
  }
);

export const getResourceNames = createSelector(getResources, resources => {
  let resourceNames: {
    [key: string]: string;
  } = {};
  resources.map(resource => (resourceNames[resource.id] = resource.name));
  return resourceNames;
});

export const formatBookings = createSelector(
  [getBookings, getSelectedResource],
  (bookings: { [key: string]: BookingWithPatient[] }, selectedResource: any) => {
    if (bookings[selectedResource]) {
      let groupedBookings = bookings[selectedResource].reduce((r: any, a) => {
        if (a.bookingStatus === 'Upcoming') {
          r[a.start] = [...(r[a.start] || []), a];
        } else if (a.bookingStatus === 'Cancelled') {
          r[a.start + 'C'] = [...(r[a.start + 'C'] || []), a];
        }
        return r;
      }, {});

      let formattedBookings: any = [];
      Object.keys(groupedBookings).forEach((bookingKey: string) => {
        if (groupedBookings[bookingKey].length <= 1) {
          formattedBookings.push({
            id: groupedBookings[bookingKey][0]?.id,
            title: `${groupedBookings[bookingKey][0]?.service} - ${groupedBookings[bookingKey][0]?.patient?.firstName} ${groupedBookings[bookingKey][0]?.patient?.lastName}`,
            start: new Date(groupedBookings[bookingKey][0]?.start),
            end: new Date(groupedBookings[bookingKey][0]?.end),
            resource: groupedBookings[bookingKey]
          });
        } else {
          formattedBookings.push({
            id: groupedBookings[bookingKey][0]?.id,
            title: `${groupedBookings[bookingKey][0]?.service} - ${groupedBookings[bookingKey]?.length} Bookings`,
            start: new Date(groupedBookings[bookingKey][0]?.start),
            end: new Date(groupedBookings[bookingKey][0]?.end),
            resource: groupedBookings[bookingKey]
          });
        }
      });
      return formattedBookings;
    } else {
      return [];
    }
  }
);

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import PageContainer from '../layout/PageContainer';
import Header from '../Header.container';
import Row from '../layout/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAdminConfigVar } from '@avicennapharmacy/managemymeds-shared';
import { H2 } from '../typography/Headers';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { appInsights } from '../../index';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

const MessageButtonContainer = styled.div`
  margin: 25px 0 50px 0;
`;

type HelpFilesResponse = {
  [key: string]: any;
  id: string;
  filename: string;
  filepath: string;
  ownershipType: string;
  createdDate: string;
};

export default () => {
  const [files, setFiles] = useState<HelpFilesResponse[] | null>(null);
  const [supportMessage, setSupportMessage] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [supportLoading, setSupportLoading] = useState(false);
  const [supportError, setSupportError] = useState(false);
  useEffect(() => {
    const getFiles = async () => {
      const res = await axios.post<HelpFilesResponse[]>(getAdminConfigVar('helpFilesListEndpoint'));
      setFiles(res.data);
    };
    getFiles();
    // eslint-disable-next-line
  }, []);

  const sendMessage = async () => {
    try {
      setSupportError(false);
      setSupportLoading(true);
      await axios.post(getAdminConfigVar('supportEndpoint'), {
        issueText: supportMessage
      });
      appInsights.trackEvent({ name: 'PHARMACY_SUPPORT_SUCCESS' });
      setSupportMessage('');
      setShowMessageModal(false);
    } catch (e) {
      appInsights.trackEvent({ name: 'PHARMACY_SUPPORT_SUCCESS' });
      appInsights.trackException(e);
      setSupportError(true);
    }
    setSupportLoading(false);
  };

  return (
    <PageContainer>
      <Header />
      <Row>
        <Col sm={10} md={6}>
          <H2>Help</H2>
          <MessageButtonContainer>
            <Button onClick={() => setShowMessageModal(true)}>Send message to support team</Button>
          </MessageButtonContainer>
          <p>Manage My Meds User guides, FAQs and other materials:</p>
          {files ? (
            <Table>
              <thead>
                <tr>
                  <th>File</th>
                  <th className="text-center">Download</th>
                </tr>
              </thead>
              <tbody>
                {files.length > 0 ? (
                  files.map((file, i) => (
                    <tr key={i}>
                      <td>
                        <a href={file.uri} target="_blank" rel="noopener noreferrer">
                          {file.filename}
                        </a>
                      </td>
                      <td className="text-center">
                        <a href={file.filepath} target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon="cloud-download-alt" />
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>
                      <em>No help files found</em>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <Spinner variant="primary" animation="border" role="status" />
          )}
        </Col>
      </Row>
      <Modal show={showMessageModal} onHide={() => setShowMessageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Send Message to Support Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {supportError && (
            <div>
              <Alert variant="danger">
                There was an error sending your message. Alternatively you can email{' '}
                <a href="mailto:managemymeds@avicenna.org">managemymeds@avicenna.org</a>
              </Alert>
            </div>
          )}
          <Form.Group>
            <Form.Label>Your message:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={supportMessage}
              onChange={e => setSupportMessage(e.currentTarget.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowMessageModal(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => sendMessage()}
            disabled={supportLoading || supportMessage.trim() === ''}
          >
            {supportLoading ? 'Sending...' : 'Send'}
          </Button>
        </Modal.Footer>
      </Modal>
    </PageContainer>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import PageContainer from '../layout/PageContainer';
import Header from '../Header.container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { H2 } from '../typography/Headers';
import {
  formatDate,
  getAdminConfigVar,
  Patient,
  Account
} from '@avicennapharmacy/managemymeds-shared';
import useDebounce from '../../hooks/useDebounce';
import axios, { AxiosResponse } from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Pagination from '../Pagination';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PatientModal from './PatientModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import SendPushNotificationModal from 'components/pushNotification/sendPushNotificationModal';
import { Modal } from 'react-bootstrap';

const itemsPerPage = 50;

type PatientResponse = {
  patients: Patient[];
  accounts: Account[];
  items: number;
  currentPage: number;
  totalPages: number;
};

export default () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [patientId, setPatientId] = useState<string | null>(null);
  const [filterParam, setFilterParam] = useState('includeIM1=true&includeNonIM1=true');
  const [patientOrderParam, setPatientOrderParam] = useState('orderByRegisterDate=false');
  const [saving, setSaving] = useState(false);
  const [savingPatient, setSavingPatient] = useState<Patient | null>(null);
  const [search, setSearch, signal] = useDebounce('', 500, {
    leading: false,
    trailing: true
  });
  const prevSearchRef = useRef();
  const prevFilterRef = useRef<string>();
  const prevPatientOrderRef = useRef<string>();
  const [bulkPatientList, setBulkPatientList] = useState<Patient[]>([]);
  const [bulkButtonDisabled, setBulkButtonDisabled] = useState(true);
  const [sendBulkPushNotification, setSendBulkPushNotification] = useState(false);
  const [isBulkProcessCompleted, setIsBulkProcessCompleted] = useState(false);
  const [isFreshCheckbox, setIsFreshCheckbox] = useState(true);
  const [isFreshBulkCheckbox, setIsFreshBulkCheckbox] = useState(true);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);

  const fetchPatients = async (searchTerm: string) => {
    try {
      setLoading(true);
      const url = getAdminConfigVar('patientsEndpoint');
      const result = await axios.post<any, AxiosResponse<PatientResponse>>(
        `${url}?q=${searchTerm}&offset=${(page - 1) *
          itemsPerPage}&limit=${itemsPerPage}&${filterParam}&${patientOrderParam}`
      );
      setPatients(result.data.patients);
      setAccounts(result.data.accounts);
      setPages(result.data.totalPages);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchPatients('');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      search !== prevSearchRef.current ||
      filterParam !== prevFilterRef.current ||
      patientOrderParam !== prevPatientOrderRef.current
    ) {
      setPage(1);
      prevSearchRef.current = search;
      prevFilterRef.current = filterParam;
      prevPatientOrderRef.current = patientOrderParam;
    }

    fetchPatients(search);
    // As this is debounced, we don't want to watch for changes to search string itself
    // eslint-disable-next-line
  }, [signal, page, filterParam, patientId, patientOrderParam]);

  const savePatient = async (patient: Patient) => {
    if (patient) {
      try {
        const newDeliveryStatus: Patient = {
          ...patient,
          hasPrescriptionsDelivered: !patient.hasPrescriptionsDelivered
        };
        setSaving(true);
        setSavingPatient(patient);
        await axios.patch(getAdminConfigVar('updatePatientEndpoint'), newDeliveryStatus);
        setPatients(
          [...patients].map((currentPatient: Patient) => {
            if (currentPatient.id === patient.id) {
              return newDeliveryStatus;
            } else return currentPatient;
          })
        );
        setSavingPatient(null);
        setSaving(false);
      } catch (e) {
        setSaving(false);
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (isBulkProcessCompleted) {
      setIsFreshCheckbox(false);
      setBulkPatientList([]);
      setBulkButtonDisabled(true);
      setIsBulkProcessCompleted(false);
      setIsFreshBulkCheckbox(false);
      setSelectedPages([]);
    }
  }, [sendBulkPushNotification, bulkPatientList, isBulkProcessCompleted]);

  useEffect(() => {
    if (!isFreshCheckbox) {
      setIsFreshCheckbox(true);
    }
  }, [isFreshCheckbox]);

  useEffect(() => {
    if (!isFreshBulkCheckbox) {
      setIsFreshBulkCheckbox(true);
    }
  }, [isFreshBulkCheckbox]);

  let content = null;

  if (loading) {
    content = <Spinner animation="border" variant="primary" />;
  } else if (error) {
    content = <Alert variant="danger">Error loading patients</Alert>;
  } else if (patients.length === 0) {
    content = <em>No patients found</em>;
  } else {
    content = (
      <>
        <Table hover>
          <thead>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>Email</th>
              <th>DOB</th>
              <th>Primary contact</th>
              <th>Address 1</th>
              <th>Postcode</th>
              <th>Registered</th>
              <th>IM1</th>
              <th>Delivery</th>
              <th>View</th>
              <th>
                {isFreshBulkCheckbox && (
                  <input
                    className="ml-1"
                    defaultChecked={
                      selectedPages?.filter(x => x === page).length > 0 ? true : false
                    }
                    type="checkbox"
                    onChange={e => {
                      if (e.target.checked) {
                        bulkPatientList.push(...patients);
                        selectedPages.push(page);

                        if (bulkButtonDisabled) {
                          setBulkButtonDisabled(false);
                        }
                      } else {
                        let unSelectedPageIndex = selectedPages.indexOf(page, 0);
                        selectedPages.splice(unSelectedPageIndex, 1);

                        let filteredArray = bulkPatientList.filter(
                          patient => !patients.map(x => x.id).includes(patient.id)
                        );
                        setBulkPatientList(filteredArray);

                        if (!bulkButtonDisabled && filteredArray.length === 0) {
                          setBulkButtonDisabled(true);
                        }
                      }

                      setIsFreshCheckbox(false);
                    }}
                  />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {patients.map(patient => {
              const account = accounts.find(a => a.id === patient.accountId);
              return (
                <tr key={patient.id}>
                  <td>{patient.firstName}</td>
                  <td>{patient.lastName}</td>
                  <td>{account ? account.email : ''}</td>
                  <td>{formatDate(patient.dateOfBirth, 'dob')}</td>
                  <td>{patient.primaryContactNumber}</td>
                  <td>{patient.address1}</td>
                  <td>{patient.postcode}</td>
                  <td>
                    {patient.consentSignedDt && formatDate(patient.consentSignedDt, 'dateAndTime')}
                  </td>
                  <td className="text-center">
                    {patient.patientIntegration ? (
                      <FontAwesomeIcon icon="check-circle" className="text-primary" />
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-center">
                    {saving && savingPatient?.id === patient.id ? (
                      <Spinner variant="primary" animation="border" role="status" />
                    ) : (
                      <input
                        type="checkbox"
                        defaultChecked={patient.hasPrescriptionsDelivered}
                        onChange={async () => {
                          await savePatient(patient);
                        }}
                      />
                    )}
                  </td>
                  <td>
                    <Button size="sm" variant="secondary" onClick={() => setPatientId(patient.id)}>
                      View
                    </Button>
                  </td>
                  <td className="text-center">
                    {isFreshCheckbox && (
                      <input
                        key={'patient-' + patient.id}
                        data-testid={patient.id}
                        type="checkbox"
                        defaultChecked={
                          bulkPatientList
                            ? bulkPatientList.some(
                                selectedPatient => selectedPatient.id === patient.id
                              )
                            : false
                        }
                        onChange={e => {
                          if (
                            (bulkPatientList.length > 0 || bulkPatientList.length === 0) &&
                            e.target.checked
                          ) {
                            bulkPatientList?.splice(bulkPatientList.length, 0, patient);
                            setBulkButtonDisabled(false);
                          } else if (bulkPatientList.length > 1) {
                            let index = bulkPatientList.indexOf(patient);
                            bulkPatientList.splice(index, 1);
                          } else {
                            let index = bulkPatientList.indexOf(patient);
                            bulkPatientList.splice(index, 1);
                            setBulkButtonDisabled(true);
                          }
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {pages > 1 && <Pagination currentPage={page} totalPages={pages} setPage={setPage} />}
      </>
    );
  }

  return (
    <>
      <PageContainer>
        <Header>
          <Col>
            <Form>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Search by first name, last name, contact number"
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
              />
            </Form>
          </Col>
          <Col sm="auto">
            <DropdownButton
              id="bulk-dropdown"
              size="lg"
              variant="success"
              title="Bulk actions"
              disabled={bulkButtonDisabled}
            >
              <Dropdown.Item
                data-testid="bulk-push-notification-button"
                onClick={() => {
                  setSendBulkPushNotification(true);
                }}
              >
                Send Push Notification
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Header>
        <Row>
          <Col sm={2}>
            <H2>Patients</H2>
          </Col>
          <Col sm={3}>
            <Form>
              <Form.Control
                as="select"
                value={filterParam}
                onChange={(e: any) => setFilterParam(e.target.value)}
              >
                <option value={'includeIM1=true&includeNonIM1=true'}>All patients</option>
                <option value={'includeIM1=true&includeNonIM1=false'}>IM1 only</option>
                <option value={'includeIM1=false&includeNonIM1=true'}>Non-IM1 only</option>
              </Form.Control>
            </Form>
          </Col>
          <Col sm={3}>
            <Form>
              <Form.Control
                as="select"
                value={patientOrderParam}
                onChange={(e: any) => setPatientOrderParam(e.target.value)}
              >
                <option value={'orderByRegisterDate=false'}>Default</option>
                <option value={'orderByRegisterDate=true'}>Registered Date</option>
              </Form.Control>
            </Form>
          </Col>
        </Row>
        <Row className="my-3 mx-0">{content}</Row>
      </PageContainer>
      <PatientModal patientId={patientId} setPatientId={setPatientId} />

      <Modal
        data-testid="bulk-push-modal"
        data-popupstatus={sendBulkPushNotification}
        show={sendBulkPushNotification}
        onHide={() => setSendBulkPushNotification(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send push notification to selected patients.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SendPushNotificationModal
            patient={null}
            patients={bulkPatientList}
            resetProcess={setIsBulkProcessCompleted}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            data-testid="bulk-push-close-button"
            onClick={() => setSendBulkPushNotification(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

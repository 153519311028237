import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

type CustomProps = {
  variant:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | undefined;
  errorMessage: string | null;
  showAlert?: boolean;
  dismissible?: boolean;
  setModalError?: React.Dispatch<React.SetStateAction<string | null>>;
};

const CustomAlert = ({
  variant,
  errorMessage,
  showAlert,
  dismissible,
  setModalError
}: CustomProps) => {
  const [show, setShow] = useState(showAlert ?? true);
  return (
    <>
      {show && errorMessage && (
        <Alert
          variant={variant}
          onClose={() => {
            setShow(false);
            if (setModalError) setModalError(null);
          }}
          dismissible={dismissible ?? false}
        >
          <p>{errorMessage}</p>
        </Alert>
      )}
    </>
  );
};

export default CustomAlert;

// TODO: DELETE THESE TYPES AND MOVE TO THE SHARED LIB

export type BookableService = {
  id: string;
  resourceId: string;
  name: string;
  active: boolean;
  startDt?: string; //e.g. 2020-12-28T16:31:45.151Z
  endDt?: string;
  externalDescription: string;
  externalName: string;
  slotDuration: number; //int
  daysBookableInAdvance?: number; //int
};

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export enum BookingStatus {
  Upcoming = 0,
  Cancelled = 1,
  Missed = 2,
  Attended = 3
}

export type BookingRecord = {
  id: string;
  startDt: string; //C# DateTime
  endDt: string;
  groupId: string;
  ownerId: string;
  resourceId: string;
  serviceId: string;
  externalCustomerId: string;
  email: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  customerNote: string;
  bookingNote: string;
  vcLink: string;
  rating: number;
  ratingComment: string;
  bookedDt: string;
  cancellationKey: string;
  cancelledDt?: string;
  cancellationReason: string;
  bookingStatus: BookingStatus;
};

export type BookingCancelModel = {
  resourceId: string | null;
  customerId: string | null;
  bookingId: string | null;
  startDate: string | null; //C# DateTime
  cancellationKey: string | null;
};

export type DeleteServiceResponse = {
  result: boolean | null;
  showCustomMessage: boolean | null;
  message: string | null;
};

export type CreateResourceResponse = {
  result: boolean | null;
  showCustomMessage: boolean | null;
  message: string | null;
};

export enum Role {
  Admin_Pharmacies_Edit = 'Admin_Pharmacies_Edit',
  Admin_Pharmacies_Add = 'Admin_Pharmacies_Add',
  Admin_Pharmacies_Toggle = 'Admin_Pharmacies_Toggle',
  Admin_Users_Edit = 'Admin_Users_Edit',
  Admin_Users_Delete = 'Admin_Users_Delete',
  Admin_Services_Edit = 'Admin_Services_Edit',
  Admin_Offers_Edit = 'Admin_Offers_Edit',
  Admin_Full = 'Admin_Full',
  Admin_ReadOnly = 'Admin_ReadOnly'
}

import { actionTypes } from '../actions/orders';
import { ERDSurveyAPIResponse, Order } from '@avicennapharmacy/managemymeds-shared';
import { sortByOrderDatesDesc } from '../utils/sort';

export type ordersStateType = {
  loadingOrders: boolean;
  orders: Order[];
  selectedOrders: Set<string>;
  rejectedOrders: Order[];
  collectedOrders: Order[];
  erdSurveys: ERDSurveyAPIResponse | null;
  modalOrderId: string | null;
  searchString: string;
  sortOrdersFn: (a: Order, b: Order) => number;
  sortOrdersDesc: boolean;
  currentPage: number;
  totalPages: number;
};

export const ordersState: ordersStateType = {
  loadingOrders: false,
  orders: [],
  selectedOrders: new Set(),
  rejectedOrders: [],
  collectedOrders: [],
  erdSurveys: null,
  modalOrderId: null,
  searchString: '',
  sortOrdersFn: sortByOrderDatesDesc,
  sortOrdersDesc: true,
  currentPage: 1,
  totalPages: 0
};

export default (state = ordersState, action: Action): ordersStateType => {
  switch (action.type) {
    case actionTypes.SET_LOADING_ORDERS: {
      return {
        ...state,
        loadingOrders: action.payload
      };
    }
    case actionTypes.LOAD_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loadingOrders: false
      };
    case actionTypes.LOAD_ERD_SURVEYS:
      return {
        ...state,
        erdSurveys: action.payload
      };
    case actionTypes.LOAD_REJECTED_ORDERS:
      return {
        ...state,
        rejectedOrders: action.payload,
        loadingOrders: false
      };
    case actionTypes.LOAD_COLLECTED_ORDERS:
      return {
        ...state,
        collectedOrders: action.payload.orders ? action.payload.orders : action.payload,
        currentPage: action.payload.currentPage ? action.payload.currentPage : 0,
        totalPages: action.payload.totalPages ? action.payload.totalPages : 0,
        loadingOrders: false
      };
    case actionTypes.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload
      };
    case actionTypes.SET_ORDER_SORT:
      return {
        ...state,
        sortOrdersFn: action.payload,
        sortOrdersDesc: action.payload === state.sortOrdersFn ? !state.sortOrdersDesc : true
      };
    case actionTypes.SET_MODAL_ORDER:
      return {
        ...state,
        modalOrderId: action.payload
      };
    case actionTypes.SELECT_ORDER: {
      if (state.selectedOrders.delete(action.payload)) {
        // Successfully deleted -> return a new set
        return {
          ...state,
          selectedOrders: new Set(state.selectedOrders)
        };
      } else {
        // Otherwise return new set with ID added
        return {
          ...state,
          selectedOrders: new Set(state.selectedOrders.add(action.payload))
        };
      }
    }
    case actionTypes.SELECT_ALL_ORDERS: {
      const ordersWithStatus = state.orders.filter(order =>
        action.payload.includes(order.orderStatus)
      );

      const getFullNewSet = () => new Set(ordersWithStatus.map(({ id }) => id));

      // If the previous selected orders were of a different status, we just replace them all with
      // the orders with the new status
      if (
        state.selectedOrders.size > 0 &&
        !action.payload.includes(
          state.orders.find(o => o.id === Array.from(state.selectedOrders)[0])?.orderStatus
        )
      ) {
        return {
          ...state,
          selectedOrders: getFullNewSet()
        };
      } else {
        if (state.selectedOrders.size === ordersWithStatus.length) {
          // All already selected, so deselect all
          return {
            ...state,
            selectedOrders: new Set()
          };
        } else {
          // Final step is none/some were selected so we set them all
          return {
            ...state,
            selectedOrders: getFullNewSet()
          };
        }
      }
    }
    case actionTypes.DESELECT_ALL_ORDERS:
      return {
        ...state,
        selectedOrders: new Set()
      };
    case actionTypes.CLEAR_ALL_ORDERS:
      return ordersState;
    default:
      return state;
  }
};

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

const StyledPre = styled.pre`
  background-color: #eee;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  white-space: pre-wrap;
`;

export default () => {
  return (
    <Row>
      <Col sm={8} md={6}>
        <h4 className="mb-4 mt-5">Website Booking Widget</h4>
        <p>Add the following HTML to your website to allow users access to the guest booking:</p>
        <StyledPre>
          &lt;iframe src="https://managemymeds.co.uk/services-iframe?labelColour=black&owner=true"
          style="border:0px solid white; width:100%; height:1020px" name="intelligenceWidgetIframe"
          frameBorder="0px" marginHeight="0px" marginWidth="0px" scrolling="auto"
          &gt;&lt;/iframe&gt;
        </StyledPre>
      </Col>
    </Row>
  );
};

import { connect } from 'react-redux';
import Header from './Header';
import { GlobalState } from '../reducers';
import { getUsername, getSelectedPharmacy, getSelectedGroup } from '../selectors/user';
import {
  signOut,
  setSelectedGroup,
  setSelectedPharmacy,
  resetOwner,
  getUserPharmacies
} from '../actions/user';

const mapStateToProps = (state: GlobalState) => ({
  username: getUsername(state),
  selectedPharmacy: getSelectedPharmacy(state),
  selectedGroup: getSelectedGroup(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  signOut: () => dispatch(signOut()),
  switchSelection: () => {
    dispatch(resetOwner());
    dispatch(setSelectedPharmacy(null));
    dispatch(setSelectedGroup(null));
    dispatch(getUserPharmacies());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

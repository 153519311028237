import React, { useState } from 'react';
import { ERDSurveyAPIResponse, Order } from '@avicennapharmacy/managemymeds-shared';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { printOrders, printNames, printERDNames } from '../../utils/print';
import { updateOrderStatus, getNextStatusText, setOrdersPrinted } from '../../utils';
import { GlobalState } from '../../reducers';
import { getUsername } from '../../selectors/user';
import { connect } from 'react-redux';
import { getErdSurveys } from '../../selectors/orders';

const mapStateToProps = (state: GlobalState) => ({
  username: getUsername(state),
  erdSurveys: getErdSurveys(state)
});

type ConfirmationModalProps = {
  show: boolean;
  onHide: () => void;
  selectedOrders: Order[];
  selectedPharmacy?: any;
  username?: string;
  erdSurveys?: ERDSurveyAPIResponse | null;
};

export const PrintConfirmationModal = connect(
  mapStateToProps,
  null
)(({ show, onHide, selectedOrders, selectedPharmacy, username }: ConfirmationModalProps) => (
  <Modal show={show} onHide={() => onHide()}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Print - Repeat Requests</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to print {selectedOrders.length} repeat requests?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => onHide()}>
        No
      </Button>
      <Button
        variant="danger"
        onClick={() => {
          printOrders(selectedOrders, selectedPharmacy, username!);
          setOrdersPrinted(selectedOrders.map(({ id }) => id));
          onHide();
        }}
      >
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
));

export const PrintListConfirmationModal = connect(
  mapStateToProps,
  null
)(({ show, onHide, selectedOrders, username }: ConfirmationModalProps) => (
  <Modal show={show} onHide={() => onHide()}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Print - Summary Name List</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to print {selectedOrders.length} selected patients as a name list?
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => onHide()}>
        No
      </Button>
      <Button
        variant="danger"
        onClick={() => {
          printNames(selectedOrders, username!);
          onHide();
        }}
      >
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
));

export const PrintERDListConfirmationModal = connect(
  mapStateToProps,
  null
)(({ show, onHide, selectedOrders, username, erdSurveys }: ConfirmationModalProps) => (
  <Modal show={show} onHide={() => onHide()}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Print - ERD Name List</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to print {selectedOrders.length} selected patients as an ERD name list?
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => onHide()}>
        No
      </Button>
      <Button
        variant="danger"
        onClick={() => {
          printERDNames(selectedOrders, erdSurveys, username!);
          onHide();
        }}
      >
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
));

export const UpdateConfirmationModal = ({
  show,
  onHide,
  selectedOrders
}: ConfirmationModalProps) => {
  const [loading, setLoading] = useState(false);

  if (!show) {
    return null;
  }

  return (
    <Modal show={show} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Status Update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to move {selectedOrders.length} orders to{' '}
        <strong>{getNextStatusText(selectedOrders[0]?.orderStatus)}</strong>?
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} variant="secondary" onClick={() => onHide()}>
          No
        </Button>
        <Button
          disabled={loading}
          variant="danger"
          onClick={async () => {
            setLoading(true);
            await updateOrderStatus({
              ids: selectedOrders.map(({ id }) => id),
              orderStatus: selectedOrders[0].orderStatus
            });
            setLoading(false);
            onHide();
          }}
        >
          {loading ? 'Working' : 'Yes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import { PharmacyTabType } from '.';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { capitalize } from '@avicennapharmacy/managemymeds-shared';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types';

type DayOfWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export default ({ pharmacy, setPharmacy }: PharmacyTabType) => {
  if (!pharmacy || !pharmacy.pharmacyOpeningTimes) {
    return (
      <>
        <h4 className="mb-4">Opening Hours</h4>
        <em>No pharmacy selected</em>
      </>
    );
  }

  const updateTime = (
    day: DayOfWeek,
    intervalIndex: number,
    point: 'from' | 'to',
    newTime: string
  ) => {
    const newTimes = Array.from(pharmacy.pharmacyOpeningTimes![day]);
    newTimes[intervalIndex][point] = newTime;

    setPharmacy({
      ...pharmacy,
      pharmacyOpeningTimes: {
        ...pharmacy.pharmacyOpeningTimes!,
        [day]: newTimes
      }
    });
  };

  return (
    <>
      <h4 className="mb-4 mt-5">Opening Hours</h4>
      {Object.entries(pharmacy.pharmacyOpeningTimes).map(([day, times], i) => {
        if (!times.length) {
          return (
            <Form.Group key={day} as={Row} className={i % 2 === 0 ? 'bg-light py-2' : 'py-2'}>
              <Form.Label column sm={2}>
                {capitalize(day)}
              </Form.Label>
              <Col sm={8}>
                <Row>
                  <Form.Label column sm={1}>
                    Closed:
                  </Form.Label>
                  <Col sm={2}>
                    <Form.Control
                      type="checkbox"
                      style={{ width: 'auto' }}
                      checked={times.length === 0}
                      onChange={() =>
                        setPharmacy({
                          ...pharmacy,
                          pharmacyOpeningTimes: {
                            ...pharmacy.pharmacyOpeningTimes!,
                            [day]: [
                              {
                                from: '09:00',
                                to: '17:00'
                              }
                            ]
                          }
                        })
                      }
                      disabled={!hasRole(Role.Admin_Services_Edit)}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          );
        }

        return (
          <Form.Group key={day} as={Row} className={i % 2 === 0 ? 'bg-light py-2' : 'py-2'}>
            <Form.Label column sm={2}>
              {day.charAt(0).toLocaleUpperCase() + day.slice(1)}
            </Form.Label>
            <Col sm={8}>
              <Row>
                <Form.Label column sm={1}>
                  Closed:
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="checkbox"
                    style={{ width: 'auto' }}
                    checked={times.length === 0}
                    onChange={() =>
                      setPharmacy({
                        ...pharmacy,
                        pharmacyOpeningTimes: {
                          ...pharmacy.pharmacyOpeningTimes!,
                          [day]: []
                        }
                      })
                    }
                    disabled={!hasRole(Role.Admin_Services_Edit)}
                  />
                </Col>
              </Row>
              <Row>
                {times.map((t, i) => (
                  <Col key={i} sm={5}>
                    <Row>
                      <Form.Label column sm={4}>
                        From:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="time"
                          min="0:00"
                          max={t.to}
                          value={t.from}
                          onChange={(e: any) =>
                            updateTime(day as DayOfWeek, i, 'from', e.target.value)
                          }
                          disabled={!hasRole(Role.Admin_Services_Edit)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Form.Label column sm={4}>
                        To:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="time"
                          min={t.from}
                          max="23:59"
                          value={t.to}
                          onChange={(e: any) =>
                            updateTime(day as DayOfWeek, i, 'to', e.target.value)
                          }
                          disabled={!hasRole(Role.Admin_Services_Edit)}
                        />
                      </Col>
                    </Row>
                    {i === 0 && hasRole(Role.Admin_Services_Edit) && (
                      <Row className="mt-3 mb-2 ml-1">
                        {times.length === 1 ? (
                          <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() =>
                              setPharmacy({
                                ...pharmacy,
                                pharmacyOpeningTimes: {
                                  ...pharmacy.pharmacyOpeningTimes!,
                                  [day]: [
                                    ...pharmacy.pharmacyOpeningTimes![day as DayOfWeek],
                                    {
                                      from: pharmacy.pharmacyOpeningTimes![day as DayOfWeek][0].to,
                                      to: '20:00'
                                    }
                                  ]
                                }
                              })
                            }
                          >
                            Add second opening slot
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            variant="outline-danger"
                            onClick={() =>
                              setPharmacy({
                                ...pharmacy,
                                pharmacyOpeningTimes: {
                                  ...pharmacy.pharmacyOpeningTimes!,
                                  [day]: pharmacy.pharmacyOpeningTimes![day as DayOfWeek].filter(
                                    (p, i) => i < 1
                                  )
                                }
                              })
                            }
                          >
                            Remove second opening slot
                          </Button>
                        )}
                        <Button
                          size="sm"
                          className="ml-4"
                          variant="outline-primary"
                          onClick={() => {
                            const json = JSON.stringify(
                              pharmacy.pharmacyOpeningTimes![day as DayOfWeek]
                            );
                            setPharmacy({
                              ...pharmacy,
                              pharmacyOpeningTimes: {
                                ...pharmacy.pharmacyOpeningTimes,
                                monday: JSON.parse(json),
                                tuesday: JSON.parse(json),
                                wednesday: JSON.parse(json),
                                thursday: JSON.parse(json),
                                friday: JSON.parse(json),
                                saturday: JSON.parse(json),
                                sunday: JSON.parse(json)
                              }
                            });
                          }}
                        >
                          Copy to other days
                        </Button>
                      </Row>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
          </Form.Group>
        );
      })}
    </>
  );
};

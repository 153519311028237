import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, Store, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
import './App.scss';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { saveUserState, loadUserState } from './utils/storage';
import { addAxiosAuthInterceptors, addAxiosPharmacyInterceptors } from './utils/auth';
import Spinner from 'react-bootstrap/Spinner';
import { initAdminConfig, getAdminConfigVar } from '@avicennapharmacy/managemymeds-shared';
import 'react-datepicker/dist/react-datepicker.css';

let appInsights: ApplicationInsights, store: Store;

ReactDOM.render(
  <div style={{ marginTop: '150px', marginLeft: 'auto', marginRight: 'auto', width: '30px' }}>
    <Spinner variant="primary" animation="border" role="status" />
  </div>,
  document.getElementById('root') || document.createElement('div')
);

initAdminConfig(process.env.REACT_APP_CONFIG_API as string).then(() => {
  const persistedState = loadUserState();

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: getAdminConfigVar('frontendApplicationInsightsKey')
    }
  });
  if (process.env.NODE_ENV === 'production') {
    appInsights.loadAppInsights();
  }
  appInsights.trackPageView();
  appInsights.trackEvent({ name: 'Portal loaded' });

  const composeEnhancers =
    (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)));

  if (persistedState) {
    addAxiosAuthInterceptors();
    addAxiosPharmacyInterceptors();
  }

  store.subscribe(() => {
    saveUserState(store.getState());
  });

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root') || document.createElement('div')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});

export { appInsights, store };

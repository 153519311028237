import React, { useState } from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import { updateOrderStatus, getNextStatusText } from '../../utils';
import { Order, AMEND } from '@avicennapharmacy/managemymeds-shared';
import { UpdateConfirmationModal } from './ConfirmationModal';

interface StatusButtonProps extends ButtonProps {
  order: Order;
  onClick?: (e: any) => any;
}

export default ({ order, onClick, ...rest }: StatusButtonProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  if (order.rejected || order.orderStatus === AMEND) {
    return null;
  }

  return (
    <>
      <Button
        data-testid={'statusbutton-' + order.id}
        variant="primary"
        onClick={(e: any) => {
          if (onClick) {
            updateOrderStatus({ ids: [order.id], orderStatus: order.orderStatus });
            onClick(e);
          } else {
            setShowConfirmation(true);
          }
          e.stopPropagation();
        }}
        {...rest}
      >
        {getNextStatusText(order.orderStatus)}
      </Button>
      {showConfirmation && (
        <UpdateConfirmationModal
          show={true}
          selectedOrders={[order]}
          onHide={() => {
            setShowConfirmation(false);
          }}
        />
      )}
    </>
  );
};

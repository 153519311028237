import { connect } from 'react-redux';
import { GlobalState } from '../reducers';
import {
  getSelectedPharmacy,
  getSelectedGroup,
  getOwner,
  getLoadedOwner,
  getSelectedPharmacyID
} from '../selectors/user';
import Dashboard from './Dashboard';
import { loadOwner, resetOwner } from '../actions/user';

const mapStateToProps = (state: GlobalState) => ({
  pharmacy: getSelectedPharmacy(state),
  pharmacyId: getSelectedPharmacyID(state),
  group: getSelectedGroup(state),
  owner: getOwner(state),
  loadedOwner: getLoadedOwner(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  loadOwner: async (callback?: Function) => {
    dispatch(await loadOwner());
    callback && callback();
  },
  resetOwner: async () => {
    dispatch(await resetOwner());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

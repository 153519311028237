import { actionTypes } from '../actions/user';
import { Pharmacy, PharmacyGroup, Owner } from '@avicennapharmacy/managemymeds-shared';
import { Role } from '../types';

export type userStateType = {
  username: string | null;
  refreshToken: string | null;
  pharmacies: Pharmacy[];
  selectedPharmacy: string | null;
  groups: PharmacyGroup[];
  selectedGroup: string | null;
  owner: Owner | null;
  loadedOwner: boolean;
  loadedPharmacies: boolean;
  roles: Role[];
  fetchedRoles: boolean;
};

export const userState: userStateType = {
  username: null,
  refreshToken: null,
  pharmacies: [],
  selectedPharmacy: null,
  groups: [],
  selectedGroup: null,
  owner: null,
  loadedOwner: false,
  loadedPharmacies: false,
  roles: [],
  fetchedRoles: false
};

export default (state = userState, action: Action): userStateType => {
  if (actionTypes === undefined) {
    return state;
  }
  switch (action.type) {
    case actionTypes.SIGN_IN:
      return {
        ...state,
        username: action.payload.username
      };
    case actionTypes.GET_PHARMACIES:
      return {
        ...state,
        loadedPharmacies: true
      };
    case actionTypes.SET_PHARMACIES:
      return {
        ...state,
        pharmacies: action.payload.pharmacies
      };
    case actionTypes.SET_SELECTED_PHARMACY:
      return {
        ...state,
        selectedGroup: null,
        selectedPharmacy: action.payload.id
      };
    case actionTypes.SET_GROUPS:
      return {
        ...state,
        groups: action.payload.groups
      };
    case actionTypes.SET_SELECTED_GROUP:
      return {
        ...state,
        selectedPharmacy: null,
        selectedGroup: action.payload.id
      };
    case actionTypes.LOAD_OWNER:
      return {
        ...state,
        owner: action.payload,
        loadedOwner: true
      };
    case actionTypes.RESET_LOADED_OWNER:
      return {
        ...state,
        owner: null,
        loadedOwner: false
      };
    case actionTypes.UPDATE_OWNER:
      return {
        ...state,
        owner: { ...action.payload },
        loadedOwner: true
      };
    case actionTypes.GET_PERMISSIONS:
      return {
        ...state,
        roles: action.payload,
        fetchedRoles: true
      };
    case actionTypes.SIGN_OUT:
      return userState;
    default:
      return state;
  }
};

import keyMirror from 'keymirror';
import { clearAccessToken, clearRefreshToken } from '../utils/auth';
import { appInsights } from '..';
import axios, { AxiosResponse } from 'axios';
import { getAdminConfigVar, Owner, Pharmacy, Group } from '@avicennapharmacy/managemymeds-shared';

export const actionTypes = keyMirror({
  SIGN_IN: null,
  SIGN_OUT: null,
  GET_PHARMACIES: null,
  SET_PHARMACIES: null,
  SET_SELECTED_PHARMACY: null,
  SET_GROUPS: null,
  SET_SELECTED_GROUP: null,
  LOAD_OWNER: null,
  RESET_LOADED_OWNER: null,
  UPDATE_OWNER: null,
  GET_PERMISSIONS: null
});

export const signIn = (username: string): Action => ({
  type: actionTypes.SIGN_IN,
  payload: { username }
});

export const signOut = (): Action => {
  clearAccessToken();
  clearRefreshToken();
  return {
    type: actionTypes.SIGN_OUT
  };
};

export const getUserPharmacies = () => async (
  dispatch: (arg: Action) => { pharmacies: Pharmacy[]; groups: Group[] }
) => {
  const { data } = await axios.get<any, AxiosResponse<any>>(
    getAdminConfigVar('userPharmaciesEndpoint')
  );
  dispatch(setPharmacies(data.pharmacies));
  dispatch(setGroups(data.groups));
  return data;
};

export const getUserPermissions = async (): Promise<Action> => {
  const permissions = await axios.get(getAdminConfigVar('userPermissionsEndpoint'));

  if (!permissions.data?.roles) {
    throw Error('Incorrect permissions response');
  }

  return {
    type: actionTypes.GET_PERMISSIONS,
    payload: permissions.data?.roles
  };
};

export const setPharmacies = (pharmacies: Pharmacy[]): Action => ({
  type: actionTypes.SET_PHARMACIES,
  payload: { pharmacies }
});

export const setGroups = (groups: Group[]): Action => ({
  type: actionTypes.SET_GROUPS,
  payload: { groups }
});

export const setSelectedPharmacy = (id: string | null): Action => {
  appInsights.trackEvent({ name: 'Select pharmacy', properties: { id } });
  return {
    type: actionTypes.SET_SELECTED_PHARMACY,
    payload: { id }
  };
};

export const setSelectedGroup = (id: string | null): Action => {
  appInsights.trackEvent({ name: 'Select group', properties: { id } });
  return {
    type: actionTypes.SET_SELECTED_GROUP,
    payload: { id }
  };
};

export const loadOwner = async (): Promise<Action> => {
  const { data } = await axios.post<any, AxiosResponse<any>>(
    getAdminConfigVar('serviceBookingGetOwnerEndpoint')
  );
  return {
    type: actionTypes.LOAD_OWNER,
    payload: data.result
  };
};

export const resetOwner = (): Action => {
  return {
    type: actionTypes.RESET_LOADED_OWNER
  };
};

export const updateOwner = (owner: Owner | null): Action => {
  return {
    type: actionTypes.UPDATE_OWNER,
    payload: owner
  };
};

import styled from 'styled-components';
import Container from 'react-bootstrap/Container';

export default styled(Container)`
  margin-top: 2em;
  max-width: 90vw;
  .react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none !important;
  }
`;

import React, { SetStateAction, Dispatch } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Service, SubService, ServiceNamesLookup } from '@avicennapharmacy/managemymeds-shared';
import DatePicker from 'react-datepicker';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types';
import Select from 'react-select';
import SelectFeedback from './SelectFeedback';

const TEXT_MAX_LENGTH = 100;

type ServiceSubTypeProps = {
  validated: boolean;
  service: Service;
  serviceSubType: SubService;
  serviceNamesLookup: ServiceNamesLookup | null;
  onServiceChange: Dispatch<SetStateAction<Service | null>>;
  i: number;
};

const ServiceSubType = ({
  validated,
  service,
  serviceSubType,
  serviceNamesLookup,
  onServiceChange,
  i
}: ServiceSubTypeProps) => {
  const getSubServices = (subs: any) => {
    if (Object.keys(subs).length) {
      var subServices = [];

      for (var key in subs) {
        var serviceName = serviceNamesLookup
          ? serviceNamesLookup[service.serviceNameId].subs[key]
          : null;
        if (serviceName?.active) {
          subServices.push({
            value: key,
            label: serviceName.name
          });
        }
      }

      return subServices;
    }

    return [
      {
        value: '',
        label: ''
      }
    ];
  };

  return (
    <Row key={i} className="mb-1">
      <Col sm={8} xs={8}>
        <Form.Group>
          <Select
            options={getSubServices(
              serviceNamesLookup ? serviceNamesLookup[service.serviceNameId].subs : ''
            )}
            value={
              serviceSubType.serviceNameId.length
                ? {
                    value: serviceSubType.serviceNameId,
                    label: serviceNamesLookup
                      ? serviceNamesLookup[service.serviceNameId].subs[serviceSubType.serviceNameId]
                          .name
                      : ''
                  }
                : {
                    value: '',
                    label: ''
                  }
            }
            onChange={(e: any) => {
              const newSubServices = service.subServices?.length ? service.subServices : [];
              newSubServices[i] = {
                ...newSubServices[i],
                serviceNameId: e.value,
                service: e.label
              };
              onServiceChange({
                ...service,
                subServices: [...newSubServices]
              });
            }}
            isDisabled={!hasRole(Role.Admin_Services_Edit)}
          />
          <SelectFeedback
            isInvalid={!service.serviceNameId && validated}
            feedback="Please select a service name"
          />
        </Form.Group>
      </Col>
      <Col sm={2} xs={2}>
        <DatePicker
          className="form-control"
          dateFormat="dd/MM/yyyy"
          selected={serviceSubType.startDt ? new Date(serviceSubType.startDt) : undefined}
          onChange={(date: Date) => {
            const newSubServices = service.subServices?.length ? service.subServices : [];
            newSubServices[i] = {
              ...newSubServices[i],
              startDt: date ? new Date(date).toISOString() : ''
            };
            onServiceChange({
              ...service,
              subServices: [...newSubServices]
            });
          }}
          disabled={!hasRole(Role.Admin_Services_Edit)}
        />
      </Col>
      {hasRole(Role.Admin_Services_Edit) && (
        <Col sm={2} xs={2}>
          {service.subServices!.length > 1 && (
            <Button
              variant="danger"
              onClick={() => {
                const serviceSubIndex = service.subServices?.findIndex(
                  a => a.service === serviceSubType.service
                );
                onServiceChange({
                  ...service,
                  subServices: service.subServices?.filter(
                    (ss: SubService, i: number) => i !== serviceSubIndex
                  )
                });
              }}
              disabled={!hasRole(Role.Admin_Services_Edit)}
            >
              Remove
            </Button>
          )}
        </Col>
      )}
    </Row>
  );
};

export default ServiceSubType;

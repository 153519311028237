import { combineReducers } from 'redux';
import bookingsReducer, { bookingsStateType } from './bookings';
import ordersReducer, { ordersStateType } from './orders';
import userReducer, { userStateType } from './user';
import servicesReducer, { servicesStateType } from './services';

export default combineReducers({
  bookings: bookingsReducer,
  orders: ordersReducer,
  user: userReducer,
  services: servicesReducer
});

export type GlobalState = {
  bookings: bookingsStateType;
  orders: ordersStateType;
  user: userStateType;
  services: servicesStateType;
};

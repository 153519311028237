import React, { useEffect, useRef, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import OrderTable from './OrderTable.container';
import {
  NEW,
  AMEND,
  RAISED,
  READY_FOR_COLLECTION,
  REJECTED,
  COLLECTED,
  NEW_REPEAT_DISPENSING,
  RAISED_IM1,
  IM1_WITH_GP_TOO_LONG,
  GPAPPROVED,
  Order,
  Pharmacy
} from '@avicennapharmacy/managemymeds-shared';
import { withRouter, RouteComponentProps } from 'react-router';
import Spinner from 'react-bootstrap/Spinner';
import { isIM1 } from '../../utils';
import styled from 'styled-components';

export type OrdersProps = RouteComponentProps & {
  loadingOrders: boolean;
  loadOrders: (callback?: Function) => void;
  loadRejectedOrders: () => void;
  loadCollectedOrders: (offset?: number, limit?: number, orderByDesc?: boolean) => void;
  loadErdSurveys: () => void;
  orderCounts: any;
  rejectedOrders: Order[];
  collectedOrders: Order[];
  tabKey: string | undefined;
  pharmacy: Pharmacy | null | undefined;
  currentPage: number;
  totalPages: number;
};

const Orders = ({
  loadingOrders,
  loadOrders,
  loadRejectedOrders,
  loadCollectedOrders,
  loadErdSurveys,
  orderCounts,
  rejectedOrders,
  collectedOrders,
  tabKey,
  history,
  pharmacy,
  currentPage,
  totalPages
}: OrdersProps) => {
  const [page, setPage] = useState(currentPage);
  const prevPageRef = useRef(0);

  useEffect(() => {
    loadOrders();
    loadErdSurveys();
    loadRejectedOrders();
    loadCollectedOrders((page - 1) * 50, 50, true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (page !== prevPageRef.current) {
      prevPageRef.current = page;
      loadCollectedOrders((page - 1) * 50, 50, true);
    } else if (page === prevPageRef.current && currentPage !== page) {
      setPage(1);
      prevPageRef.current = 1;
    }
  }, [page, collectedOrders, loadCollectedOrders, currentPage]);

  if (loadingOrders) {
    return (
      <div className="text-center">
        <Spinner variant="primary" animation="border" role="status" />
      </div>
    );
  }

  const showIM1Statuses = isIM1();

  return (
    <Tabs
      activeKey={tabKey ?? NEW}
      onSelect={(key: string) => history.push(`/Orders/${key}`)}
      id="status-tabs"
    >
      {pharmacy?.allowManualReordering && (
        <Tab eventKey={NEW} title={`New (${orderCounts[NEW]})`}>
          <OrderTable
            statuses={[NEW]}
            info={newInfo}
            isPaged={false}
            currentPage={currentPage}
            totalPages={totalPages}
            setPage={setPage}
          />
        </Tab>
      )}
      <Tab
        eventKey={RAISED}
        title={`With GP (${orderCounts[RAISED] +
          orderCounts[RAISED_IM1] +
          orderCounts[IM1_WITH_GP_TOO_LONG]})`}
      >
        <OrderTable
          statuses={[RAISED, RAISED_IM1, IM1_WITH_GP_TOO_LONG]}
          info={withGpInfo}
          isPaged={false}
          currentPage={currentPage}
          totalPages={totalPages}
          setPage={setPage}
        />
      </Tab>
      <Tab eventKey={GPAPPROVED} title={`With Pharmacy (${orderCounts[GPAPPROVED]})`}>
        <OrderTable
          statuses={[GPAPPROVED]}
          info={gpApprovedInfo}
          isPaged={false}
          currentPage={currentPage}
          totalPages={totalPages}
          setPage={setPage}
        />
      </Tab>
      {showIM1Statuses && (
        <Tab
          eventKey={NEW_REPEAT_DISPENSING}
          title={`New ERD (${orderCounts[NEW_REPEAT_DISPENSING]})`}
        >
          <OrderTable
            statuses={[NEW_REPEAT_DISPENSING]}
            info={erdInfo}
            isPaged={false}
            currentPage={currentPage}
            totalPages={totalPages}
            setPage={setPage}
          />
        </Tab>
      )}
      <Tab
        eventKey={READY_FOR_COLLECTION}
        title={`Ready for collection (${orderCounts[READY_FOR_COLLECTION]})`}
      >
        <OrderTable
          statuses={[READY_FOR_COLLECTION]}
          info={collectionInfo}
          isPaged={false}
          currentPage={currentPage}
          totalPages={totalPages}
          setPage={setPage}
        />
      </Tab>
      {pharmacy?.allowManualReordering && (
        <Tab eventKey={AMEND} title={`Amendments (${orderCounts[AMEND]})`}>
          <OrderTable
            statuses={[AMEND]}
            info={amendInfo}
            isPaged={false}
            currentPage={currentPage}
            totalPages={totalPages}
            setPage={setPage}
          />
        </Tab>
      )}
      <Tab eventKey={REJECTED} title={`Rejected`}>
        <OrderTable
          statuses={[REJECTED]}
          orders={rejectedOrders}
          info={rejectedInfo}
          isPaged={false}
          currentPage={currentPage}
          totalPages={totalPages}
          setPage={setPage}
        />
      </Tab>
      <Tab eventKey={COLLECTED} title={`Archived Orders`}>
        <OrderTable
          statuses={[COLLECTED]}
          orders={collectedOrders}
          info={collectedInfo}
          isPaged={true}
          currentPage={currentPage}
          totalPages={totalPages}
          setPage={setPage}
        />
      </Tab>
    </Tabs>
  );
};

const ColouredSpan = styled.span`
  padding: 0 5px;
  border-radius: 5px;
`;

const newInfo = <>Orders created manually by users.</>;
const withGpInfo = (
  <>
    Orders that have been raised with the GP for approval.{' '}
    <ColouredSpan className="bg-warning">Amber</ColouredSpan> - 3 days waiting.{' '}
    <ColouredSpan className="bg-danger text-white">Red</ColouredSpan> - with GP too long.
  </>
);
const gpApprovedInfo = (
  <>
    Orders that have been approved by GP. {' '}
    <ColouredSpan className="bg-warning">Amber</ColouredSpan> - 3 days waiting. {' '}
    <ColouredSpan className="bg-danger text-white">Red</ColouredSpan> - 5 days
    waiting.
  </>
);
const collectionInfo = (
  <>
    Orders that are ready for collection by the user. Rows are coloured{' '}
    <ColouredSpan className="bg-warning">amber 7</ColouredSpan> or{' '}
    <ColouredSpan className="bg-danger text-white">red 14</ColouredSpan> days after being marked
    ready.
  </>
);
const erdInfo = (
  <>
    Orders automatically raised as part of the patient's electronic repeat dispensing schedule. Rows
    are coloured <ColouredSpan className="bg-danger text-white">red</ColouredSpan> if there are ERD
    issues that must be addressed.
  </>
);
const amendInfo = 'Orders that have been marked as requiring user amendment.';
const rejectedInfo = 'Orders that have been rejected in the past 12 weeks.';
const collectedInfo = 'Orders that have been collected in the past 12 weeks.';

export default withRouter(Orders);

import { connect } from 'react-redux';
import OrdersPage from './OrdersPage';
import { GlobalState } from '../../reducers';
import { getLoadingOrders, getSearchString, getSelectedOrders } from '../../selectors/orders';
import { getSelectedPharmacy } from '../../selectors/user';
import {
  setSearchString,
  deselectAllOrders,
  setLoadingOrders,
  loadOrders,
  loadRejectedOrders,
  loadCollectedOrders,
  loadErdSurveys
} from '../../actions/orders';
import { defaultOrdersToLoad } from '../../config/constants';
import { isIM1 } from '../../utils';

const mapStateToProps = (state: GlobalState) => ({
  searchString: getSearchString(state),
  selectedOrders: getSelectedOrders(state),
  selectedPharmacy: getSelectedPharmacy(state),
  loadingOrders: getLoadingOrders(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setSearchString: (searchString: string) => dispatch(setSearchString(searchString)),
  deselectAllOrders: () => dispatch(deselectAllOrders()),
  loadOrders: async (callback?: Function) => {
    dispatch(setLoadingOrders());
    dispatch(await loadOrders(defaultOrdersToLoad(isIM1())));
    callback && callback();
  },
  loadRejectedOrders: async () => {
    dispatch(setLoadingOrders());
    dispatch(await loadRejectedOrders());
  },
  loadCollectedOrders: async () => {
    dispatch(setLoadingOrders());
    dispatch(await loadCollectedOrders(0, 50, true));
  },
  loadErdSurveys: async () => {
    if (isIM1()) {
      dispatch(await loadErdSurveys());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);

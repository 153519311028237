import React from 'react';
import Form from 'react-bootstrap/Form';

type SelectFeedbackProps = {
  isInvalid: boolean;
  feedback: string;
};
const SelectFeedback = ({ isInvalid, feedback }: SelectFeedbackProps) => (
  <>
    <Form.Control
      tabIndex={-1}
      style={{
        opacity: 0,
        height: 0,
        display: isInvalid ? 'none' : ''
      }}
      isInvalid={isInvalid}
    />
    <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
  </>
);

export default SelectFeedback;

import React, { useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import Table from 'react-bootstrap/Table';
import differenceInDays from 'date-fns/differenceInDays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusInfoButton from './StatusButton';
import {
  NEW,
  RAISED,
  READY_FOR_COLLECTION,
  REJECTED,
  AMEND,
  COLLECTED,
  Order,
  formatDate,
  IM1_WITH_GP_TOO_LONG,
  RAISED_IM1,
  ERDSurveyAPIResponse,
  NEW_REPEAT_DISPENSING,
  ERDSurveyResponse,
  GPAPPROVED,
  getAdminConfigVar
} from '@avicennapharmacy/managemymeds-shared';
import {
  sortByCustomerNameDesc,
  sortByOrderDatesDesc,
  sortByPrintDatesDesc,
  sortByItemCountDesc,
  sortByEstimatedDatesDesc,
  sortByLatestStatusDateDesc,
  sortByCustomerAddressDesc,
  sortByGPSurgeryDesc
} from '../../utils/sort';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Pagination from '../Pagination';

const getLatestStatusDate = (order: Order, status: string, tryFormat: boolean = true): string => {
  if (status === IM1_WITH_GP_TOO_LONG) {
    status = 'WithGPTooLong';
  }
  const latestUpdate = order.statusUpdates.reverse().find(u => u.trackingStatus === status)
    ?.statusUpdateDt;
  if (!latestUpdate) {
    return '';
  }
  return tryFormat ? formatDate(latestUpdate, 'dateAndTime') : latestUpdate;
};

export type OrderTableProps = {
  orders: Order[];
  erdSurveys: ERDSurveyAPIResponse | null;
  selectedOrders: Set<string>;
  statuses: string[];
  sortFn: (a: Order, b: Order) => number;
  sortDesc: boolean;
  setModalOrder: (orderId: string) => void;
  selectOrder: (order: Order) => void;
  selectAllOrders: () => void;
  setOrderSort: (sortFn: (a: Order, b: Order) => number) => void;
  customLoad?: () => void;
  info: ReactNode;
  isPaged: boolean | null;
  currentPage: number;
  totalPages: number;
  setPage: (page: number) => void;
};

export default ({
  orders,
  erdSurveys,
  selectedOrders,
  statuses,
  sortFn,
  sortDesc,
  setModalOrder,
  selectOrder,
  selectAllOrders,
  setOrderSort,
  customLoad,
  info,
  isPaged,
  currentPage,
  totalPages,
  setPage
}: OrderTableProps) => {
  useEffect(() => {
    if (customLoad) {
      customLoad();
    }
    // eslint-disable-next-line
  }, []);

  if (!orders?.length) {
    return (
      <EmptyDiv>
        <em>No orders found</em>
      </EmptyDiv>
    );
  }

  const getSortIcon = (colSortFn: (a: Order, b: Order) => number) => {
    let icon: IconProp = 'sort';
    if (sortFn === colSortFn) {
      icon = sortDesc ? 'sort-down' : 'sort-up';
    }
    return <FontAwesomeIcon icon={icon} />;
  };

  const showItems = statuses.some(s => [RAISED, READY_FOR_COLLECTION, REJECTED, NEW].includes(s));
  const showEstimatedCollection = statuses.includes(RAISED);
  const showPrinted = !statuses.some(s =>
    [RAISED, READY_FOR_COLLECTION, REJECTED, COLLECTED].includes(s)
  );

  const showUpdate =
    getAdminConfigVar('featureWithGpActionButton').toLowerCase() === 'true'
      ? !statuses.some(s => [AMEND, REJECTED, COLLECTED].includes(s))
      : !statuses.some(s =>
          [AMEND, REJECTED, COLLECTED, RAISED, RAISED_IM1, IM1_WITH_GP_TOO_LONG].includes(s)
        );
  const showIM1 = statuses.some(s => [RAISED_IM1, IM1_WITH_GP_TOO_LONG].includes(s));
  const showErdFlag = statuses.includes(NEW_REPEAT_DISPENSING);

  return (
    <MainDiv>
      <Alert variant="primary">{info}</Alert>
      <Table>
        <thead>
          <tr>
            <th
              onClick={(e: any) => {
                selectAllOrders();
                e.stopPropagation();
              }}
            >
              <input type="checkbox" checked={orders.length === selectedOrders.size} readOnly />
            </th>
            <StatusDateTh
              statuses={statuses}
              setOrderSort={setOrderSort}
              getSortIcon={getSortIcon}
            />
            <th onClick={() => setOrderSort(sortByCustomerNameDesc)}>
              Patient's Name {getSortIcon(sortByCustomerNameDesc)}
            </th>
            <th onClick={() => setOrderSort(sortByCustomerAddressDesc)}>
              Address {getSortIcon(sortByCustomerAddressDesc)}
            </th>
            <th onClick={() => setOrderSort(sortByGPSurgeryDesc)}>
              GP Surgery {getSortIcon(sortByGPSurgeryDesc)}
            </th>
            <th>Delivery</th>
            {showIM1 && <th>IM1</th>}
            {showItems && (
              <th onClick={() => setOrderSort(sortByItemCountDesc)}>
                No. Items {getSortIcon(sortByItemCountDesc)}
              </th>
            )}
            {showEstimatedCollection && (
              <th onClick={() => setOrderSort(sortByEstimatedDatesDesc)}>
                Est. Collection {getSortIcon(sortByEstimatedDatesDesc)}
              </th>
            )}
            {showPrinted && (
              <th onClick={() => setOrderSort(sortByPrintDatesDesc)}>
                Date Printed {getSortIcon(sortByPrintDatesDesc)}
              </th>
            )}
            {showErdFlag && <th>ERD Issues</th>}
            <th>View</th>
            {showUpdate && <th>Update Status</th>}
          </tr>
        </thead>
        <tbody>
          {orders.map(order => {
            const erdSurvey = erdSurveys ? erdSurveys[order.id] : null;

            return (
              <DateAutoColorTr
                order={order}
                statuses={statuses}
                key={order.id}
                erdSurvey={erdSurvey}
              >
                <td
                  onClick={(e: any) => {
                    selectOrder(order);
                    e.stopPropagation();
                  }}
                >
                  <input type="checkbox" checked={selectedOrders.has(order.id)} readOnly />
                </td>
                <StatusDateTd order={order} />
                <td>{order.patientName}</td>
                <td>{order.patientAddress}</td>
                <td>
                  {order.patientSurgeryAddress ? order.patientSurgeryAddress.split('\n')[0] : ''}
                </td>
                <td className="text-center">
                  {order.isDelivery ? <FontAwesomeIcon icon="shuttle-van" /> : ''}
                </td>
                {showIM1 && (
                  <td className="text-center">
                    {[RAISED_IM1, IM1_WITH_GP_TOO_LONG].includes(order.orderStatus) ? (
                      <FontAwesomeIcon icon="check" />
                    ) : (
                      ''
                    )}
                  </td>
                )}
                {showItems && <td>{order.items.length}</td>}
                {showEstimatedCollection && (
                  <DateAutoColorTd
                    date={order.estimatedCollectionDate ?? ''}
                    amberDays={3}
                    redDays={5}
                    showShortDate
                  />
                )}
                {showPrinted && (
                  <td>{order.printDt ? formatDate(order.printDt, 'dateAndTime') : ''}</td>
                )}
                {showErdFlag && (
                  <td className="text-center text-danger">
                    {!erdSurvey ||
                    erdSurvey.rejectedItemIds.length > 0 ||
                    erdSurvey.surveyResponses.some(s => s.responseRequiresIntervention) ? (
                      <FontAwesomeIcon icon="exclamation-circle" />
                    ) : (
                      ''
                    )}
                  </td>
                )}
                <td>
                  <Button size="sm" variant="secondary" onClick={() => setModalOrder(order.id)}>
                    View
                  </Button>
                </td>
                {showUpdate && (
                  <td>
                    <StatusInfoButton size="sm" order={order} />
                  </td>
                )}
              </DateAutoColorTr>
            );
          })}
        </tbody>
      </Table>
      {isPaged && (
        <Pagination currentPage={currentPage} totalPages={totalPages} setPage={setPage} />
      )}
    </MainDiv>
  );
};

const StatusDateTh = ({
  statuses,
  setOrderSort,
  getSortIcon
}: {
  statuses: string[];
  setOrderSort: any;
  getSortIcon: any;
}) => {
  let text: string = '',
    sortFn: (a: Order, b: Order) => number = sortByLatestStatusDateDesc;

  if (statuses.some(s => [RAISED, RAISED_IM1, IM1_WITH_GP_TOO_LONG].includes(s))) {
    text = 'Requested';
  } else if (statuses.includes(READY_FOR_COLLECTION)) {
    text = 'Completed';
  } else if (statuses.includes(REJECTED)) {
    text = 'Rejected';
  } else if (statuses.includes(COLLECTED)) {
    text = 'Collected';
  } else {
    text = 'Ordered';
    sortFn = sortByOrderDatesDesc;
  }

  return (
    <th onClick={() => setOrderSort(sortFn)}>
      {`Date ${text}`} {getSortIcon(sortFn)}
    </th>
  );
};

const StatusDateTd = ({ order }: { order: Order }) => {
  if ([NEW, AMEND, RAISED, RAISED_IM1, IM1_WITH_GP_TOO_LONG].includes(order.orderStatus)) {
    return <td>{formatDate(order.orderDt, 'dateAndTime')}</td>;
  }
  if (order.orderStatus === READY_FOR_COLLECTION) {
    return (
      <DateAutoColorTd
        date={getLatestStatusDate(order, order.orderStatus, false)}
        amberDays={7}
        redDays={14}
      />
    );
  }
  return <td>{getLatestStatusDate(order, order.orderStatus)}</td>;
};

const DateAutoColorTd = ({
  date,
  amberDays,
  redDays,
  showShortDate = false
}: {
  date: string;
  amberDays: number;
  redDays: number;
  showShortDate?: boolean;
}) => {
  if (date === '') {
    return <td></td>;
  }
  const numDays = differenceInDays(new Date(), new Date(date));
  let color;
  if (numDays >= redDays) {
    color = 'red';
  } else if (numDays >= amberDays) {
    color = '#774821';
  }
  return (
    <ColoredTd color={color}>{formatDate(date, showShortDate ? 'date' : 'dateAndTime')}</ColoredTd>
  );
};

const DateAutoColorTr = ({
  order,
  statuses,
  erdSurvey,
  children
}: {
  order: Order;
  statuses: string[];
  erdSurvey: ERDSurveyResponse | null;
  children: ReactNode;
}) => {
  let colourDate = '',
    amberDays = 0,
    redDays = 0;
  if (statuses.includes(GPAPPROVED)) {
    colourDate = getLatestStatusDate(order, order.orderStatus, false);
    amberDays = 3;
    redDays = 5;
  } else if (statuses.includes(RAISED)) {
    colourDate = getLatestStatusDate(order, order.orderStatus, false);
    amberDays = 3;
    redDays = 5;
  } else if (statuses.includes(READY_FOR_COLLECTION)) {
    colourDate = getLatestStatusDate(order, order.orderStatus, false);
    amberDays = 7;
    redDays = 14;
  }

  if (order.orderStatus === IM1_WITH_GP_TOO_LONG) {
    return <ColoredTr color="255,0,0">{children}</ColoredTr>;
  }

  if (
    order.orderStatus === NEW_REPEAT_DISPENSING &&
    (!erdSurvey ||
      erdSurvey.rejectedItemIds.length > 0 ||
      erdSurvey.surveyResponses.some(s => s.responseRequiresIntervention))
  ) {
    return <ColoredTr color="255,0,0">{children}</ColoredTr>;
  }

  if (colourDate === '') {
    return <tr>{children}</tr>;
  }
  const numDays = differenceInDays(new Date(), new Date(colourDate));
  let color;
  if (numDays >= redDays) {
    color = '255,0,0';
  } else if (numDays >= amberDays) {
    color = '241,145,67';
  }
  return (
    <ColoredTr color={color} data-testid={order.orderStatus + '-' + order.id}>
      {children}
    </ColoredTr>
  );
};

const MainDiv = styled.div`
  margin-top: 1em;
`;

const EmptyDiv = styled.div`
  margin-top: 1em;
  text-align: center;
`;

const ColoredTd = styled.td`
  color: ${props => props.color ?? 'inherit'};
`;

const ColoredTr = styled.tr`
  background-color: ${props => (props.color ? `rgba(${props.color},0.2)` : 'inherit')};
  &:hover {
    background-color: ${props =>
      props.color ? `rgba(${props.color},0.4)` : 'rgba(33,37,41,0.075)'} !important;
  }
`;

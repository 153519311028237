import React, { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
  formatDate,
  getAdminConfigVar,
  Owner,
  Resource,
  ResourceAddress
} from '@avicennapharmacy/managemymeds-shared';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import OpeningHours from './OpeningHours';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomAlert from 'components/common/alert';
import { CreateResourceResponse } from 'types';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types';

const StyledForm = styled(Form)`
  padding-top: 20px;
`;

const AddButton = styled(Button)`
  margin-left: 12px;
`;

const CustomAddressContainer = styled.div`
  padding-top: 20px;
`;

type RoomsTabProps = {
  owner: Owner;
  fetchResources: () => void;
  resources: Resource[];
  loading: boolean;
};

export default ({ owner, fetchResources, resources, loading }: RoomsTabProps) => {
  const [resourceBeingEdited, setResourceBeingEdited] = useState<Resource | null>(null);
  const [, setError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState<string | null>(null);

  const closeModal = () => {
    setModalError(null);
    setShowModal(false);
    setResourceBeingEdited(null);
  };

  if (loading) {
    return <Spinner variant="primary" animation="border" role="status" />;
  }

  return (
    <>
      <h4 className="mb-4 mt-5">Rooms</h4>
      {hasRole(Role.Admin_Services_Edit) && (
        <Row className="mb-4">
          <Col>
            <Button
              onClick={() => {
                setResourceBeingEdited({
                  id: '',
                  groupId: owner.groupId,
                  ownerId: owner.id,
                  name: '',
                  openingHours: null,
                  closedDates: [],
                  slotIntervalMinutes: 1,
                  address: {
                    address1: '',
                    address2: '',
                    address3: '',
                    county: '',
                    city: '',
                    postcode: ''
                  },
                  contactNumber: null,
                  contactEmail: null
                });
                setShowModal(true);
              }}
            >
              Add new room
            </Button>
          </Col>
        </Row>
      )}
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Slot interval</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {resources.map(r => {
            return (
              <tr key={r.id}>
                <td>{r.name}</td>
                <td>{r.slotIntervalMinutes} mins</td>
                <td>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setShowModal(true);
                      setResourceBeingEdited(r);
                    }}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <EditModal
        show={showModal}
        onHide={closeModal}
        resource={resourceBeingEdited}
        onResourceChange={setResourceBeingEdited}
        loading={saving}
        error={modalError}
        setModalError={setModalError}
        onSave={async () => {
          try {
            setSaving(true);
            let url = getAdminConfigVar('serviceBookingUpdateResourceEndpoint');
            if (resourceBeingEdited?.id === '') {
              url = getAdminConfigVar('serviceBookingCreateResourceEndpoint');
            }
            const response: CreateResourceResponse = (
              await axios.post<any, AxiosResponse<CreateResourceResponse>>(url, resourceBeingEdited)
            ).data;
            if (response.showCustomMessage) {
              setModalError(response.message);
              setSaving(false);
            } else {
              setSaving(false);
              setError(false);
              closeModal();
              await fetchResources();
            }
          } catch (err) {
            setError(true);
          }
        }}
      />
    </>
  );
};

type EditModalProps = {
  show: boolean;
  onHide: () => void;
  resource: Resource | null;
  onResourceChange: React.Dispatch<React.SetStateAction<Resource | null>>;
  onSave: () => Promise<void>;
  loading: boolean;
  error: string | null;
  setModalError: React.Dispatch<React.SetStateAction<string | null>>;
};
function EditModal({
  show,
  onHide,
  resource,
  onResourceChange,
  onSave,
  loading,
  error,
  setModalError
}: EditModalProps) {
  const [useCustomHours, setUseCustomHours] = useState(false);
  const [useCustomAddress, setUseCustomAddress] = useState(false);
  const [selectedClosedDate, setSelectedClosedDate] = useState<string | null>(null);

  const id = resource?.id;

  useEffect(() => {
    setUseCustomHours(resource?.openingHours !== null);
    setUseCustomAddress(
      resource?.address !== null ||
        resource?.contactNumber !== null ||
        resource?.contactEmail !== null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getAddress = (address: ResourceAddress | null) =>
    address ?? {
      address1: '',
      address2: '',
      address3: '',
      city: '',
      county: '',
      postcode: ''
    };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Editing: {resource?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {resource && (
          <Tabs defaultActiveKey="resource" id="edit-resource-modal">
            <Tab eventKey="resource" title="Room configuration">
              <StyledForm>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={resource.name}
                    onChange={(e: any) => {
                      onResourceChange({
                        ...resource,
                        name: e.target.value
                      });
                      setModalError(null);
                    }}
                    disabled={!hasRole(Role.Admin_Services_Edit)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Slot interval (minutes)</Form.Label>
                  <Form.Control
                    type="number"
                    value={String(resource.slotIntervalMinutes)}
                    onChange={(e: any) =>
                      onResourceChange({
                        ...resource,
                        slotIntervalMinutes: Number(e.target.value)
                      })
                    }
                    disabled={!hasRole(Role.Admin_Services_Edit)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Dates closed</Form.Label>
                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={selectedClosedDate ? new Date(selectedClosedDate) : undefined}
                      onChange={(date: Date) => {
                        if (date) {
                          setSelectedClosedDate(date.toISOString());
                        } else {
                          setSelectedClosedDate(null);
                        }
                      }}
                    />

                    <AddButton
                      disabled={!selectedClosedDate}
                      size="sm"
                      onClick={() =>
                        onResourceChange({
                          ...resource,
                          closedDates: resource.closedDates
                            ? [...resource.closedDates, selectedClosedDate!]
                            : [selectedClosedDate!]
                        })
                      }
                    >
                      Add
                    </AddButton>
                  </div>
                </Form.Group>
                <ul>
                  {resource.closedDates &&
                    resource.closedDates.map((dt, i) => (
                      <li key={i}>
                        {formatDate(dt, 'dayDateMonthYear')}
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() =>
                            onResourceChange({
                              ...resource,
                              closedDates: resource.closedDates.filter((_, j) => j !== i)
                            })
                          }
                        >
                          Remove
                        </Button>
                      </li>
                    ))}
                </ul>
                <Form.Check
                  type="radio"
                  id="pharmacy-address"
                  label="Use pharmacy contact information"
                  checked={!useCustomAddress}
                  onChange={() => {
                    setUseCustomAddress(!useCustomAddress);
                    onResourceChange({
                      ...resource,
                      address: null,
                      contactEmail: null,
                      contactNumber: null
                    });
                  }}
                />
                <Form.Check
                  type="radio"
                  id="custom-address"
                  label="Use custom contact information"
                  checked={useCustomAddress}
                  onChange={() => setUseCustomAddress(!useCustomAddress)}
                />
                {useCustomAddress && (
                  <CustomAddressContainer>
                    <Form.Group>
                      <Form.Label>Contact number</Form.Label>
                      <Form.Control
                        type="text"
                        value={resource.contactNumber ?? undefined}
                        onChange={(e: any) =>
                          onResourceChange({
                            ...resource,
                            contactNumber: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Contact email</Form.Label>
                      <Form.Control
                        type="text"
                        value={resource.contactEmail ?? undefined}
                        onChange={(e: any) =>
                          onResourceChange({
                            ...resource,
                            contactEmail: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Address 1"
                        value={resource.address?.address1}
                        onChange={(e: any) =>
                          onResourceChange({
                            ...resource,
                            address: {
                              ...getAddress(resource.address),
                              address1: e.target.value
                            }
                          })
                        }
                      />
                      <Form.Control
                        type="text"
                        placeholder="Address 2"
                        value={resource.address?.address2}
                        onChange={(e: any) =>
                          onResourceChange({
                            ...resource,
                            address: {
                              ...getAddress(resource.address),
                              address2: e.target.value
                            }
                          })
                        }
                      />
                      <Form.Control
                        type="text"
                        placeholder="Address 3"
                        value={resource.address?.address3}
                        onChange={(e: any) =>
                          onResourceChange({
                            ...resource,
                            address: {
                              ...getAddress(resource.address),
                              address3: e.target.value
                            }
                          })
                        }
                      />
                      <Form.Control
                        type="text"
                        placeholder="City"
                        value={resource.address?.city}
                        onChange={(e: any) =>
                          onResourceChange({
                            ...resource,
                            address: {
                              ...getAddress(resource.address),
                              city: e.target.value
                            }
                          })
                        }
                      />
                      <Form.Control
                        type="text"
                        placeholder="County"
                        value={resource.address?.county}
                        onChange={(e: any) =>
                          onResourceChange({
                            ...resource,
                            address: {
                              ...getAddress(resource.address),
                              county: e.target.value
                            }
                          })
                        }
                      />
                      <Form.Control
                        type="text"
                        required
                        placeholder="Postcode"
                        value={resource.address?.postcode}
                        onChange={(e: any) =>
                          onResourceChange({
                            ...resource,
                            address: {
                              ...getAddress(resource.address),
                              postcode: e.target.value
                            }
                          })
                        }
                      />
                    </Form.Group>
                  </CustomAddressContainer>
                )}
              </StyledForm>
            </Tab>
            {hasRole(Role.Admin_Services_Edit) && (
              <Tab eventKey="opening" title="Room opening times">
                <StyledForm>
                  <Form.Check
                    type="radio"
                    id="pharmacy-hours"
                    label="Use pharmacy hours"
                    checked={!useCustomHours}
                    onChange={() => {
                      setUseCustomHours(!useCustomHours);
                      onResourceChange({
                        ...resource,
                        openingHours: null
                      });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    id="custom-hours"
                    label="Use custom hours"
                    checked={useCustomHours}
                    onChange={() => setUseCustomHours(!useCustomHours)}
                  />
                </StyledForm>
                {useCustomHours && (
                  <OpeningHours
                    openingHours={resource?.openingHours}
                    onChange={onResourceChange as any}
                  />
                )}
              </Tab>
            )}
          </Tabs>
        )}
        {error && (
          <CustomAlert
            variant="warning"
            errorMessage={error}
            showAlert={error ? true : false}
            setModalError={setModalError}
            dismissible={true}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={loading}>
          Cancel
        </Button>
        <Button active={!loading} onClick={onSave} disabled={loading}>
          {loading ? 'Loading...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

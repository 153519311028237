import { connect } from 'react-redux';
import BookingsTable from './BookingsTable';
import { GlobalState } from '../../reducers';
import { Booking } from '@avicennapharmacy/managemymeds-shared';
import { getSelectedPharmacy } from '../../selectors/user';
import { selectBooking, clearSelectedBookings } from '../../actions/bookings';
import {
  getResourceNames,
  getSelectedBookings,
  getBookings,
  getResources,
  getSelectedResource
} from '../../selectors/bookings';

const mapStateToProps = (state: GlobalState) => ({
  selectedPharmacy: getSelectedPharmacy(state),
  selectedResource: getSelectedResource(state),
  resources: getResources(state),
  resourceNames: getResourceNames(state),
  loadingBookings: state.bookings.loadingBookings,
  bookings: getBookings(state),
  selectedBookings: getSelectedBookings(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  selectBooking: (booking: Booking) => dispatch(selectBooking(booking.id)),
  clearSelectedBookings: () => dispatch(clearSelectedBookings())
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingsTable);

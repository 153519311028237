import { PharmacyTabType } from '.';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { AdHocOpeningTimes, Pharmacy } from '@avicennapharmacy/managemymeds-shared';
import DatePicker from 'react-datepicker';

export default ({ pharmacy, setPharmacy }: PharmacyTabType) => {
  return (
    <>
      <Row className="mt-5">
        <Col sm={4}>
          <h4 className="mb-4">Bank Holidays</h4>
        </Col>
        <Col sm={8}>
          <Button
            variant="primary"
            onClick={() => {
              setPharmacy({
                ...pharmacy,
                adHocOpeningTimes: [
                  {
                    adHocDt: new Date().toISOString(),
                    closed: true,
                    times: [
                      {
                        from: '09:00',
                        to: '17:00'
                      }
                    ]
                  },
                  ...pharmacy.adHocOpeningTimes
                ]
              });
            }}
          >
            Add new date
          </Button>
        </Col>
      </Row>
      {pharmacy.adHocOpeningTimes.map((opening: AdHocOpeningTimes, i: number) =>
        mapBankHoliday(opening, i, pharmacy, setPharmacy)
      )}
    </>
  );
};

const mapBankHoliday = (
  adHoc: AdHocOpeningTimes,
  i: number,
  pharmacy: Pharmacy,
  setPharmacy: (pharmacy: Pharmacy) => void
) => (
  <Form.Group key={i} as={Row} className={i % 2 === 0 ? 'bg-light py-2' : 'py-2'}>
    <Col sm={8}>
      <Row>
        <Form.Label column sm={1}>
          Date:
        </Form.Label>
        <Col sm={3}>
          <DatePicker
            required
            dateFormat="dd/MM/yyyy"
            selected={new Date(adHoc.adHocDt)}
            onChange={(date: Date) => {
              if (date) {
                const newArr = Array.from(pharmacy.adHocOpeningTimes);
                newArr[i] = {
                  ...pharmacy.adHocOpeningTimes[i],
                  adHocDt: date.toISOString()
                };
                setPharmacy({
                  ...pharmacy,
                  adHocOpeningTimes: newArr
                });
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Form.Label column sm={1}>
          Closed:
        </Form.Label>
        <Col sm={2}>
          <Form.Control
            type="checkbox"
            checked={adHoc.closed}
            style={{ width: 'auto' }}
            onChange={() => {
              const newArr = Array.from(pharmacy.adHocOpeningTimes);
              newArr[i] = {
                ...pharmacy.adHocOpeningTimes[i],
                closed: !pharmacy.adHocOpeningTimes[i].closed
              };
              setPharmacy({
                ...pharmacy,
                adHocOpeningTimes: newArr
              });
            }}
          />
        </Col>
      </Row>
      {!pharmacy.adHocOpeningTimes[i].closed && (
        <Row>
          {adHoc.times.map((t, timeIdx) => (
            <Col key={timeIdx} sm={3}>
              <Row>
                <Form.Label column sm={4}>
                  From:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="time"
                    min="0:00"
                    max={t.to}
                    value={t.from}
                    onChange={(e: any) => {
                      const newArr = Array.from(pharmacy.adHocOpeningTimes);
                      const newTimes = Array.from(newArr[i].times);
                      newTimes[timeIdx].from = e.target.value;
                      newArr[i] = {
                        ...pharmacy.adHocOpeningTimes[i],
                        times: newTimes
                      };
                      setPharmacy({
                        ...pharmacy,
                        adHocOpeningTimes: newArr
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Form.Label column sm={4}>
                  To:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="time"
                    min={t.from}
                    max="23:59"
                    value={t.to}
                    onChange={(e: any) => {
                      const newArr = Array.from(pharmacy.adHocOpeningTimes);
                      const newTimes = Array.from(newArr[i].times);
                      newTimes[timeIdx].to = e.target.value;
                      newArr[i] = {
                        ...pharmacy.adHocOpeningTimes[i],
                        times: newTimes
                      };
                      setPharmacy({
                        ...pharmacy,
                        adHocOpeningTimes: newArr
                      });
                    }}
                  />
                </Col>
              </Row>
              {timeIdx === 0 && (
                <Row className="mt-3 mb-2 ml-1">
                  {adHoc.times.length === 1 ? (
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        const newArr = Array.from(pharmacy.adHocOpeningTimes);
                        const newTimes = Array.from(newArr[i].times);
                        newTimes.push({
                          from: newTimes[0].to,
                          to: '20:00'
                        });
                        newArr[i] = {
                          ...pharmacy.adHocOpeningTimes[i],
                          times: newTimes
                        };
                        setPharmacy({
                          ...pharmacy,
                          adHocOpeningTimes: newArr
                        });
                      }}
                    >
                      Add second opening slot
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => {
                        const newArr = Array.from(pharmacy.adHocOpeningTimes);
                        const newTimes = Array.from(newArr[i].times);
                        newArr[i] = {
                          ...pharmacy.adHocOpeningTimes[i],
                          times: [newTimes[0]]
                        };
                        setPharmacy({
                          ...pharmacy,
                          adHocOpeningTimes: newArr
                        });
                      }}
                    >
                      Remove second opening slot
                    </Button>
                  )}
                </Row>
              )}
            </Col>
          ))}
        </Row>
      )}
      <Row className="mt-3 mb-2 ml-1">
        <Button
          size="sm"
          variant="danger"
          onClick={() => {
            const newArr = pharmacy.adHocOpeningTimes.filter((_: any, fIdx: number) => i !== fIdx);
            setPharmacy({
              ...pharmacy,
              adHocOpeningTimes: newArr
            });
          }}
        >
          Remove date
        </Button>
      </Row>
    </Col>
  </Form.Group>
);

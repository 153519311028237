import { getAdminConfigVar, Pharmacy, Owner } from '@avicennapharmacy/managemymeds-shared';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../Header.container';
import PageContainer from '../layout/PageContainer';
import { H2 } from '../typography/Headers';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ServicesTab from './ServicesTab';
import OpeningHoursTab from './OpeningHoursTab';
import BankHolidayHoursTab from './BankHolidayHoursTab';
import AppServiceCardsTab from './AppServiceCardsTab';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

type EditDetailsProps = {
  pharmacy: Pharmacy | null | undefined;
  setPharmacies: (pharmacies: any[]) => void;
  owner?: Owner | null;
};

type PageAlert = {
  variant:
    | 'success'
    | 'danger'
    | 'primary'
    | 'secondary'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | undefined;
  message: string;
};

export default ({ pharmacy: initialPharmacy, setPharmacies, owner }: EditDetailsProps) => {
  const [pharmacy, setPharmacy] = useState<Pharmacy | null>(null);
  const [validated, setValidated] = useState(false);
  const [hoursError, setHoursError] = useState(false);
  const [holsError, setHolsError] = useState(false);
  const [appServiceCardsError, setAppServiceCardsError] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [alert, setAlert] = useState<PageAlert | null>(null);

  useEffect(() => {
    if (initialPharmacy) {
      setPharmacy(initialPharmacy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!pharmacy || !initialPharmacy) {
    return <em>No pharmacy selected!</em>;
  }

  const handleSubmit = (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (
      form.checkValidity() === false ||
      form.querySelectorAll('.is-invalid ~ .invalid-feedback').length
    ) {
      if (form.querySelectorAll('#pharmacy-tabs-tabpane-hours .form-control:invalid').length) {
        setHoursError(true);
      }
      if (form.querySelectorAll('#pharmacy-tabs-tabpane-hols .form-control:invalid').length) {
        setHolsError(true);
      }
      if (
        form.querySelectorAll('#pharmacy-tabs-tabpane-app-service-cards .form-control:invalid')
          .length
      ) {
        setAppServiceCardsError(true);
      }

      setShowSaveModal(false);
    } else {
      setHoursError(false);
      setHolsError(false);
      setAppServiceCardsError(false);
      setShowSaveModal(true);
    }

    setValidated(true);
  };

  const updatePharmacy = async () => {
    setModalLoading(true);
    try {
      const url = getAdminConfigVar('updatePharmaciesEndpoint');
      await axios.put(url, pharmacy);
      const { pharmacies } = (await axios.get(getAdminConfigVar('userPharmaciesEndpoint'))).data;
      setPharmacies(pharmacies);
      setShowSaveModal(false);
      setModalLoading(false);
      setAlert({
        variant: 'success',
        message: 'Pharmacy updated successfully'
      });
    } catch (e) {
      setModalLoading(false);
      setShowSaveModal(false);
      setAlert({
        variant: 'danger',
        message: 'There was an error updating the pharmacy'
      });
    }
  };

  const hoursTitle = (
    <>
      Opening Hours{' '}
      {hoursError && (
        <Badge pill variant="danger">
          !
        </Badge>
      )}
    </>
  );

  const holsTitle = (
    <>
      Bank Holidays{' '}
      {holsError && (
        <Badge pill variant="danger">
          !
        </Badge>
      )}
    </>
  );

  const appServiceCardsTitle = (
    <>
      App Service Cards{' '}
      {appServiceCardsError && (
        <Badge pill variant="danger">
          !
        </Badge>
      )}
    </>
  );

  return (
    <PageContainer>
      <Header />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col sm={6}>
            <H2>{pharmacy.businessName}</H2>
            {alert && <Alert variant={alert.variant}>{alert.message}</Alert>}
          </Col>
          <Col sm={6}>
            <p className="text-right">
              <Button
                variant="secondary"
                disabled={initialPharmacy === pharmacy}
                onClick={() => {
                  setPharmacy(initialPharmacy);
                  setValidated(false);
                  setHoursError(false);
                  setHolsError(false);
                }}
              >
                <FontAwesomeIcon icon="history" /> Reset
              </Button>{' '}
              <Button variant="primary" disabled={initialPharmacy === pharmacy} type="submit">
                <FontAwesomeIcon icon="save" /> Save
              </Button>
            </p>
          </Col>
        </Row>
        <Tabs defaultActiveKey="services" id="pharmacy-tabs" className="my-3">
          <Tab eventKey="services" title="Services" className="my-3 mx-2">
            <ServicesTab pharmacy={pharmacy} setPharmacy={setPharmacy} />
          </Tab>
          <Tab eventKey="hours" title={hoursTitle} className="my-3 mx-2">
            <OpeningHoursTab pharmacy={pharmacy} setPharmacy={setPharmacy} />
          </Tab>
          <Tab eventKey="hols" title={holsTitle} className="my-3 mx-2">
            <BankHolidayHoursTab pharmacy={pharmacy} setPharmacy={setPharmacy} />
          </Tab>
          {owner?.active && (
            <Tab eventKey="app-service-cards" title={appServiceCardsTitle} className="my-3 mx-2">
              <AppServiceCardsTab
                pharmacy={pharmacy}
                setPharmacy={(updatedFields: Partial<Pharmacy>) =>
                  setPharmacy({
                    ...pharmacy,
                    ...updatedFields
                  })
                }
                validated={validated}
                setValidated={setValidated}
              />
            </Tab>
          )}
        </Tabs>
        <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Save Changes</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to save changes?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSaveModal(false)}>
              Cancel
            </Button>
            {modalLoading ? (
              <Button variant="primary" disabled>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="sr-only">Saving...</span>
              </Button>
            ) : (
              <Button variant="primary" onClick={updatePharmacy}>
                Save
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </Form>
    </PageContainer>
  );
};

export type PharmacyTabType = {
  pharmacy: Pharmacy;
  setPharmacy: (pharmacy: Pharmacy) => void;
};

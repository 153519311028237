import React, { useEffect, useState } from 'react';
import { Owner, Pharmacy, Group } from '@avicennapharmacy/managemymeds-shared';

type GeneralTabProps = {
  owner: Owner | null;
  updateOwner: (owner: Owner | null) => void;
  pharmacy: Pharmacy | null | undefined;
  getUserPharmacies: () => { pharmacies: Pharmacy[]; groups: Group[] };
};

export default ({ owner }: GeneralTabProps) => {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    setIsEnabled(owner ? owner.active : false);
  }, [owner]);

  return (
    <>
      <h4 className="mb-4 mt-5">General configuration</h4>
      {isEnabled && (
        <>
          <p>
            Service booking is <strong>Enabled</strong>.<br />
            <br /> Use the <strong>services</strong> tab to define which services are available to
            your users and when they can be booked.
          </p>
        </>
      )}
      {!isEnabled && owner && (
        <p>
          Service booking is <strong>Not Enabled</strong>.<br />
          <br />
          Please contact the administrator to turn on this functionality. This will allow users of
          the app to book services at your pharmacy.
        </p>
      )}
      {!isEnabled && !owner && <p>Service booking unavailable, please contact the administrator</p>}
    </>
  );
};

import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {
  capitalize,
  OpeningHours,
  DayOfWeek,
  Resource,
  Service
} from '@avicennapharmacy/managemymeds-shared';

type OpeningHoursProps = {
  openingHours: OpeningHours[] | null;
  onChange: React.Dispatch<React.SetStateAction<Resource | Service>>;
};
export default ({ openingHours, onChange }: OpeningHoursProps) => {
  const formattedOpeningHours: { [key in DayOfWeek]: OpeningHours[] } = {
    [DayOfWeek.Monday]: [],
    [DayOfWeek.Tuesday]: [],
    [DayOfWeek.Wednesday]: [],
    [DayOfWeek.Thursday]: [],
    [DayOfWeek.Friday]: [],
    [DayOfWeek.Saturday]: [],
    [DayOfWeek.Sunday]: []
  };

  if (!openingHours) {
    openingHours = [];
  }

  openingHours.forEach(o => {
    //https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
    formattedOpeningHours[o.dayOfWeek]?.push(o);
  });

  const updateTime = (
    day: DayOfWeek,
    intervalIndex: number,
    point: 'openingTime' | 'closingTime',
    newTime: string
  ) => {
    onChange(r => ({
      ...r,
      openingHours: Object.values({
        ...formattedOpeningHours,
        [day]: formattedOpeningHours[day].map((x, i) =>
          i === intervalIndex
            ? {
                ...x,
                [point]: newTime
              }
            : x
        )
      }).flat() as OpeningHours[]
    }));
  };

  return (
    <>
      <h4 className="mb-4 mt-5">Opening Hours</h4>
      {Object.entries(formattedOpeningHours).map(([_day, times], i) => {
        const day = (_day as unknown) as DayOfWeek;
        if (!times.length) {
          return (
            <Form.Group key={day} as={Row} className={i % 2 === 0 ? 'bg-light py-2' : 'py-2'}>
              <Form.Label column>{capitalize(DayOfWeek[day])}</Form.Label>
              <Col>
                <Row>
                  <Form.Label column>Closed:</Form.Label>
                  <Col>
                    <Form.Control
                      type="checkbox"
                      style={{ width: 'auto' }}
                      checked={times.length === 0}
                      onChange={() =>
                        onChange(r => ({
                          ...r,
                          openingHours: Object.values({
                            ...formattedOpeningHours,
                            [day]: {
                              dayOfWeek: day,
                              openingTime: '09:00:00',
                              closingTime: '17:00:00'
                            }
                          }).flat()
                        }))
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          );
        }

        return (
          <Form.Group key={day} as={Row} className={i % 2 === 0 ? 'bg-light py-2' : 'py-2'}>
            <Form.Label column>{capitalize(DayOfWeek[day])}</Form.Label>
            <Col sm={8}>
              <Row>
                <Form.Label column>Closed:</Form.Label>
                <Col>
                  <Form.Control
                    type="checkbox"
                    style={{ width: 'auto' }}
                    checked={times.length === 0}
                    onChange={() =>
                      onChange(r => ({
                        ...r,
                        openingHours: Object.values({
                          ...formattedOpeningHours,
                          [day]: []
                        }).flat()
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row>
                {times.map((t, i) => (
                  <Col key={i}>
                    <Row>
                      <Form.Label column>From:</Form.Label>
                      <Col>
                        <Form.Control
                          type="time"
                          min="0:00"
                          max={t.closingTime}
                          value={t.openingTime}
                          onChange={(e: any) => updateTime(day, i, 'openingTime', e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Form.Label column>To:</Form.Label>
                      <Col>
                        <Form.Control
                          type="time"
                          min={t.openingTime}
                          max="23:59"
                          value={t.closingTime}
                          onChange={(e: any) => updateTime(day, i, 'closingTime', e.target.value)}
                        />
                      </Col>
                    </Row>
                    {i === 0 && (
                      <Row className="mt-3 mb-2 ml-1">
                        {times.length === 1 ? (
                          <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() =>
                              onChange(r => {
                                const newState = {
                                  ...r,
                                  openingHours: Object.values({
                                    ...formattedOpeningHours,
                                    [day]: [
                                      ...formattedOpeningHours[day],
                                      {
                                        openingTime: formattedOpeningHours[day][0].closingTime,
                                        closingTime: '20:00:00',
                                        dayOfWeek: day
                                      }
                                    ]
                                  }).flat()
                                };
                                return newState;
                              })
                            }
                          >
                            Add second opening slot
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            variant="outline-danger"
                            onClick={() =>
                              onChange(r => ({
                                ...r,
                                openingHours: Object.values({
                                  ...formattedOpeningHours,
                                  [day]: formattedOpeningHours[day].filter((p, i) => i < 1)
                                }).flat()
                              }))
                            }
                          >
                            Remove second opening slot
                          </Button>
                        )}
                        <Button
                          size="sm"
                          className="ml-4"
                          variant="outline-primary"
                          onClick={() =>
                            onChange(r => ({
                              ...r,
                              openingHours: Object.values(
                                copyToOtherDays(day, formattedOpeningHours)
                              ).flat()
                            }))
                          }
                        >
                          Copy to other days
                        </Button>
                      </Row>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
          </Form.Group>
        );
      })}
    </>
  );
};

const copyToOtherDays = (
  day: DayOfWeek,
  formattedOpeningHours: { [key in DayOfWeek]: OpeningHours[] }
): { [key in DayOfWeek]: OpeningHours[] } => {
  const result: any = {};
  for (let key in DayOfWeek) {
    if (isNaN(Number(key))) {
      continue;
    }
    result[key] = formattedOpeningHours[day].map(x => ({ ...x, dayOfWeek: key }));
  }
  return result;
};

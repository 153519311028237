import { connect } from 'react-redux';
import { GlobalState } from '../../reducers';
import {
  loadOrders,
  loadCollectedOrders,
  loadRejectedOrders,
  setLoadingOrders,
  loadErdSurveys
} from '../../actions/orders';
import {
  getOrderCounts,
  getFilteredRejectedOrders,
  getFilteredCollectedOrders,
  getLoadingOrders,
  getCurrentOrderPage,
  getTotalOrderPages
} from '../../selectors/orders';
import { getSelectedPharmacy } from '../../selectors/user';
import Orders from './OrderTabs';
import { defaultOrdersToLoad } from '../../config/constants';
import { isIM1 } from '../../utils';

const mapStateToProps = (state: GlobalState) => ({
  orderCounts: getOrderCounts(state),
  rejectedOrders: getFilteredRejectedOrders(state),
  collectedOrders: getFilteredCollectedOrders(state),
  loadingOrders: getLoadingOrders(state),
  pharmacy: getSelectedPharmacy(state),
  currentPage: getCurrentOrderPage(state),
  totalPages: getTotalOrderPages(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  loadOrders: async (callback?: Function) => {
    dispatch(setLoadingOrders());
    dispatch(await loadOrders(defaultOrdersToLoad(isIM1())));
    callback && callback();
  },
  loadRejectedOrders: async () => {
    dispatch(setLoadingOrders());
    dispatch(await loadRejectedOrders());
  },
  loadCollectedOrders: async (offset?: number, limit?: number, orderByDesc?: boolean) => {
    dispatch(setLoadingOrders());
    dispatch(await loadCollectedOrders(offset, limit, orderByDesc));
  },
  loadErdSurveys: async () => {
    if (isIM1()) {
      dispatch(await loadErdSurveys());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);

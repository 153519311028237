import { connect } from 'react-redux';
import { GlobalState } from '../../reducers';
import { getModalErdSurvey, getModalOrder, getRelatedOrders } from '../../selectors/orders';
import { setModalOrder, loadOrders, loadErdSurveys } from '../../actions/orders';
import OrderInfo from './OrderInfo';
import { defaultOrdersToLoad } from '../../config/constants';
import { isIM1 } from '../../utils';

const mapStateToProps = (state: GlobalState) => ({
  order: getModalOrder(state),
  erdSurvey: getModalErdSurvey(state),
  relatedOrders: getRelatedOrders(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setModalOrder: async (orderId: string | null) => {
    const im1 = isIM1();
    dispatch(await loadOrders(defaultOrdersToLoad(im1)));
    if (im1) {
      dispatch(await loadErdSurveys());
    }
    dispatch(setModalOrder(orderId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfo);

import React from 'react';
import SignIn from './SignIn.container';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import { Pharmacy, PharmacyGroup, Owner } from '@avicennapharmacy/managemymeds-shared';
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';

const StyledDiv = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 15px;
  margin: auto;
  margin-top: 8vh;
`;

type PrivateRouteProps = {
  username: string | null;
  pharmacies: Pharmacy[];
  selectedPharmacy?: Pharmacy | null;
  setSelectedPharmacy: (id: string) => void;
  groups: PharmacyGroup[];
  selectedGroup?: PharmacyGroup | null;
  setSelectedGroup: (id: string) => void;
  owner?: Owner | null;
  pharmacy: Pharmacy | null | undefined;
  fetchedRoles: boolean;
} & RouteProps;

const PrivateRoute = ({
  username,
  pharmacies,
  selectedPharmacy,
  setSelectedPharmacy,
  groups,
  selectedGroup,
  setSelectedGroup,
  owner,
  pharmacy,
  fetchedRoles,
  ...rest
}: PrivateRouteProps) => {
  const history = useHistory();
  const location = useLocation();

  if (!username || !fetchedRoles) {
    return <SignIn />;
  }

  if (
    (!owner || !owner.active) &&
    (selectedPharmacy || selectedGroup) &&
    location.pathname === '/Diary'
  ) {
    history.push('/');
  }

  if (!selectedPharmacy && !selectedGroup) {
    return (
      <StyledDiv>
        <ListGroup>
          {groups.length > 0 && (
            <>
              <p>Access group portal:</p>
              {groups.map(group => (
                <ListGroup.Item
                  key={group.id}
                  action
                  onClick={() => {
                    setSelectedGroup(group.id);
                    history.push('/');
                  }}
                >
                  <p>
                    <strong>{group.name}</strong>
                  </p>
                </ListGroup.Item>
              ))}
            </>
          )}
          {pharmacies.length > 0 && (
            <>
              <p className="mt-5">Choose pharmacy:</p>
              {pharmacies.map(pharmacy => (
                <ListGroup.Item
                  key={pharmacy.id}
                  action
                  onClick={() => {
                    setSelectedPharmacy(pharmacy.id);
                    history.push('/');
                  }}
                >
                  <p>
                    <strong>{pharmacy.businessName}</strong>
                  </p>
                  <p>
                    {pharmacy.address1}, {pharmacy.postcode}
                  </p>
                </ListGroup.Item>
              ))}
            </>
          )}
        </ListGroup>
      </StyledDiv>
    );
  }

  return <Route {...rest} />;
};

export default PrivateRoute;

import { connect } from 'react-redux';
import { setSelectedPharmacy } from '../../../actions/user';
import Pharmacies from './';
import { GlobalState } from '../../../reducers';
import { getServiceNamesLookupState, getServiceNamesState } from '../../../selectors/services';
import { getServiceNames } from '../../../actions/services';

const mapStateToProps = (state: GlobalState) => ({
  serviceNamesLookup: getServiceNamesLookupState(state),
  serviceNames: getServiceNamesState(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedPharmacy: (id: string) => dispatch(setSelectedPharmacy(id)),
  getServiceNames: async () => dispatch(await getServiceNames())
});

export default connect(mapStateToProps, mapDispatchToProps)(Pharmacies);

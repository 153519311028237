import {
  getAdminConfigVar,
  SubService,
  Service,
  ServiceName,
  ServiceNamesLookup
} from '@avicennapharmacy/managemymeds-shared';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import Axios, { AxiosResponse } from 'axios';
import {
  availableAppointmentTypes,
  EXTERNAL_DESCRIPTION_MAX_LENGTH,
  bookingFormTypes
} from '../../../config/constants';
import styled from 'styled-components';
import Select from 'react-select';
import SelectFeedback from '../../serviceEdit/SelectFeedback';
import OpeningHours from '../../serviceEdit/OpeningHours';
import { BulkEditModalProps } from './BulkEditModal';
import ServiceSubType from '../../serviceEdit/ServiceSubType';
import { endOfDay, startOfDay } from 'date-fns';

const StyledForm = styled(Form)`
  padding-top: 20px;
  margin-left: 10px;
`;

type BulkServiceInsertProps = {
  pharmacyIds: string[];
  setShow: (show: boolean) => void;
  setShowSuccess?: (show: boolean) => void;
  setSuccessModalTitle?: (title: string) => void;
  setSuccessModalBody?: (body: string) => void;
  selectedGroupId?: string | null;
  serviceNamesLookup: ServiceNamesLookup | null;
  serviceNames: ServiceName[] | null;
};

export default ({
  pharmacyIds,
  setShow,
  setShowSuccess,
  setSuccessModalTitle,
  setSuccessModalBody,
  selectedGroupId,
  serviceNamesLookup,
  serviceNames
}: BulkServiceInsertProps) => {
  const [service, setService] = useState<Service | null>({
    id: '',
    rowKey: '',
    groupId: '',
    ownerId: '',
    resourceId: '',
    serviceNameId: '',
    name: '',
    externalName: '',
    description: '',
    emailDescription: null,
    externalDescription: null,
    active: true,
    startDt: null,
    endDt: null,
    serviceBookingConfiguration: {
      bookingTypes: []
    },
    slotDuration: 1,
    daysBookableInAdvance: null,
    openingHours: null,
    pricingInformation: '',
    category: null,
    serviceType: 'Online',
    isMultipleSlot: false,
    patientPerSlot: 0,
    hasServiceSubTypes: false,
    subServices: []
  });
  const [validated, setValidated] = useState(false);
  const [useCustomHours, setUseCustomHours] = useState(false);
  const [, setError] = useState(false);
  const [bulkServiceInsertAction, setBulkServiceInsertAction] = useState(false);
  const [startDtValidation, setStartDtValidation] = useState(false);
  const [endDtValidation, setEndDtValidation] = useState(false);

  const selectedAppointmentType = availableAppointmentTypes.filter(
    a => service?.serviceBookingConfiguration.bookingTypes.includes(a.value) || false
  );

  const isExternalDescriptionInvalid =
    Number(service?.externalDescription?.length) > EXTERNAL_DESCRIPTION_MAX_LENGTH;

  useEffect(() => {
    if (service === null) {
      setService({
        id: '',
        rowKey: '',
        groupId: '',
        ownerId: '',
        resourceId: '',
        serviceNameId: '',
        name: '',
        externalName: '',
        description: '',
        emailDescription: null,
        externalDescription: null,
        active: true,
        startDt: null,
        endDt: null,
        serviceBookingConfiguration: {
          bookingTypes: []
        },
        slotDuration: 1,
        daysBookableInAdvance: null,
        openingHours: null,
        pricingInformation: '',
        category: null,
        serviceType: 'Online',
        isMultipleSlot: false,
        patientPerSlot: 0,
        hasServiceSubTypes: false,
        subServices: []
      });
    }
  }, [service]);

  const selectedBookingFormType = bookingFormTypes.filter(
    a => a.value === service?.serviceType || false
  );

  return (
    <>
      <StyledForm>
        {serviceNames && (
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Select
              options={serviceNames.map(r => ({
                value: r.serviceNameId,
                label: r.name
              }))}
              value={
                service?.serviceNameId.length && serviceNamesLookup
                  ? {
                      value: service.serviceNameId,
                      label: serviceNamesLookup[service.serviceNameId].name[0]
                    }
                  : {
                      value: '',
                      label: ''
                    }
              }
              onChange={(e: any) => {
                if (service !== null) {
                  setService({
                    ...service,
                    serviceNameId: e.value,
                    name: e.label,
                    externalName: serviceNamesLookup ? serviceNamesLookup[e.value].name[1] : '',
                    subServices: null,
                    hasServiceSubTypes: false
                  });
                }
              }}
            />
            <SelectFeedback
              isInvalid={!service?.serviceNameId && validated}
              feedback="Please select a service name"
            />
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>
            Sub Service Type selection
            <br />
            <small>
              Tick if the service should have sub service types for selection when booking{' '}
            </small>
          </Form.Label>
          <Form.Check
            checked={Boolean(service?.hasServiceSubTypes)}
            disabled={
              service?.serviceNameId && serviceNamesLookup
                ? serviceNamesLookup[service?.serviceNameId].subs == null
                : true
            }
            onChange={() => {
              if (service !== null) {
                setService({
                  ...service,
                  hasServiceSubTypes: !service.hasServiceSubTypes,
                  subServices: !service.hasServiceSubTypes
                    ? [{ serviceNameId: '', service: '', startDt: '', endDt: '' }]
                    : []
                });
              }
            }}
          />
        </Form.Group>
        {service?.hasServiceSubTypes && !!service.subServices && (
          <>
            <Form.Group>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={8}>
                      <Form.Label>Sub types for selection</Form.Label>
                    </Col>
                    <Col sm={2}>
                      <Form.Label>Date available from</Form.Label>
                    </Col>
                  </Row>
                  {service.subServices?.map((subService: SubService, i: number) => {
                    return (
                      <ServiceSubType
                        validated={validated}
                        service={service}
                        serviceSubType={subService}
                        onServiceChange={setService}
                        serviceNamesLookup={serviceNamesLookup}
                        i={i}
                        key={i}
                      />
                    );
                  })}
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 4 }}>
                  <Button
                    disabled={
                      service.subServices?.length
                        ? service.subServices[service.subServices?.length - 1].service.length < 1
                        : false
                    }
                    onClick={() => {
                      setService({
                        ...service,
                        subServices: service.subServices?.length
                          ? [
                              ...service.subServices,
                              { serviceNameId: '', service: '', startDt: '', endDt: '' }
                            ]
                          : []
                      });
                      setValidated(false);
                    }}
                  >
                    + Add another sub type
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </>
        )}
        <Form.Group>
          <Form.Label>Available appointment types</Form.Label>
          <Select
            options={availableAppointmentTypes}
            value={selectedAppointmentType}
            onChange={(selected: any) => {
              if (!selected) {
                selected = [];
              }
              if (service !== null) {
                setService({
                  ...service,
                  serviceBookingConfiguration: {
                    bookingTypes: [selected.value]
                  }
                });
              }
            }}
          />
          <SelectFeedback
            isInvalid={!selectedAppointmentType.length && validated}
            feedback="Please select an appointment type"
          />
        </Form.Group>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>
                Number of slots
                <br />
                <small>
                  <>The number of minutes for each slot</>
                </small>
              </Form.Label>
              <Form.Control
                type="number"
                isInvalid={Number(!service?.slotDuration) > 0 && validated}
                value={String(service?.slotDuration)}
                onChange={(e: any) => {
                  if (service !== null) {
                    setService({
                      ...service,
                      slotDuration: Number(e.target.value)
                    });
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">
                Slot duration should be higher than 0.
              </Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label>
                Days bookable in advance
                <br />
                <small>
                  If not supplied, patients will be able to book appointments on the same day
                </small>
              </Form.Label>
              <Form.Control
                type="number"
                value={String(service?.daysBookableInAdvance)}
                onChange={(e: any) => {
                  if (service !== null) {
                    setService({
                      ...service,
                      daysBookableInAdvance: Number(e.target.value)
                    });
                  }
                }}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col sm={6}>
              <Form.Label>Date available from</Form.Label>
              <DatePicker
                wrapperClassName="container leftpaddingless"
                className="form-control"
                dateFormat="dd/MM/yyyy"
                selected={service?.startDt ? new Date(service.startDt) : undefined}
                onChange={(date: Date) => {
                  if (date && service !== null) {
                    setStartDtValidation(true);
                    setService({
                      ...service,
                      startDt: date.toISOString()
                    });
                  } else if (service !== null) {
                    setStartDtValidation(false);
                    setService({
                      ...service,
                      startDt: startOfDay(date).toISOString()
                    });
                  }
                }}
              />
              {!startDtValidation && service?.startDt === null && validated && (
                <Form.Label style={{ fontSize: '80%', color: '#d44b40' }}>
                  Please enter a start date.
                </Form.Label>
              )}
            </Col>
            <Col sm={6}>
              <Form.Label>Date available until</Form.Label>
              <DatePicker
                wrapperClassName="container leftpaddingless"
                className="form-control"
                dateFormat="dd/MM/yyyy"
                selected={service?.endDt ? new Date(service.endDt) : undefined}
                onChange={(date: Date) => {
                  if (date && service !== null) {
                    setEndDtValidation(true);
                    setService({
                      ...service,
                      endDt: endOfDay(date).toISOString()
                    });
                  } else {
                    setEndDtValidation(false);
                  }
                }}
              />
              {!endDtValidation && service?.endDt === null && validated && (
                <Form.Label style={{ fontSize: '80%', color: '#d44b40' }}>
                  Please enter an end date.
                </Form.Label>
              )}
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col sm={8}>
              <Form.Label>
                Active
                <br />
                <small>
                  Tick if the service should be live (respects available dates set above)
                </small>
              </Form.Label>
            </Col>
            <Col sm={4}>
              <br />
              <Form.Check
                checked={service?.active}
                onChange={() => {
                  if (service !== null) {
                    setService({
                      ...service,
                      active: !service.active
                    });
                  }
                }}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col sm={8}>
              <Form.Label>
                AviClinic
                <br />
                <small>Tick if the service should be available in the AviClinic category</small>
              </Form.Label>
            </Col>
            <Col sm={4}>
              <br />
              <Form.Check
                checked={Boolean(service?.category)}
                onChange={() => {
                  if (service !== null) {
                    setService({
                      ...service,
                      category: service.category ? null : 'AviClinic'
                    });
                  }
                }}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Service Type</Form.Label>
          <Select
            options={bookingFormTypes}
            value={selectedBookingFormType}
            onChange={(selected: any) => {
              if (service !== null) {
                setService({
                  ...service,
                  serviceType: selected.value
                });
              }
            }}
          />
        </Form.Group>
        <h4 className="mt-5">External information</h4>
        <p>The details below will be displayed to the patients</p>
        <Form.Group>
          <Form.Label>External name</Form.Label>
          <Form.Control
            type="text"
            isInvalid={!service?.externalName?.trim() && validated}
            value={service?.externalName ?? undefined}
            onChange={(e: any) => {
              if (service !== null) {
                setService({
                  ...service,
                  externalName: e.target.value
                });
              }
            }}
            disabled={true}
          />
          <Form.Control.Feedback type="invalid">
            Please enter an external name for your service
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            External description
            <br />
            <small>
              Provide an overview of the service and how the appointment will take place, any
              additional requirements etc.
            </small>
          </Form.Label>
          <Form.Control
            isInvalid={isExternalDescriptionInvalid}
            as="textarea"
            rows={5}
            value={service?.externalDescription ?? undefined}
            onChange={(e: any) => {
              if (service !== null) {
                setService({
                  ...service,
                  externalDescription: e.target.value
                });
              }
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please provide an external description for your customers that is less than{' '}
            {EXTERNAL_DESCRIPTION_MAX_LENGTH} characters.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Pricing information
            <br />
            <small>Free text for description of any costs the patient may have to incur</small>
          </Form.Label>
          <Form.Control
            type="text"
            value={service?.pricingInformation ?? undefined}
            onChange={(e: any) => {
              if (service !== null) {
                setService({
                  ...service,
                  pricingInformation: e.target.value
                });
              }
            }}
          />
        </Form.Group>
        <Form.Check
          type="radio"
          id="resource-hours"
          label="Use room hours"
          checked={!useCustomHours}
          onChange={() => {
            if (service !== null) {
              setUseCustomHours(!useCustomHours);
              setService({
                ...service,
                openingHours: null
              });
            }
          }}
        />
        <Form.Check
          type="radio"
          id="custom-hours"
          label="Use custom hours"
          checked={useCustomHours}
          onChange={() => setUseCustomHours(!useCustomHours)}
        />
        {useCustomHours && service !== null && (
          <OpeningHours openingHours={service?.openingHours} onChange={setService as any} />
        )}
      </StyledForm>
      <Row className="col-md-12 mt-3">
        <Button
          autoFocus
          type="button"
          data-testid="bulk-service-insert-save"
          className="mt-2"
          onClick={() => setBulkServiceInsertAction(true)}
        >
          Save
        </Button>
        {bulkServiceInsertAction && (
          <>
            <Row className="ml-3">
              <label className="mt-3" data-testid="confirmation-label">
                Are you sure?
              </label>
              <Button
                className="ml-3 mr-2"
                variant="danger"
                onClick={async () => {
                  setValidated(true);
                  if (
                    (!startDtValidation && service?.startDt === null) ||
                    (!endDtValidation && service?.endDt === null) ||
                    !service?.name.trim() ||
                    !selectedAppointmentType.length ||
                    (service.hasServiceSubTypes &&
                      !service.subServices?.every(st => st.service.trim().length)) ||
                    !service?.externalName?.trim()
                  ) {
                    return;
                  }
                  try {
                    await Axios.post<any, AxiosResponse<Service[]>>(
                      getAdminConfigVar('bulkServiceBookingCreateServiceEndpoint'),
                      {
                        groupId: selectedGroupId,
                        pharmacies: pharmacyIds,
                        name: service.name,
                        externalName: service.externalName,
                        description: service.description,
                        emailDescription: service.emailDescription,
                        pricingInformation: service.pricingInformation,
                        startDt: service.startDt,
                        endDt: service.endDt,
                        serviceBookingConfiguration: service.serviceBookingConfiguration,
                        slotDuration: service.slotDuration,
                        daysBookableInAdvance: service.daysBookableInAdvance,
                        openingHours: service.openingHours,
                        category: service.category,
                        serviceType: service.serviceType,
                        hasServiceSubTypes: service.hasServiceSubTypes,
                        subServices: service.subServices,
                        Active: service.active,
                        externalDescription: service.externalDescription,
                        serviceNameId: service?.serviceNameId
                      }
                    );
                  } catch (err) {
                    setError(true);
                  } finally {
                    setError(false);
                    setShow(false);

                    if (setSuccessModalTitle !== undefined) {
                      setSuccessModalTitle('Bulk Service Request Update');
                    }

                    if (setSuccessModalBody !== undefined) {
                      setSuccessModalBody(
                        'The system started to create services. It will be completed shortly.'
                      );
                    }

                    if (setShowSuccess !== undefined) {
                      setShowSuccess(true);
                    }
                  }
                }}
              >
                Yes
              </Button>{' '}
              <Button variant="secondary" onClick={() => setBulkServiceInsertAction(false)}>
                No
              </Button>
            </Row>
          </>
        )}
      </Row>
    </>
  );
};

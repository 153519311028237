import axios from 'axios';
import {
  PENDING,
  NEW,
  RAISED,
  RAISED_IM1,
  IM1_WITH_GP_TOO_LONG,
  NEW_REPEAT_DISPENSING,
  CANCELLED_IM1,
  READY_FOR_COLLECTION,
  AMEND,
  GPAPPROVED,
  getAdminConfigVar
} from '@avicennapharmacy/managemymeds-shared';
import { appInsights, store } from '../index';
import { loadOrders, loadErdSurveys } from '../actions/orders';
import { defaultOrdersToLoad } from '../config/constants';

const getEndpoints = () => ({
  [NEW as string]: getAdminConfigVar('raisedEndpoint'),
  [NEW_REPEAT_DISPENSING as string]: getAdminConfigVar('readyForCollectionEndpoint'),
  [RAISED as string]: getAdminConfigVar('readyForCollectionEndpoint'),
  [RAISED_IM1 as string]: getAdminConfigVar('readyForCollectionEndpoint'),
  [IM1_WITH_GP_TOO_LONG as string]: getAdminConfigVar('readyForCollectionEndpoint'),
  [READY_FOR_COLLECTION as string]: getAdminConfigVar('collectedEndpoint'),
  [AMEND as string]: getAdminConfigVar('amendEndpoint'),
  [GPAPPROVED as string]: getAdminConfigVar('readyForCollectionEndpoint')
});

export const getNextStatusText = (status: string): string => {
  switch (status) {
    case NEW:
      return 'Requested';
    case NEW_REPEAT_DISPENSING:
    case RAISED:
    case RAISED_IM1:
    case IM1_WITH_GP_TOO_LONG:
      return 'Ready to collect';
    case READY_FOR_COLLECTION:
      return 'Mark collected';
    case GPAPPROVED:
      return 'Ready to collect';
    default:
      return '';
  }
};

export const getStatusUpdateText = (statusUpdateText: string) => {
  switch (statusUpdateText) {
    case PENDING:
      return 'Order Received';
    case NEW:
      return 'New';
    case NEW_REPEAT_DISPENSING:
      return 'New (Repeat Dispensing)';
    case RAISED:
      return 'Requested Order';
    case RAISED_IM1:
      return 'Requested Order Automatically';
    case IM1_WITH_GP_TOO_LONG:
      return 'Order with GP longer than expected';
    case READY_FOR_COLLECTION:
      return 'Ready to Collect';
    case CANCELLED_IM1:
      return 'Cancelled by user';
    default:
      return statusUpdateText;
  }
};

export const isIM1 = () => getAdminConfigVar('featureIM1')?.toLowerCase() === 'true';

export const updateOrderStatus = async ({
  ids,
  orderStatus
}: {
  ids: string[];
  orderStatus: string;
}) => {
  try {
    const im1 = isIM1();
    await (await axios.post(getEndpoints()[orderStatus], { orderIds: ids })).data;
    store.dispatch(await loadOrders(defaultOrdersToLoad(im1)));
    if (im1) {
      store.dispatch(await loadErdSurveys());
    }
    appInsights.trackEvent({ name: 'Updated orders', properties: { ids, orderStatus } });
  } catch (e) {
    appInsights.trackException({
      exception: new Error(
        `Failed to update orders ${ids.join(', ')} via ${getEndpoints()[orderStatus]} - ${e}`
      )
    });
  }
};

export const setOrdersPrinted = async (ids: string[]) => {
  try {
    const im1 = isIM1();
    await (await axios.post(getAdminConfigVar('printEndpoint'), { orderIds: ids })).data;
    store.dispatch(await loadOrders(defaultOrdersToLoad(im1)));
    if (im1) {
      store.dispatch(await loadErdSurveys());
    }
    appInsights.trackEvent({ name: 'Marked orders printed', properties: { ids } });
  } catch (e) {
    appInsights.trackException({
      exception: new Error(`Failed to mark orders printed ${ids.join(', ')} - ${e}`)
    });
  }
};

export type RejectionType = {
  orderId: string;
  itemId?: string | null;
  reason: string;
  text: string;
};

export const rejectEntireOrders = async (rejections: RejectionType[]) => {
  const rejectionsBody = {
    rejectOrders: rejections.map(r => ({
      orderId: r.orderId,
      rejectEntireOrder: true,
      entireOrderRejectReason: r.reason,
      entireOrderRejectText: r.text
    }))
  };

  try {
    const im1 = isIM1();
    await (await axios.post(getAdminConfigVar('rejectionEndpoint'), rejectionsBody)).data;
    store.dispatch(await loadOrders(defaultOrdersToLoad(im1)));
    if (im1) {
      store.dispatch(await loadErdSurveys());
    }
    appInsights.trackEvent({
      name: 'Rejected entire orders',
      properties: { ids: rejections.map(r => r.orderId) }
    });
  } catch (e) {
    appInsights.trackException({
      exception: new Error(
        `Failed to update orders via ${getAdminConfigVar('rejectionEndpoint')} - ${e}`
      )
    });
  }
};

export const rejectOrderItem = async ({ orderId, itemId, reason, text }: RejectionType) => {
  try {
    const im1 = isIM1();
    await (
      await axios.post(getAdminConfigVar('rejectionEndpoint'), {
        rejectOrders: [
          {
            orderId,
            rejectOrderItems: [
              {
                orderItemId: itemId,
                rejectReason: reason,
                rejectText: text
              }
            ]
          }
        ]
      })
    ).data;
    store.dispatch(await loadOrders(defaultOrdersToLoad(im1)));
    if (im1) {
      store.dispatch(await loadErdSurveys());
    }
    appInsights.trackEvent({
      name: 'Rejected order item',
      properties: {
        orderId,
        itemId
      }
    });
  } catch (e) {
    appInsights.trackException({
      exception: new Error(
        `Failed to update order item ${itemId} via ${getAdminConfigVar('rejectionEndpoint')} - ${e}`
      )
    });
  }
};

export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

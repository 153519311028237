import { Order } from '@avicennapharmacy/managemymeds-shared';

const getTime = (date: Date): number => (date ? date.getTime() : 0);

const nullableDateSortDesc = (a: string | null, b: string | null) => {
  if (a === null && b === null) {
    return 0;
  }
  if (a === null && b !== null) {
    return 1;
  }
  if (a !== null && b === null) {
    return -1;
  }
  return getTime(new Date(b as string)) - getTime(new Date(a as string));
};

const nullableStringSortDesc = (a: string | null, b: string | null) => {
  if (a === null && b === null) {
    return 0;
  }
  if (a === null && b !== null) {
    return 1;
  }
  if (a !== null && b === null) {
    return -1;
  }
  return (b as string).localeCompare(a as string);
};

export const sortByOrderDatesDesc = (a: Order, b: Order) =>
  getTime(new Date(b.orderDt)) - getTime(new Date(a.orderDt));

export const sortByPrintDatesDesc = (a: Order, b: Order) =>
  nullableDateSortDesc(a.printDt, b.printDt);

export const sortByEstimatedDatesDesc = (a: Order, b: Order) =>
  nullableDateSortDesc(a.estimatedCollectionDate, b.estimatedCollectionDate);

export const sortByLatestStatusDateDesc = (a: Order, b: Order) => {
  const latestUpdateA =
    a.statusUpdates.length > 0 ? a.statusUpdates.reverse()[0]?.statusUpdateDt ?? null : null;
  const latestUpdateB =
    b.statusUpdates.length > 0 ? b.statusUpdates.reverse()[0]?.statusUpdateDt ?? null : null;

  return nullableDateSortDesc(latestUpdateA, latestUpdateB);
};

export const sortByCustomerNameDesc = (a: Order, b: Order) =>
  b.patientName.localeCompare(a.patientName);

export const sortByCustomerAddressDesc = (a: Order, b: Order) =>
  nullableStringSortDesc(a.patientAddress, b.patientAddress);

export const sortByGPSurgeryDesc = (a: Order, b: Order) =>
  nullableStringSortDesc(a.patientSurgeryAddress, b.patientSurgeryAddress);

export const sortByItemCountDesc = (a: Order, b: Order) => b.items.length - a.items.length;

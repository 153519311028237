import { getAdminConfigVar, Account, Patient, Order } from '@avicennapharmacy/managemymeds-shared';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios, { AxiosResponse } from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import SendPushNotificationModal from '../pushNotification/sendPushNotificationModal';

type PatientModalProps = {
  patientId: string | null;
  setPatientId: (id: string | null) => void;
};

type FullPatientResponse = {
  patient: Patient;
  account: Account;
  orders: Order[];
};

export default ({ patientId, setPatientId }: PatientModalProps) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<FullPatientResponse | null>(null);
  const [error, setError] = useState(false);
  const [erdEnabled, setErdEnabled] = useState(false);
  const [deliveryEnabled, setDeliveryEnabled] = useState(false);
  const [saving, setSaving] = useState(false);
  const [notification, setNotification] = useState(false);

  const fetchPatient = async (id: string | null) => {
    if (!id) {
      setResponse(null);
    } else {
      try {
        setLoading(true);
        const url = getAdminConfigVar('patientsEndpoint');
        const result = await axios.post<any, AxiosResponse<FullPatientResponse>>(`${url}/${id}`);
        setResponse(result.data);
        setErdEnabled(result.data.patient.repeatDispendingEnabled);
        setDeliveryEnabled(result.data.patient.hasPrescriptionsDelivered);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(true);
      }
    }
  };

  const saveErdToggle = async () => {
    try {
      setSaving(true);
      await axios.post(getAdminConfigVar('updateERDToggleEndpoint'), {
        patientId: patientId,
        repeatDispendingEnabled: erdEnabled
      });
      setSaving(false);
    } catch (e) {
      setSaving(false);
      setError(true);
    }
  };

  const savePatient = async () => {
    if (response) {
      try {
        setSaving(true);
        await axios.patch(getAdminConfigVar('updatePatientEndpoint'), {
          ...response.patient,
          hasPrescriptionsDelivered: deliveryEnabled
        });
        setSaving(false);
      } catch (e) {
        setSaving(false);
        setError(true);
      }
    }
  };

  useEffect(() => {
    fetchPatient(patientId);

    // Hide notification content when the modal is closed
    if (patientId == null) {
      setNotification(false);
    }
  }, [patientId]);

  let header;
  let content;

  if (loading) {
    header = 'Loading';
    content = <Spinner variant="primary" animation="border" role="status" />;
  } else if (error) {
    header = 'Error';
    content = 'There was an error loading the patient';
  } else if (response) {
    const { patient, account } = response;

    header = `${patient.firstName} ${patient.lastName}`;
    if (notification) {
      header = `Send push notification to ${header}`;
      content = <SendPushNotificationModal patient={patient} patients={null} />;
    } else {
      content = (
        <MainPatientModalContent
          {...{
            patient,
            account,
            deliveryEnabled,
            setDeliveryEnabled,
            erdEnabled,
            setErdEnabled,
            setNotification,
            onSave: () => Promise.all([saveErdToggle(), savePatient()]),
            saving
          }}
        />
      );
    }
  }

  return (
    <Modal show={Boolean(patientId)} onHide={() => setPatientId(null)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setPatientId(null)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

type MainPatientModalContentProps = {
  patient: Patient;
  account: Account;
  deliveryEnabled: boolean;
  setDeliveryEnabled: (d: boolean) => void;
  erdEnabled: boolean;
  setErdEnabled: (e: boolean) => void;
  saving: boolean;
  onSave: () => Promise<[void, void]>;
  setNotification: (show: boolean) => void;
};
const MainPatientModalContent = ({
  patient,
  account,
  deliveryEnabled,
  setDeliveryEnabled,
  erdEnabled,
  setErdEnabled,
  saving,
  onSave,
  setNotification
}: MainPatientModalContentProps) => (
  <>
    <InfoRow>
      <Col sm={3}>
        <strong>Email</strong>
      </Col>
      <Col sm={9}>{account.email}</Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>Account Status</strong>
      </Col>
      <Col sm={9}>{account.accountStatus}</Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>First Name</strong>
      </Col>
      <Col sm={9}>{patient.firstName}</Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>Last Name</strong>
      </Col>
      <Col sm={9}>{patient.lastName}</Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>Primary Contact</strong>
      </Col>
      <Col sm={9}>{patient.primaryContactNumber}</Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>Secondary Contact</strong>
      </Col>
      <Col sm={9}>{patient.secondaryContactNumber}</Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>Address</strong>
      </Col>
      <Col sm={9}>
        <div>{patient.address1}</div>
        <div>{patient.address2}</div>
        <div>{patient.address3}</div>
        <div>{patient.city}</div>
        <div>{patient.postcode}</div>
      </Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>GP Name</strong>
      </Col>
      <Col sm={9}>{patient.doctorName}</Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>GP Address</strong>
      </Col>
      <Col sm={9}>
        <div>{patient.gpName}</div>
        <div>{patient.gpAddress1}</div>
        <div>{patient.gpAddress2}</div>
        <div>{patient.gpAddress3}</div>
        <div>{patient.gpAddress4}</div>
        <div>{patient.gpPostcode}</div>
      </Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>GP Contact</strong>
      </Col>
      <Col sm={9}>{patient.gpContactNumber}</Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>Medication Delivered</strong>
      </Col>
      <Col sm={9}>
        <Form.Check
          type="checkbox"
          checked={deliveryEnabled}
          onChange={() => setDeliveryEnabled(!deliveryEnabled)}
        />
      </Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>IM1 Integration</strong>
      </Col>
      <Col sm={9}>
        <Form.Check type="checkbox" disabled checked={patient.patientIntegration != null} />
      </Col>
    </InfoRow>
    <InfoRow>
      <Col sm={3}>
        <strong>ERD Enabled</strong>
      </Col>
      <Col sm={9}>
        <Form.Check
          type="checkbox"
          checked={erdEnabled}
          onChange={() => setErdEnabled(!erdEnabled)}
        />
        <Button
          variant="primary"
          disabled={saving}
          onClick={async () => {
            await onSave();
          }}
        >
          {saving ? <Spinner variant="primary" animation="border" role="status" /> : 'Save Changes'}
        </Button>
        <Button variant="secondary" onClick={() => setNotification(true)}>
          Send push notification
        </Button>
      </Col>
    </InfoRow>
  </>
);

const InfoRow = styled(Row)`
  margin-top: 0.5em;
  background-color: ${props => (props.highlighted ? 'rgb(251, 238, 242)' : 'transparent')};

  button + button {
    margin-left: 12px;
  }
`;

import React, { useEffect, useState, useCallback } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { BookingWithPatient, Resource } from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import {
  startOfMonth,
  endOfMonth,
  isBefore,
  startOfWeek,
  endOfWeek,
  addMonths,
  format,
  parse,
  getDay,
  addWeeks,
  addDays
} from 'date-fns';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

const StyledDetailsDiv = styled.div`
  div {
    margin-bottom: 2em;
  }
  .detailsTable {
    list-style: none;
    display: table;
    padding-left: 0;
    width: 100%;
  }
  .detailsTable > li {
    display: table-row;
  }
  .detailsTable > li > * {
    display: table-cell;
    padding: 5px;
  }
  .detailsTable label {
    font-weight: bold;
    text-align: left;
  }
  .detailsTable div {
    text-align: left;
  }
`;

const StyledCalendar = styled.div<{ displayPrevMonth: boolean }>`
  .rbc-off-range {
    pointer-events: none !important;
    color: transparent !important;
  }
  .isLoadingBookings {
    opacity: 0.5;
  }
  ${props => {
    if (props.displayPrevMonth === false)
      return `
      .rbc-toolbar .rbc-btn-group:nth-child(1) > button:nth-child(2){
        display: none;
      }
    `;
  }}
`;

const locales = {
  'en-GB': require('date-fns/locale/en-GB')
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

const monthLimit = addMonths(startOfMonth(new Date()), -2);

const minTime = new Date();
minTime.setHours(8, 0, 0);
const maxTime = new Date();
maxTime.setHours(20, 30, 0);

const spinner = (
  <div
    style={{
      margin: 10,
      display: 'flex',
      justifyContent: 'center'
    }}
  >
    <Spinner variant="primary" animation="border" role="status" />
  </div>
);

let formats = {
  agendaHeaderFormat: ({ start, end }: any) =>
    format(start, 'dd/MM/yyyy') + ' — ' + format(end, 'dd/MM/yyyy')
};

type formattedBooking = {
  id: number;
  title: string;
  start: Date;
  end: Date;
  resource: BookingWithPatient[];
};

type BookingsCalendarProps = {
  bookings: {
    [key: string]: BookingWithPatient[];
  };
  selectedResource: any;
  selectedPharmacy: any;
  formattedBookings: any;
  setLastLoadedMonth: (month: string) => void;
  lastLoadedMonth: string | null;
  loadingBookings: boolean;
  bookingStatusFilter: string;
  loadBookingsForResource: (
    resourceId: string,
    pharmacyId: string,
    fromDate: any,
    toDate: any,
    clear: boolean,
    callback?: Function
  ) => void;
  resources: Resource[];
  setResourcesUpdated: (num: number) => void;
  startDate: any;
  endDate: any;
  setStartDate: (month: any) => void;
  setEndDate: (month: any) => void;
};

export default ({
  selectedResource,
  selectedPharmacy,
  formattedBookings,
  setLastLoadedMonth,
  lastLoadedMonth,
  loadingBookings,
  loadBookingsForResource,
  bookingStatusFilter,
  resources,
  setResourcesUpdated,
  startDate,
  endDate,
  setStartDate,
  setEndDate
}: BookingsCalendarProps) => {
  const [filteredBookings, setFilteredBookings] = useState<any>([]);
  const [displayView, setDisplayView] = useState<string | null>('month');
  const [showModal, setShowModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<BookingWithPatient[]>([]);
  const [displayPrevMonth, setDisplayPrevMonth] = useState(true);

  const hideDetailsModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (formattedBookings) {
      if (displayView === 'month') {
        setFilteredBookings(
          formattedBookings.filter((booking: formattedBooking) => {
            let bookingsInRange =
              isBefore(startDate, new Date(booking?.start)) &&
              isBefore(new Date(booking?.start), endDate);

            return bookingsInRange && booking?.resource[0].bookingStatus === bookingStatusFilter;
          })
        );
        if (isBefore(addMonths(startDate, -1), monthLimit)) {
          setDisplayPrevMonth(false);
        }
      } else {
        setFilteredBookings(
          formattedBookings.filter(
            (booking: formattedBooking) =>
              booking?.resource[0].bookingStatus === bookingStatusFilter
          )
        );
      }
    } else {
      setFilteredBookings([]);
    }
  }, [formattedBookings, startDate, endDate, displayView, bookingStatusFilter]);

  const loadNextMonth = useCallback(
    (start, end) => {
      if (lastLoadedMonth && isBefore(new Date(lastLoadedMonth), start)) {
        setResourcesUpdated(0);
        resources.forEach((resource: Resource) => {
          loadBookingsForResource(resource.id, selectedPharmacy.id, start, end, false);
        });
        setLastLoadedMonth(end.toISOString());
      }
    },
    [lastLoadedMonth, selectedResource]
  );

  const onNavigate = (date: Date, view: string) => {
    let start = startOfMonth(date);
    let end = endOfMonth(date);

    setStartDate(start);
    setEndDate(end);

    if (lastLoadedMonth && isBefore(new Date(lastLoadedMonth), start)) {
      loadNextMonth(start, end);
    }

    if ((view === 'month' || view === 'agenda') && isBefore(addMonths(start, -1), monthLimit)) {
      setDisplayPrevMonth(false);
    } else if (view === 'week' && isBefore(addWeeks(startOfWeek(date), -1), monthLimit)) {
      setDisplayPrevMonth(false);
    } else if (view === 'day' && isBefore(addDays(date, -1), monthLimit)) {
      setDisplayPrevMonth(false);
    } else {
      setDisplayPrevMonth(true);
    }

    if (
      view === 'week' &&
      lastLoadedMonth &&
      isBefore(new Date(lastLoadedMonth), endOfWeek(date)) &&
      isBefore(date, new Date(lastLoadedMonth)) //if navigation date is before end of lastLoadedMonth but the week also shows part of next month, load next month
    ) {
      loadNextMonth(addMonths(start, 1), addMonths(end, 1));
    }
  };

  const handleSelectEvent = (event: any) => {
    setSelectedBooking(event.resource);
    setShowModal(true);
  };

  const slotPropGetter = (date: any) => {
    const slotDate = new Date(date);
    const day = format(slotDate, 'EEEE');
    const slotTime = format(slotDate, 'HH:mm');
    let backgroundColor;
    let closed = false;

    switch (day) {
      case 'Monday':
        if (
          slotTime < selectedPharmacy.pharmacyOpeningTimes.monday[0]?.from ||
          slotTime > selectedPharmacy.pharmacyOpeningTimes.monday[0]?.to
        ) {
          closed = true;
        }
        break;
      case 'Tuesday':
        if (
          slotTime < selectedPharmacy.pharmacyOpeningTimes.tuesday[0]?.from ||
          slotTime > selectedPharmacy.pharmacyOpeningTimes.tuesday[0]?.to
        ) {
          closed = true;
        }
        break;
      case 'Wednesday':
        if (
          slotTime < selectedPharmacy.pharmacyOpeningTimes.wednesday[0]?.from ||
          slotTime > selectedPharmacy.pharmacyOpeningTimes.wednesday[0]?.to
        ) {
          closed = true;
        }
        break;
      case 'Thursday':
        if (
          slotTime < selectedPharmacy.pharmacyOpeningTimes.thursday[0]?.from ||
          slotTime > selectedPharmacy.pharmacyOpeningTimes.thursday[0]?.to
        ) {
          closed = true;
        }
        break;
      case 'Friday':
        if (
          slotTime < selectedPharmacy.pharmacyOpeningTimes.friday[0]?.from ||
          slotTime > selectedPharmacy.pharmacyOpeningTimes.friday[0]?.to
        ) {
          closed = true;
        }
        break;
      case 'Saturday':
        if (
          slotTime < selectedPharmacy.pharmacyOpeningTimes.saturday[0]?.from ||
          slotTime > selectedPharmacy.pharmacyOpeningTimes.saturday[0]?.to
        ) {
          closed = true;
        }
        break;
      case 'Sunday':
        if (
          slotTime < selectedPharmacy.pharmacyOpeningTimes.sunday[0]?.from ||
          slotTime > selectedPharmacy.pharmacyOpeningTimes.sunday[0]?.to
        ) {
          closed = true;
        }
        break;
      default:
        break;
    }

    if (!selectedPharmacy.pharmacyOpeningTimes?.[day.toLowerCase()].length) {
      closed = true;
    }

    if (closed === true) {
      backgroundColor = '#e5e5e5';
    }

    var style = {
      backgroundColor
    };
    return {
      style: style
    };
  };

  return (
    <div>
      <StyledCalendar displayPrevMonth={displayPrevMonth}>
        {loadingBookings && spinner}
        <Calendar
          className={loadingBookings ? 'isLoadingBookings' : undefined}
          localizer={localizer}
          events={filteredBookings}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600 }}
          step={5}
          min={minTime}
          max={maxTime}
          onNavigate={(date, view) => onNavigate(date, view)}
          onView={view => setDisplayView(view)}
          formats={formats}
          tooltipAccessor={'resource'}
          onSelectEvent={event => handleSelectEvent(event)}
          slotPropGetter={date => slotPropGetter(date)}
          defaultDate={startDate}
        />
      </StyledCalendar>
      <Modal show={showModal} onHide={hideDetailsModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedBooking[0]?.bookingStatus === 'Cancelled' && 'Cancelled'} Booking
            {selectedBooking?.length > 1 ? 's' : ''} Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedBooking?.map(booking => {
            return (
              <StyledDetailsDiv key={booking.id}>
                <ul className="detailsTable">
                  <li>
                    <label>Service:</label>
                    <div>{booking.service}</div>
                  </li>
                  <li>
                    <label>First name:</label>
                    <div>{booking.patient?.firstName}</div>
                  </li>
                  <li>
                    <label>Last name:</label>
                    <div>{booking.patient?.lastName}</div>
                  </li>
                  <li>
                    <label>Email:</label>
                    <div>{booking.email}</div>
                  </li>
                  <li>
                    <label>Contact Number:</label>
                    <div>{booking.contactNumber || 'None provided'}</div>
                  </li>
                  <li>
                    <label>Date:</label>
                    <div>{format(new Date(booking.start), 'dd/MM/yyyy')}</div>
                  </li>
                  <li>
                    <label>Time:</label>
                    <div>
                      {format(new Date(booking.start), 'h:mm')} -{' '}
                      {format(new Date(booking.end), 'h:mm a')}
                    </div>
                  </li>
                </ul>
                <hr style={{ color: 'grey', height: 5 }} />
              </StyledDetailsDiv>
            );
          })}
        </Modal.Body>
      </Modal>
    </div>
  );
};

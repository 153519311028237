import React, { useEffect, useState, useCallback } from 'react';
import useDebounce from '../../hooks/useDebounce';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import Alert from 'react-bootstrap/Alert';
import {
  Resource,
  BookingStatus,
  BookingWithPatient,
  getAdminConfigVar,
  ServiceNamesLookup
} from '@avicennapharmacy/managemymeds-shared';
import Header from '../Header.container';
import Row from '../layout/Row';
import PageContainer from '../layout/PageContainer';
import BookingsTable from './BookingsTable.container';
import BookingsCalendar from './BookingsCalendar.container';
import {
  differenceInDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  getYear,
  isBefore,
  getMonth,
  startOfDay
} from 'date-fns';
import NewBookingModal from './NewBookingModal';
import Spinner from 'react-bootstrap/Spinner';
import ReactDatePicker from 'react-datepicker';
import { FormControl, InputGroup } from 'react-bootstrap';

const StyledDatePicker = styled(ReactDatePicker)`
  color: black;
  font-size: 16px;
  background-color: white;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  ::placeholder {
    color: black;
  }
`;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const spinner = (
  <div
    style={{
      margin: 10,
      display: 'flex',
      justifyContent: 'center'
    }}
  >
    <Spinner variant="primary" animation="border" role="status" />
  </div>
);

const bookingStatuses = [
  {
    label: 'Upcoming',
    value: BookingStatus.Upcoming
  },
  {
    label: 'Cancelled',
    value: BookingStatus.Cancelled
  }
];

const H2 = styled.h2`
  font-weight: 700;
`;

const minDate = addMonths(startOfMonth(new Date()), -2);

type customHeader = {
  decreaseMonth: () => void;
  increaseMonth: () => void;
  date: Date;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
};

export type AppProps = {
  loadResources: () => void;
  resetDiary: () => void;
  loadBookingsForResource: (
    resourceId: string,
    pharmacyId: string,
    fromDate: any,
    toDate: any,
    clear: boolean,
    callback?: Function
  ) => void;
  setSelectedResource: (id: string) => void;
  setLoadedPharmacy: (id: string) => void;
  setLastLoadedMonth: (month: string) => void;
  setLoadingBookings: () => void;
  clearSelectedBookings: () => void;
  getServiceNames: () => void;
  selectedPharmacy: any;
  selectedResource: any;
  loadedPharmacy: any;
  lastLoadedMonth: string | null;
  resources: Resource[];
  bookings: {
    [key: string]: BookingWithPatient[];
  };
  loadingBookings: boolean;
  selectedBookings: BookingWithPatient[] | null;
  setLoadingBookingsComplete: any;
  serviceNamesLookup: ServiceNamesLookup | null;
};

export default ({
  loadResources,
  resetDiary,
  loadBookingsForResource,
  setSelectedResource,
  setLoadedPharmacy,
  setLastLoadedMonth,
  setLoadingBookings,
  clearSelectedBookings,
  getServiceNames,
  selectedPharmacy,
  loadingBookings,
  selectedResource,
  loadedPharmacy,
  resources,
  bookings,
  lastLoadedMonth,
  selectedBookings,
  setLoadingBookingsComplete,
  serviceNamesLookup
}: AppProps) => {
  const [confirmationType, setConfirmationType] = useState('');
  const [viewType, setViewType] = useState('List');
  const [error] = useState('');
  const [startDate, setStartDate] = useState(minDate);
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));
  const [bookingsRefresh, setBookingsRefresh] = useState(false);
  const [searchParam, setSearchParam] = useState<string>('Name');
  const [resourcesUpdated, setResourcesUpdated] = useState<number>(0);
  const [bookingStatusFilter, setBookingStatusFilter] = useState(
    BookingStatus[BookingStatus.Upcoming]
  );
  const [serviceNamesLoading, setServiceNamesLoading] = useState(false);
  const [searchString, setSearchString, signal] = useDebounce('', 500, {
    leading: false,
    trailing: true
  });

  const getViewType = () => (viewType === 'List' ? 'Calendar' : 'List');

  useEffect(() => {
    if (selectedPharmacy.id !== loadedPharmacy) {
      setLoadedPharmacy(selectedPharmacy.id);
      resetDiary();
    }
    fetchServiceNames();
  }, []);

  const fetchServiceNames = async () => {
    setServiceNamesLoading(true);
    await getServiceNames();
    setServiceNamesLoading(false);
  };

  const loadNextMonth = useCallback(
    (start, end) => {
      if (lastLoadedMonth && isBefore(new Date(lastLoadedMonth), start)) {
        setResourcesUpdated(0);
        resources.forEach((resource: Resource) => {
          loadBookingsForResource(resource.id, selectedPharmacy.id, start, end, false);
        });
        setLastLoadedMonth(end.toISOString());
      }
    },
    [lastLoadedMonth, selectedResource]
  );

  useEffect(() => {
    setResourcesUpdated(resourcesUpdated + 1);
  }, [bookings]);

  useEffect(() => {
    if (resourcesUpdated === resources.length && resourcesUpdated > 0) {
      setLoadingBookingsComplete();
    }
  }, [resourcesUpdated]);

  const loadAllBookings = useCallback(() => {
    setBookingsRefresh(true);
    setResourcesUpdated(0);
    resources.forEach((resource: Resource) => {
      loadBookingsForResource(
        resource.id,
        selectedPharmacy.id,
        addMonths(startOfMonth(new Date()), -2),
        endOfMonth(new Date()),
        true
      );
    });
    setLastLoadedMonth(endOfMonth(new Date()).toISOString());
    clearSelectedBookings();
  }, [loadBookingsForResource, resources, selectedPharmacy.id]);

  useEffect(() => {
    if (!Boolean(resources.length)) {
      loadResources();
    }
  }, [loadResources, resources.length]);

  useEffect(() => {
    if (bookingsRefresh) {
      setBookingsRefresh(false);
    }
  }, [bookingsRefresh]);

  useEffect(() => {
    if (resources.length) {
      if (!Boolean(selectedResource)) {
        const resourceId = resources[0].id;
        setSelectedResource(resourceId);
        loadAllBookings();
      }
    }
  }, [
    loadAllBookings,
    loadBookingsForResource,
    resources,
    selectedPharmacy.id,
    selectedResource,
    setLoadingBookings,
    setSelectedResource,
    loadedPharmacy
  ]);

  const setDateRange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const monthChange = (date: Date, compensate: number) => {
    const monthStartDate = startOfMonth(addMonths(date, compensate));
    const monthEndDate = endOfMonth(addMonths(date, compensate));
    if (isBefore(monthStartDate, minDate)) {
      setStartDate(minDate);
    } else {
      setStartDate(monthStartDate);
    }
    setEndDate(monthEndDate);
    loadNextMonth(monthStartDate, monthEndDate);
  };

  useEffect(() => {
    setStartDate(startOfDay(new Date()));
    setEndDate(endOfMonth(new Date()));
  }, [bookingsRefresh]);

  useEffect(() => {
    if (searchString.length > 0) {
      setBookingStatusFilter(BookingStatus[BookingStatus.Upcoming]);
    }
  }, [searchString]);

  return (
    <>
      <PageContainer>
        <Header smallLogo>
          <Col>
            {getAdminConfigVar('featureServiceBookingDiarySearch')?.toLowerCase() === 'true' &&
              viewType === 'List' && (
                <InputGroup className="mb-3">
                  <FormControl
                    size="lg"
                    type="text"
                    placeholder={'Search by ' + searchParam}
                    value={searchString}
                    onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchString(target.value)
                    }
                    data-testid="search_bar"
                  />

                  <DropdownButton
                    variant="outline-secondary"
                    title={searchParam}
                    id="input-group-dropdown-2"
                    align="end"
                    size="lg"
                  >
                    <Dropdown.Item onClick={() => setSearchParam('Name')}>Name</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSearchParam('Email')}>Email</Dropdown.Item>
                  </DropdownButton>
                </InputGroup>
              )}
          </Col>
          <Col sm="auto">
            <Button
              variant="primary"
              size="lg"
              disabled={loadingBookings}
              onClick={() => {
                loadAllBookings();
                setSearchString('');
              }}
            >
              {'Fetch latest bookings'}
            </Button>
          </Col>
          {!searchString.length && (
            <Col sm="auto">
              <DropdownButton
                id="bulk-dropdown"
                size="lg"
                variant="success"
                title="Bulk actions"
                disabled={selectedBookings ? selectedBookings.length === 0 : true}
              >
                <Dropdown.Item
                  onClick={() => {
                    setConfirmationType('cancel-bookings');
                  }}
                >
                  Cancel Items
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          )}
        </Header>
        <H2>Service Diary</H2>
        {error && <Alert variant="danger">{error}</Alert>}
        {!searchString.length && (
          <Row>
            <Col sm>
              <p>
                The following {differenceInDays(endDate, startDate) + 1 || 1} days of service
                booking appointments
              </p>
            </Col>
            <Col sm="auto">
              <Button
                onClick={() => {
                  setViewType(getViewType());
                  clearSelectedBookings();
                }}
              >
                Switch to {getViewType()}
              </Button>
            </Col>
            {serviceNamesLoading ? (
              spinner
            ) : (
              <Col sm="auto">
                <Button
                  variant="success"
                  disabled={Boolean(!selectedResource?.length)}
                  onClick={() => {
                    setConfirmationType('new-booking');
                  }}
                >
                  Add new booking
                </Button>
              </Col>
            )}
          </Row>
        )}
        {resources.length ? (
          <Form>
            {!searchString.length && (
              <Row>
                <Col sm={2}>
                  <Form.Label>Room:</Form.Label>
                  <Form.Control
                    key={selectedResource}
                    as="select"
                    defaultValue={selectedResource}
                    onChange={(e: any) => {
                      setSelectedResource(e.target.value);
                    }}
                  >
                    {resources.map(resource => (
                      <option key={resource.id} value={resource.id}>
                        {resource.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col sm={2}>
                  <Form.Label>Status: </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e: any) => setBookingStatusFilter(BookingStatus[e.target.value])}
                    data-testid="status_change"
                  >
                    {bookingStatuses.map(status => (
                      <option key={status.value} value={status.value} data-testid={status.label}>
                        {status.label}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col sm />
                {viewType === 'List' && (
                  <Col sm={3}>
                    <Form.Label>Month:</Form.Label>
                    <StyledDatePicker
                      renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled
                      }: customHeader) => (
                        <>
                          <div
                            style={{
                              margin: 3,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <button
                              style={{
                                border: 'none',
                                margin: '0px 30px',
                                fontSize: 16,
                                background: 'none'
                              }}
                              onClick={() => {
                                decreaseMonth();
                                monthChange(date, -1);
                              }}
                              type="button"
                              disabled={prevMonthButtonDisabled}
                            >
                              {'<'}
                            </button>
                            {months[getMonth(date)] + ' ' + getYear(date)}

                            <button
                              style={{
                                border: 'none',
                                margin: '0px 30px',
                                fontSize: 16,
                                background: 'none'
                              }}
                              onClick={() => {
                                increaseMonth();
                                monthChange(date, 1);
                              }}
                              type="button"
                              disabled={nextMonthButtonDisabled}
                            >
                              {'>'}
                            </button>
                          </div>
                          {loadingBookings && spinner}
                        </>
                      )}
                      key="date-range-from-input"
                      wrapperClassName="container leftpaddingless"
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      minDate={minDate}
                      selectsRange
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(dates: any) => {
                        setDateRange(dates);
                      }}
                      shouldCloseOnSelect={false}
                    />
                  </Col>
                )}
              </Row>
            )}
          </Form>
        ) : (
          <Spinner variant="primary" animation="border" role="status" />
        )}
        <Row>
          <Col>
            {bookingsRefresh ? (
              <Spinner variant="primary" animation="border" role="status" />
            ) : (
              <>
                {viewType === 'List' ? (
                  <BookingsTable
                    loadAllBookings={loadAllBookings}
                    confirmationType={confirmationType}
                    setConfirmationType={setConfirmationType}
                    startDate={startDate}
                    endDate={endDate}
                    bookingStatusFilter={bookingStatusFilter}
                    searchString={searchString}
                    signal={signal}
                    searchParam={searchParam}
                  />
                ) : (
                  <BookingsCalendar
                    bookingStatusFilter={bookingStatusFilter}
                    setResourcesUpdated={setResourcesUpdated}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </PageContainer>
      <NewBookingModal
        show={confirmationType === 'new-booking'}
        onHide={() => setConfirmationType('')}
        loadAllBookings={loadAllBookings}
        resources={resources}
        selectedResourceId={selectedResource}
        serviceNamesLookup={serviceNamesLookup}
      />
    </>
  );
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Row from '../layout/Row';
import Col from 'react-bootstrap/Col';
import {
  getAdminConfigVar,
  formatDate,
  BookingStatus,
  Booking,
  BookingWithPatient,
  Resource
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { addDays, differenceInMinutes, getMonth, isBefore, format, isEqual } from 'date-fns';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { BookingCancelModel } from '../../types';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { ExportToCsv } from 'export-to-csv';
import ResponseModal from 'components/common/ResponseModal';

const StyledTable = styled.div<{ searchFilterMode: boolean; bookingStatusFilter: string }>`
  .dropdown-toggle {
    background-color: #0f5d5e;
  }
  .above-table {
    display: flex;
    justify-content: space-between;
  }
  ${props => {
    if (props.searchFilterMode !== true && props.bookingStatusFilter === 'Upcoming') {
      return `
        .cancelled-key {
          display: none;
        }
      `;
    }
    if (props.searchFilterMode !== true && props.bookingStatusFilter === 'Cancelled') {
      return `
        .past-bookings-key{
          display: none;
        }
      `;
    }
  }}
`;

const bookingStatuses = [
  {
    label: 'Upcoming',
    value: BookingStatus.Upcoming
  },
  {
    label: 'Cancelled',
    value: BookingStatus.Cancelled
  }
];

const CUSTOMER_ATTENDED_MEETING = 'Customer attended meeting',
  CUSTOMER_MISSED_MEETING = 'Customer missed meeting';

const StyledVideoIcon = styled(FontAwesomeIcon)`
  margin-left: 12px;
`;

const Centered = styled.div`
  text-align: center;
`;

const red = 'rgba(255,0,0,0.4)';
const amber = 'rgba(241,145,67,0.4)';

const ColouredSpan = styled.span`
  padding: 0 5px;
  border-radius: 5px;
`;

type DayWithBookings = {
  date: string;
  diaryBookings: BookingWithPatient[];
};

type BookingMap = {
  [key: string]: BookingWithPatient[];
};

type DiaryProps = {
  selectedPharmacy: any;
  selectedResource: any;
  resources: Resource[];
  resourceNames: {
    [key: string]: string;
  };
  loadingBookings: boolean;
  bookings: {
    [key: string]: BookingWithPatient[];
  };
  selectBooking: (booking: Booking) => void;
  selectedBookings: BookingWithPatient[] | null;
  confirmationType: string;
  setConfirmationType: (confirmationType: string) => void;
  loadAllBookings: () => void;
  startDate: Date;
  endDate: Date | null;
  bookingStatusFilter: string;
  searchString: string;
  signal: number;
  searchParam: string;
  clearSelectedBookings: () => void;
};

type BulkCancelRequest = {
  pharmacyId: string | null;
  BookingList: BookingCancelModel[];
  cancellationReason: string;
};

const defaultBookingCancelRequest = (): BulkCancelRequest => ({
  pharmacyId: '',
  BookingList: [],
  cancellationReason: ''
});

const bulkSelectedBookings: BookingWithPatient[] = [];
const bookingRequest: BulkCancelRequest = defaultBookingCancelRequest();

export default ({
  selectedPharmacy,
  loadingBookings,
  resources,
  resourceNames,
  bookings,
  selectedResource,
  selectBooking,
  selectedBookings,
  confirmationType,
  setConfirmationType,
  loadAllBookings,
  startDate,
  endDate,
  bookingStatusFilter,
  searchString,
  signal,
  searchParam,
  clearSelectedBookings
}: DiaryProps) => {
  const [daysWithBookings, setDaysWithBookings] = useState<DayWithBookings[]>([]);
  const [modalError, setModalError] = useState('');
  const [selectedBooking, setSelectedBooking] = useState<BookingWithPatient | null>(null);
  const [newBookingStatus, setNewBookingStatus] = useState(BookingStatus[BookingStatus.Missed]);
  const [bookingNote, setBookingNote] = useState('');
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [showReSendConfirmationModal, setShowReSendConfirmationModal] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');
  const [originalBookings, setOriginalBookings] = useState<BookingWithPatient[] | null>([]);
  const [filteredBookings, setFilteredBookings] = useState<BookingWithPatient[] | null>([]);
  const [isValidBookings, setIsValidBookings] = useState(false);
  const [isResetSelectedCheckboxes, setIsResetSelectedCheckboxes] = useState(true);
  const [filteringBookings, setFilteringBookings] = useState(false);
  const [filteredSearchBookings, setFilteredSearchBookings] = useState<BookingWithPatient[] | null>(
    []
  );
  const [searchFilterMode, setSearchFilterMode] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isExpandableTable, setIsExpandableTable] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [isFreshCheckbox, setIsFreshCheckbox] = useState(true);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [successModalTitle, setSuccessModalTitle] = useState('');
  const [successModalBody, setSuccessModalBody] = useState('');

  useEffect(() => {
    if (searchString.length) {
      setSearchFilterMode(true);
      setLoadingSearch(true);
      getBookingSearch();
    } else {
      setSearchFilterMode(false);
    }
  }, [signal, searchParam]);

  const getBookingSearch = async () => {
    const { data } = await axios.post(
      getAdminConfigVar('serviceBookingGetBookingsSearchEndpoint'),
      {
        PharmacyId: selectedPharmacy.id,
        SearchValue: searchString,
        EntityType: searchParam
      }
    );
    setFilteredSearchBookings(data.result);
    setLoadingSearch(false);
  };

  useEffect(() => {
    if (filteredBookings?.length) {
      const bookingMap = filteredBookings.reduce((acc: BookingMap, next: BookingWithPatient) => {
        const dateStr = formatDate(next.start, 'dayDateMonth');
        if (!acc[dateStr]) {
          acc[dateStr] = [];
        }
        acc[dateStr].push(next);
        return acc;
      }, {});
      setDaysWithBookings(
        Object.entries(bookingMap).map<DayWithBookings>(([date, diaryBookings]) => ({
          date,
          diaryBookings
        }))
      );
    } else {
      setDaysWithBookings([]);
    }
  }, [filteredBookings]);

  useEffect(() => {
    if (bookings[selectedResource]) {
      selectedBookings?.splice(0, selectedBookings.length);
      clearSelectedBookings();
      setSelectedAll(false);
      setFilteringBookings(true);
      const filteredBookings = bookings[selectedResource].filter(booking => {
        let bookingsInRange =
          getMonth(new Date(booking?.start)) === getMonth(startDate) &&
          isBefore(startDate, new Date(booking?.start));
        if (endDate) {
          //calendar picker doesnt return end date when only one day is picked
          bookingsInRange =
            bookingsInRange && isBefore(new Date(booking?.start), addDays(endDate, 1));
        } else {
          bookingsInRange =
            bookingsInRange && isBefore(new Date(booking?.start), addDays(startDate, 1));
        }
        return bookingsInRange && booking?.bookingStatus === bookingStatusFilter;
      });

      setIsExpandableTable(false);
      const uniqueBookingRecord = filteredBookings.reduce(
        (acc: BookingMap, next: BookingWithPatient) => {
          if (!acc[next.start]) {
            acc[next.start] = [];
            acc[next.start].push(next);
          } else {
            setIsExpandableTable(true);
          }

          return acc;
        },
        {}
      );

      const mergedUniqueBookings: BookingWithPatient[] = [];
      Object.values(uniqueBookingRecord).map(subArray => {
        return mergedUniqueBookings.push(...subArray);
      });

      setOriginalBookings(filteredBookings);
      setFilteredBookings(mergedUniqueBookings);
    } else {
      setFilteredBookings([]);
    }
  }, [bookings, selectedResource, startDate, endDate, bookingStatusFilter]);

  useEffect(() => {
    if (filteredBookings) {
      setFilteringBookings(false);
    }
  }, [filteredBookings]);

  useEffect(() => {
    if (!isFreshCheckbox) {
      setIsFreshCheckbox(true);
    }
  }, [isFreshCheckbox]);

  const cleanBulkBookingCancel = () => {
    bulkSelectedBookings.splice(0, bulkSelectedBookings.length);
    bookingRequest.cancellationReason = '';
    bookingRequest.pharmacyId = '';
    bookingRequest.BookingList.splice(0, bookingRequest.BookingList.length);
    selectedBookings?.splice(0, selectedBookings.length);
    setCancellationReason('');
    setIsValidBookings(false);
    setIsResetSelectedCheckboxes(true);
    setIsFreshCheckbox(false);
  };

  const hideCancellationModal = () => {
    setShowCancellationModal(false);
    setCancellationReason('');
    setSelectedBooking(null);
    setModalError('');
  };

  const hideResendConfirmationEmailModal = () => {
    setShowReSendConfirmationModal(false);
    setSelectedBooking(null);
    setModalError('');
  };

  useEffect(() => {
    if (!isResetSelectedCheckboxes) {
      setIsResetSelectedCheckboxes(true);
    }
  }, [isValidBookings, isResetSelectedCheckboxes]);

  if (!resources.length) {
    return null;
  }

  const expandRow = {
    renderer: (booking: any) => (
      <>
        {originalBookings
          ?.filter(x => x.start == booking.start && x.id !== booking.id)
          ?.map((selectedBooking: any, i: number, array: BookingWithPatient[]) => {
            return (
              <div
                className={`row ${i + 1 < array.length ? 'border-bottom' : ''}`}
                key={'multibooking-' + selectedBooking.id}
              >
                <div style={{ float: 'left', width: '5.4%' }}></div>
                <div style={{ float: 'left', width: '7.2%', padding: '0.75rem' }}>
                  {formatDate(selectedBooking.start, 'date')}
                </div>
                <div style={{ float: 'left', width: '5.8%', padding: '0.75rem' }}>
                  {formatDate(selectedBooking.start, 'time')}
                </div>
                <div style={{ float: 'left', width: '6.9%', padding: '0.75rem' }}>
                  {differenceInMinutes(
                    new Date(selectedBooking.end),
                    new Date(selectedBooking.start)
                  ) + ` mins`}
                </div>
                <div style={{ float: 'left', width: '9.2%', padding: '0.75rem' }}>
                  {resourceNames[selectedBooking.resourceId]}
                </div>
                <div style={{ float: 'left', width: '13.6%', padding: '0.75rem' }}>
                  {selectedBooking.service}
                  {selectedBooking.serviceSubType ? ': ' + selectedBooking.serviceSubType : ''}
                </div>
                <div style={{ float: 'left', width: '11%', padding: '0.75rem' }}>
                  {selectedBooking.patient.firstName + ' ' + selectedBooking.patient.lastName}
                </div>
                <div style={{ float: 'left', width: '8.8%', padding: '0.75rem' }}>
                  {`${
                    selectedBooking.patient?.dateOfBirth
                      ? `${formatDate(selectedBooking.patient?.dateOfBirth, 'date')}`
                      : ''
                  }`}
                </div>
                <div style={{ float: 'left', width: '8.7%', padding: '0.75rem' }}>
                  {`${selectedBooking.contactNumber || ''}`}
                </div>
                <div style={{ float: 'left', width: '5%', padding: '0.75rem' }}>
                  {selectedBooking.email && selectedBooking.email.includes('@') && (
                    <Centered className="mx-auto">
                      <FontAwesomeIcon icon="check-circle" color="#0f5d5e" />
                    </Centered>
                  )}
                </div>
                <div style={{ float: 'left', width: '13.2%', padding: '0.75rem' }}>
                  {!isBefore(new Date(selectedBooking.start), new Date()) &&
                    selectedBooking.bookingStatus !== 'Cancelled' && (
                      <>
                        {booking.email && booking.email.includes('@') && (
                          <Button
                            size="sm"
                            data-testid={'resend-' + selectedBooking.id}
                            block
                            variant="outline-primary"
                            onClick={() => {
                              setSelectedBooking(selectedBooking);
                              setShowReSendConfirmationModal(true);
                            }}
                          >
                            Resend Confirmation Email
                          </Button>
                        )}
                        <Button
                          size="sm"
                          block
                          variant="outline-danger"
                          onClick={() => {
                            setSelectedBooking(booking);
                            setShowCancellationModal(true);
                          }}
                        >
                          Cancel Appointment
                        </Button>
                      </>
                    )}
                </div>
                <div style={{ float: 'left', width: '4.6%', padding: '0.75rem' }}>
                  <Centered>
                    <input
                      type="checkbox"
                      data-testid={'checkbox_' + selectedBooking.id}
                      key={selectedBooking.cancellationKey}
                      onChange={e => {
                        selectBooking(selectedBooking);
                      }}
                      value={selectedBooking.id}
                      defaultChecked={
                        selectedBookings
                          ? selectedBookings.some(x => x.id === selectedBooking.id)
                          : false
                      }
                    />
                  </Centered>
                </div>
              </div>
            );
          })}
      </>
    ),
    showExpandColumn: isExpandableTable,
    expandColumnRenderer: (param: any) => {
      var bookingDetail = originalBookings?.find(x => x.id == param.rowKey);
      var multiBookings = originalBookings?.filter(x => x.start == bookingDetail?.start);
      if (multiBookings != null && multiBookings?.length > 1) {
        if (param.expanded) {
          return (
            <div className="col-md-12 text-center" data-testid={'less-' + bookingDetail?.start}>
              <a href="javascript:void(0)" style={{ textDecoration: 'none' }}>
                <b>
                  -{multiBookings?.length - 1}
                  <br />
                  Less
                </b>
              </a>
            </div>
          );
        } else {
          return (
            <div className="col-md-12 text-center" data-testid={'more-' + bookingDetail?.start}>
              <a href="javascript:void(0)" style={{ textDecoration: 'none' }}>
                <b>
                  +{multiBookings?.length - 1}
                  <br />
                  More
                </b>
              </a>
            </div>
          );
        }
      }
    },
    expandHeaderColumnRenderer: () => {
      return <></>;
    },
    expandByColumnOnly: true
  };

  const columns = [
    {
      dataField: 'start',
      text: 'Date',
      formatter: (cell: any, booking: any) => {
        return (
          <div data-testid={'start_' + booking.id}>{`${formatDate(booking.start, 'date')}`}</div>
        );
      },
      headerStyle: () => {
        return { width: '6%' };
      },
      csvFormatter: (cell: any) => `${formatDate(cell, 'date')}`,
      sort: true
    },
    {
      dataField: 'start',
      text: 'Time',
      formatter: (cell: any, booking: any) => {
        return <div>{`${formatDate(booking.start, 'time')}`}</div>;
      },
      headerStyle: () => {
        return { width: '6%' };
      },
      csvFormatter: (cell: any) => `${formatDate(cell, 'time')}`,
      sort: true
    },
    {
      dataField: 'duration',
      text: 'Duration',
      formatter: (cell: any, booking: any) => {
        return (
          <div>{`${differenceInMinutes(new Date(booking.end), new Date(booking.start))} mins`}</div>
        );
      },
      headerStyle: () => {
        return { width: '7%' };
      },
      csvFormatter: (cell: any, booking: any) =>
        `${differenceInMinutes(new Date(booking.end), new Date(booking.start))} mins`,
      sort: true
    },
    {
      dataField: 'resourceId',
      text: 'Room',
      formatter: (cell: any, booking: any) => {
        return `${resourceNames[booking.resourceId]}`;
      },
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell: any) => `${resourceNames[cell]}`
    },
    {
      dataField: 'service',
      formatter: (cell: any, booking: BookingWithPatient) => {
        return `${booking.service}${booking.serviceSubType ? ': ' + booking.serviceSubType : ''}`;
      },
      text: 'Service',
      headerStyle: () => {
        return { width: '15%' };
      },
      sort: true
    },
    {
      dataField: 'patient.firstName',
      text: 'Name',
      formatter: (cell: any, booking: any) => {
        return `${booking.patient.firstName} ${booking.patient.lastName}`;
      },
      headerStyle: () => {
        return { width: '12%' };
      },
      csvFormatter: (cell: any, booking: any) =>
        `${booking.patient.firstName} ${booking.patient.lastName}`,
      sort: true
    },
    {
      dataField: 'patient.dateOfBirth',
      text: 'Date of Birth',
      formatter: (cell: any, booking: any) => {
        return `${
          booking.patient?.dateOfBirth ? `${formatDate(booking.patient?.dateOfBirth, 'date')}` : ''
        }`;
      },
      headerStyle: () => {
        return { width: '9%' };
      },
      csvFormatter: (cell: any) => `${cell ? formatDate(cell, 'date') : ''}`,
      sort: true
    },
    {
      dataField: 'contactNumber',
      text: 'Contact Number',
      headerStyle: () => {
        return { width: '9%' };
      },
      csvFormatter: (cell: any) => `${cell || ''}`
    },
    {
      dataField: 'contactEmail',
      text: 'Email',
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (cell: any, booking: any) => {
        return (
          <>
            {booking.email && booking.email.includes('@') && (
              <Centered className="mx-auto">
                <FontAwesomeIcon icon="check-circle" color="#0f5d5e" />
              </Centered>
            )}
          </>
        );
      },
      csvFormatter: (cell: any, booking: any) => `${booking.email}`
    },
    {
      dataField: 'bookingStatus',
      text: 'Booking Status',
      hidden: true,
      csvFormatter: (cell: any) => `${cell || ''}`
    },
    {
      dataField: 'action',
      text: 'Actions',
      hidden: bookingStatusFilter === 'Cancelled',
      formatter: (cell: any, booking: any) => {
        return (
          <>
            {Boolean(booking.vcStartLink) && (
              <a
                href={booking.vcStartLink!}
                className="btn btn-outline-primary btn-block btn-sm"
                target="_blank"
                rel="noopener noreferrer"
              >
                Start Video
                <StyledVideoIcon icon={faVideo} size="lg" />
              </a>
            )}
            {!isBefore(new Date(booking.start), new Date()) &&
              booking.bookingStatus !== 'Cancelled' && (
                <>
                  {booking.email && booking.email.includes('@') && (
                    <Button
                      size="sm"
                      data-testid={'resend-' + booking.id}
                      block
                      variant="outline-primary"
                      onClick={() => {
                        setSelectedBooking(booking);
                        setShowReSendConfirmationModal(true);
                      }}
                    >
                      Resend Confirmation Email
                    </Button>
                  )}
                  <Button
                    size="sm"
                    block
                    variant="outline-danger"
                    onClick={() => {
                      setSelectedBooking(booking);
                      setShowCancellationModal(true);
                    }}
                  >
                    Cancel Appointment
                  </Button>
                </>
              )}
          </>
        );
      },
      headerStyle: () => {
        return { width: '14%' };
      },
      csvExport: false
    },
    {
      dataField: 'selection',
      text: 'Select',
      hidden: bookingStatusFilter === 'Cancelled' || searchFilterMode,
      formatter: (cell: any, booking: any) => {
        return (
          <Centered>
            <input
              type="checkbox"
              data-testid={'checkbox_' + booking.id}
              key={booking.cancellationKey}
              onChange={e => {
                selectBooking(booking);
              }}
              value={booking.id}
              defaultChecked={
                selectedBookings
                  ? selectedBookings.some(selectedBooking => selectedBooking.id === booking.id)
                  : false
              }
            />
          </Centered>
        );
      },
      headerStyle: () => {
        return { width: '4%' };
      },
      csvExport: false
    }
  ];

  const options = {
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10
      },
      {
        text: '25',
        value: 25
      },
      {
        text: '50',
        value: 50
      },
      {
        text: 'All',
        value: searchFilterMode
          ? filteredSearchBookings?.length || 0
          : filteredBookings?.length || 0
      }
    ]
  };

  const MyExportCSV = (props: any) => {
    const handleClick = () => {
      var mappedArray = originalBookings?.map((val: BookingWithPatient) => ({
        date: formatDate(val.start, 'date'),
        time: formatDate(val.start, 'time'),
        duration: differenceInMinutes(new Date(val.end), new Date(val.start)) + ` mins`,
        room: resourceNames[val.resourceId],
        service: val.service + (val.serviceSubType ? ': ' + val.serviceSubType : ''),
        name: val.patient.firstName + ' ' + val.patient.lastName,
        birthDate: val.patient?.dateOfBirth ? formatDate(val.patient?.dateOfBirth, 'date') : '',
        contactNumber: val.contactNumber || '',
        email: val.email || '',
        status: val.bookingStatus
      }));

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: false,
        showTitle: false,
        useTextFile: false,
        useKeysAsHeaders: true
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(mappedArray);
    };
    return (
      <div className="exp-btn mb-3">
        <button className="btn btn-primary" onClick={handleClick}>
          Export to CSV
        </button>
      </div>
    );
  };

  const AllSelection = (selectedAll: boolean) => {
    bookings[selectedResource]
      .filter(x => new Date(x.start) > startDate && x.bookingStatus !== 'Cancelled')
      .map((selectedBooking: any) => {
        if (!selectedAll && !selectedBookings?.some(x => x.id === selectedBooking.id)) {
          selectBooking(selectedBooking);
        } else if (selectedAll && selectedBookings?.some(x => x.id === selectedBooking.id)) {
          selectBooking(selectedBooking);
        }
      });

    setSelectedAll(!selectedAll);
    setIsFreshCheckbox(false);
  };

  const rowStyle = (row: BookingWithPatient) => {
    if (row.bookingStatus === 'Cancelled') {
      return { backgroundColor: red };
    } else if (isBefore(new Date(row.start), new Date())) {
      return { backgroundColor: amber };
    }
  };

  return (
    <>
      {loadingBookings || loadingSearch ? (
        <Spinner variant="primary" animation="border" role="status" />
      ) : (
        <Row>
          <Col>
            {!loadingBookings && bookings[selectedResource] && !filteringBookings && (
              <div>
                <h2
                  style={{
                    margin: 3,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {searchFilterMode
                    ? `Search Results for '${searchString}'`
                    : format(new Date(startDate), 'LLLL yyyy')}
                </h2>
                {isResetSelectedCheckboxes && (
                  <ToolkitProvider
                    keyField="id"
                    data={searchFilterMode ? filteredSearchBookings || [] : filteredBookings || []}
                    columns={columns}
                    exportCSV={{
                      fileName: searchFilterMode
                        ? 'Bookings.csv'
                        : format(new Date(startDate), 'dd') +
                          `${
                            endDate && !isEqual(endDate, startDate)
                              ? ' - ' + format(new Date(endDate), 'dd')
                              : ''
                          } ` +
                          `${format(new Date(startDate), 'LLLL yyyy')} Bookings.csv`
                    }}
                  >
                    {(props: any) => (
                      <div>
                        {(!loadingBookings && !filteredBookings?.length && !searchFilterMode) ||
                        (searchFilterMode && !filteredSearchBookings?.length && !loadingSearch) ? (
                          <p>No results</p>
                        ) : (
                          <StyledTable
                            searchFilterMode={searchFilterMode}
                            bookingStatusFilter={bookingStatusFilter}
                          >
                            <div className="above-table">
                              <div className="pt-3">
                                <span className="cancelled-key">
                                  <ColouredSpan className="bg-danger text-white mb-1 mr-1">
                                    Red
                                  </ColouredSpan>
                                  - Cancelled Bookings
                                </span>
                                <span className="past-bookings-key">
                                  <ColouredSpan className="bg-warning ml-2 mb-1 mr-1">
                                    Amber
                                  </ColouredSpan>
                                  - Past Bookings
                                </span>
                              </div>
                              <div className="row">
                                <MyExportCSV {...props.csvProps} />
                                <div className="exp-btn mb-3 ml-3 mr-3">
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => AllSelection(selectedAll)}
                                  >
                                    {selectedAll ? 'Unselect All' : 'Select All'}
                                  </button>
                                </div>
                              </div>
                            </div>
                            {isFreshCheckbox && (
                              <BootstrapTable
                                {...props.baseProps}
                                pagination={paginationFactory(options)}
                                style={{ dropdownToggle: 'background-color' }}
                                rowStyle={rowStyle}
                                expandRow={expandRow}
                              />
                            )}
                          </StyledTable>
                        )}
                      </div>
                    )}
                  </ToolkitProvider>
                )}
                <br />
              </div>
            )}
          </Col>
        </Row>
      )}
      <Modal show={showCancellationModal} onHide={hideCancellationModal}>
        <Modal.Header>
          <Modal.Title>Cancel Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalError && <Alert variant="danger">{modalError}</Alert>}
          <Form>
            {!selectedBooking?.email?.includes('@') && (
              <Alert variant="warning">Selected booking does not have a valid email address.</Alert>
            )}
            <Form.Group>
              <Form.Label>Cancellation reason</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={cancellationReason}
                onChange={(e: any) => setCancellationReason(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCancellationModal}>
            Cancel
          </Button>
          <Button
            disabled={cancellationReason.trim() === ''}
            onClick={async () => {
              try {
                await Axios.delete(getAdminConfigVar('serviceBookingCancelBookingEndpoint'), {
                  data: {
                    pharmacyId: selectedPharmacy.id,
                    resourceId: selectedBooking?.resourceId,
                    bookingId: selectedBooking?.id,
                    startDate: selectedBooking?.start,
                    cancellationReason,
                    cancellationKey: selectedBooking?.cancellationKey
                  }
                });
                hideCancellationModal();
                loadAllBookings();
              } catch (error) {
                setModalError(
                  error.response.status === 422
                    ? error.response.data.message
                    : 'Unable to delete this booking'
                );
              }
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={confirmationType === 'cancel-bookings'} onHide={() => setConfirmationType('')}>
        <Modal.Header>
          <Modal.Title>
            Cancel {selectedBookings?.length}{' '}
            {selectedBookings && selectedBookings?.length > 1 ? 'Bookings' : 'Booking'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalError && <Alert variant="danger">{modalError}</Alert>}
          <Form>
            {selectedBookings &&
              selectedBookings?.filter(x => !x.email?.includes('@')).length > 0 && (
                <Alert variant="warning">Some bookings do not have valid email addresses.</Alert>
              )}
            <Form.Group>
              <Form.Label>Cancellation reason</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={cancellationReason}
                onChange={(e: any) => setCancellationReason(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <input
                type="checkbox"
                key="checkbox-email-validation"
                onChange={e => {
                  setIsValidBookings(e.target.checked);
                }}
              />{' '}
              <Form.Label>Cancel bookings which have a valid email.</Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmationType('')}>
            Cancel
          </Button>
          <Button
            disabled={cancellationReason.trim() === ''}
            onClick={async () => {
              try {
                bookingRequest.cancellationReason = cancellationReason;
                bookingRequest.pharmacyId = selectedPharmacy.id;

                if (isValidBookings) {
                  if (
                    selectedBookings &&
                    selectedBookings?.filter(x => x.email?.includes('@')).length > 0
                  ) {
                    selectedBookings
                      ?.filter(x => x.email?.includes('@'))
                      .map(booking => {
                        bookingRequest.BookingList.splice(bookingRequest.BookingList.length, 0, {
                          customerId: '',
                          resourceId: booking?.resourceId,
                          bookingId: booking?.id,
                          startDate: booking?.start,
                          cancellationKey: booking?.cancellationKey
                        });
                      });
                  }
                } else {
                  selectedBookings?.map(booking => {
                    bookingRequest.BookingList.splice(bookingRequest.BookingList.length, 0, {
                      customerId: '',
                      resourceId: booking?.resourceId,
                      bookingId: booking?.id,
                      startDate: booking?.start,
                      cancellationKey: booking?.cancellationKey
                    });
                  });
                }

                if (bookingRequest.BookingList.length > 0) {
                  await Axios.delete(getAdminConfigVar('bulkServiceBookingCancelBookingEndpoint'), {
                    data: bookingRequest
                  });
                }

                setConfirmationType('');
              } catch (error) {
                setModalError(
                  error.response.status === 422
                    ? error.response.data.message
                    : 'Unable to delete those bookings'
                );
              } finally {
                cleanBulkBookingCancel();
                if (setSuccessModalTitle !== undefined) {
                  setSuccessModalTitle('Bulk Booking Cancellation Request');
                }

                if (setSuccessModalBody !== undefined) {
                  setSuccessModalBody(
                    'The system started to cancel bookings. This may take a few minutes to process.'
                  );
                }

                if (setShowResponseModal !== undefined) {
                  setShowResponseModal(true);
                }
              }
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showReSendConfirmationModal} onHide={hideResendConfirmationEmailModal}>
        <Modal.Header>
          <Modal.Title className="text-center col-md-12">Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalError && <Alert variant="danger">{modalError}</Alert>}
          <p className="text-center">
            If you click the Confirm button,{' '}
            <strong>
              {selectedBooking?.patient.firstName + ' ' + selectedBooking?.patient.lastName}
            </strong>{' '}
            will receive a new confirmation email for booking slot{' '}
            <strong>
              {formatDate(new Date(selectedBooking?.start ?? 0), 'date') +
                ' ' +
                formatDate(new Date(selectedBooking?.start ?? 0), 'time') +
                ' / ' +
                formatDate(new Date(selectedBooking?.end ?? 0), 'date') +
                ' ' +
                formatDate(new Date(selectedBooking?.end ?? 0), 'time')}
            </strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideResendConfirmationEmailModal}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              try {
                await Axios.post(
                  getAdminConfigVar('serviceBookingReSendBookingConfirmationEmailEndpoint'),
                  {
                    pharmacyId: selectedPharmacy.id,
                    resourceId: selectedBooking?.resourceId,
                    bookingId: selectedBooking?.id,
                    startDate: selectedBooking?.start
                  }
                );
                hideResendConfirmationEmailModal();
              } catch (error) {
                setModalError(
                  error.response.status === 422
                    ? error.response.data.message
                    : 'Unable to delete this booking'
                );
              }
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <ResponseModal
        show={showResponseModal}
        setShowSuccess={setShowResponseModal}
        title={successModalTitle}
        message={successModalBody}
      />
    </>
  );
};

import { connect } from 'react-redux';
import BookingsCalendar from './BookingsCalendar';
import { GlobalState } from '../../reducers';
import { getSelectedPharmacy } from '../../selectors/user';
import {
  setLoadingBookings,
  loadBookingsForResource,
  setLastLoadedMonth
} from '../../actions/bookings';
import {
  getResourceNames,
  getSelectedResource,
  getResources,
  getBookings,
  formatBookings,
  getlastLoadedMonth
} from '../../selectors/bookings';

const mapStateToProps = (state: GlobalState) => ({
  selectedPharmacy: getSelectedPharmacy(state),
  selectedResource: getSelectedResource(state),
  resources: getResources(state),
  resourceNames: getResourceNames(state),
  loadingBookings: state.bookings.loadingBookings,
  bookings: getBookings(state),
  formattedBookings: formatBookings(state),
  lastLoadedMonth: getlastLoadedMonth(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setLoadingBookings: async () => {
    dispatch(await setLoadingBookings());
  },
  loadBookingsForResource: async (
    resourceId: string,
    pharmacyId: string,
    fromDate: any,
    toDate: any,
    clear: boolean,
    callback?: Function
  ) => {
    dispatch(setLoadingBookings());
    dispatch(
      await loadBookingsForResource({
        resourceId,
        pharmacyId,
        fromDate,
        toDate,
        clear
      })
    );
    callback && callback();
  },
  setLastLoadedMonth: async (month: string) => {
    dispatch(await setLastLoadedMonth(month));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingsCalendar);

import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import SelectFeedback from '../serviceEdit/SelectFeedback';
import { Pharmacy, PromotedServices, Service } from '@avicennapharmacy/managemymeds-shared';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types';

const PROMO_TEXT_MAX_LENGTH = 50;

type PromotedServiceProps = {
  loading: boolean;
  services: Service[];
  pharmacy: Pharmacy;
  validated: boolean;
  promotedService: PromotedServices;
  setPharmacy: (p: Partial<Pharmacy>) => void;
  i: number;
};

export default ({
  loading,
  services,
  pharmacy,
  validated,
  promotedService,
  setPharmacy,
  i
}: PromotedServiceProps) => {
  const [selectedService, setSelectedService] = useState<{ value: string; label: string } | null>(
    null
  );

  useEffect(() => {
    const matchedService = services.find(s => s.id === promotedService.serviceId);

    setSelectedService(
      matchedService && promotedService.serviceId
        ? {
            value: promotedService.serviceId,
            label: matchedService.name
          }
        : null
    );
  }, [services, pharmacy.promotedServices]);

  return (
    <Row key={i}>
      <Col sm={5}>
        <Select
          isLoading={loading}
          options={services
            .filter(s => !pharmacy.promotedServices.map(p => p.serviceId).includes(s.id))
            .map(s => ({
              value: s.id,
              label: s.name
            }))}
          value={selectedService}
          onChange={({ value }: any) => {
            if (value) {
              const newArr = Array.from(pharmacy.promotedServices);
              newArr[i] = {
                ...pharmacy.promotedServices[i],
                serviceId: value
              };
              setPharmacy({
                ...pharmacy,
                promotedServices: newArr
              });
            }
          }}
          isDisabled={!hasRole(Role.Admin_Services_Edit)}
        />
        <SelectFeedback
          isInvalid={!selectedService && validated}
          feedback="Please select the service you would like to promote"
        />
      </Col>
      <Col sm={7}>
        <Row>
          <Col sm={7}>
            <Form.Control
              type="text"
              as="textarea"
              rows="1"
              value={promotedService.serviceCardText || ''}
              onChange={(e: any) => {
                const newArr = Array.from(pharmacy.promotedServices);
                newArr[i] = {
                  ...pharmacy.promotedServices[i],
                  serviceCardText: e.target.value
                };
                setPharmacy({
                  ...pharmacy,
                  promotedServices: newArr
                });
              }}
              required
              isInvalid={(promotedService.serviceCardText || '').length > PROMO_TEXT_MAX_LENGTH}
              disabled={!hasRole(Role.Admin_Services_Edit)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter the promotional text to display on the card, up to{' '}
              {PROMO_TEXT_MAX_LENGTH} characters.
            </Form.Control.Feedback>
          </Col>
          {hasRole(Role.Admin_Services_Edit) && (
            <Col sm={5}>
              <Button
                variant="danger"
                onClick={() => {
                  setPharmacy({
                    ...pharmacy,
                    promotedServices: pharmacy.promotedServices.filter(
                      (ps: PromotedServices) => ps.serviceId !== promotedService.serviceId
                    )
                  });
                }}
              >
                Remove service
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

type Props = {
  currentPage: number;
  totalPages: number;
  setPage: (page: number) => void;
};

export default ({ currentPage, totalPages, setPage }: Props) => (
  <Pagination>
    {totalPages > 5 && <Pagination.First disabled={currentPage === 1} onClick={() => setPage(1)} />}
    <Pagination.Prev disabled={currentPage === 1} onClick={() => setPage(currentPage - 1)} />
    {totalPages > 5 && currentPage > 3 && <Pagination.Ellipsis />}
    {Array.from(Array(totalPages)).map((_, i) => {
      const thisPage = i + 1;
      const offsetFromPage = Math.abs(currentPage - thisPage);

      if (
        (currentPage < 3 && thisPage <= 5) || // In first 5
        (currentPage > totalPages - 2 && thisPage >= totalPages - 5) || // In last 5
        offsetFromPage <= 2 // In current 5 with selected in middle position
      ) {
        return (
          <Pagination.Item onClick={() => setPage(thisPage)} active={currentPage === thisPage}>
            {thisPage}
          </Pagination.Item>
        );
      }
      return null;
    })}
    {totalPages > 5 && currentPage < totalPages - 2 && <Pagination.Ellipsis />}
    <Pagination.Next
      disabled={currentPage === totalPages}
      onClick={() => setPage(currentPage + 1)}
    />
    {totalPages > 5 && (
      <Pagination.Last disabled={currentPage === totalPages} onClick={() => setPage(totalPages)} />
    )}
  </Pagination>
);

import { createSelector } from 'reselect';
import { GlobalState } from '../reducers/index';
import {
  Order,
  NEW,
  AMEND,
  RAISED,
  READY_FOR_COLLECTION,
  NEW_REPEAT_DISPENSING,
  RAISED_IM1,
  IM1_WITH_GP_TOO_LONG,
  GPAPPROVED
} from '@avicennapharmacy/managemymeds-shared';

export const getCurrentOrderPage = (state: GlobalState) => state.orders.currentPage;
export const getTotalOrderPages = (state: GlobalState) => state.orders.totalPages;
export const getLoadingOrders = (state: GlobalState) => state.orders.loadingOrders;
export const getSearchString = (state: GlobalState) => state.orders.searchString;
export const getModalOrderId = (state: GlobalState) => state.orders.modalOrderId;
export const getSelectedOrderIds = (state: GlobalState) => state.orders.selectedOrders;
export const getSortFn = (state: GlobalState) => state.orders.sortOrdersFn;
export const getSortDesc = (state: GlobalState) => state.orders.sortOrdersDesc;
export const getErdSurveys = (state: GlobalState) => state.orders.erdSurveys;
const getUnorderedOrders = (state: GlobalState) => state.orders.orders;
const getUnorderedRejectedOrders = (state: GlobalState) => state.orders.rejectedOrders;
const getUnorderedCollectedOrders = (state: GlobalState) => state.orders.collectedOrders;
const getStatusProp = (_: any, { statuses }: { statuses: string[] }) => statuses;

const sortOrdersSelector = (
  orders: Order[],
  sortFn: (a: Order, b: Order) => number,
  sortDesc: boolean
) => {
  if (sortDesc) {
    return orders.sort(sortFn);
  } else {
    return orders.sort((a: Order, b: Order) => sortFn(a, b) * -1);
  }
};

export const getOrders = createSelector(
  [getUnorderedOrders, getSortFn, getSortDesc],
  sortOrdersSelector
);

export const getRejectedOrders = createSelector(
  [getUnorderedRejectedOrders, getSortFn, getSortDesc],
  sortOrdersSelector
);

export const getCollectedOrders = createSelector(
  [getUnorderedCollectedOrders, getSortFn, getSortDesc],
  sortOrdersSelector
);

export const getAllOrders = createSelector(
  [getUnorderedOrders, getUnorderedRejectedOrders, getUnorderedCollectedOrders],
  (orders, rejectedOrders, collectedOrders) => orders.concat(rejectedOrders).concat(collectedOrders)
);

export const getModalOrder = createSelector([getAllOrders, getModalOrderId], (orders, orderId) => {
  if (!orderId) {
    return null;
  }
  return orders.find(o => o.id === orderId) ?? null;
});

export const getModalErdSurvey = createSelector(
  [getModalOrderId, getErdSurveys],
  (orderId, erdSurveys) => {
    if (!orderId || !erdSurveys) {
      return null;
    }
    return erdSurveys[orderId];
  }
);

export const getRelatedOrders = createSelector([getOrders, getModalOrder], (orders, order) =>
  order ? orders.filter(o => o.id !== order.id && order.patientId === o.patientId) : []
);

const filteredOrdersSelector = (orders: Order[], searchString: string) => {
  const normalisedSearchString = searchString.replace(/\s/g, '').toLowerCase();
  const filterOrder = (order: any) =>
    searchString.trim() === '' ||
    order.id.toLowerCase().includes(searchString) ||
    (order.patientName &&
      order.patientName
        .replace(/\s/g, '')
        .toLowerCase()
        .includes(normalisedSearchString)) ||
    (order.patientAddress &&
      order.patientAddress
        .replace(/\s/g, '')
        .toLowerCase()
        .includes(normalisedSearchString)) ||
    (order.patientPhoneNumber &&
      order.patientPhoneNumber
        .replace(/\s/g, '')
        .toLowerCase()
        .includes(normalisedSearchString));
  return orders.filter(filterOrder);
};

export const getFilteredOrders = createSelector(
  [getOrders, getSearchString, getSortDesc],
  filteredOrdersSelector
);

export const getFilteredRejectedOrders = createSelector(
  [getRejectedOrders, getSearchString, getSortDesc],
  filteredOrdersSelector
);

export const getFilteredCollectedOrders = createSelector(
  [getCollectedOrders, getSearchString, getSortDesc],
  filteredOrdersSelector
);

export const getFilteredOrdersByStatuses = createSelector(
  [getFilteredOrders, getStatusProp, getSortDesc],
  (orders, statuses) => orders.filter(({ orderStatus }) => statuses.includes(orderStatus))
);

export const getOrderCounts = createSelector([getFilteredOrders], orders => ({
  [NEW]: orders.filter(order => order.orderStatus === NEW).length,
  [NEW_REPEAT_DISPENSING]: orders.filter(order => order.orderStatus === NEW_REPEAT_DISPENSING)
    .length,
  [AMEND]: orders.filter(order => order.orderStatus === AMEND).length,
  [RAISED]: orders.filter(order => order.orderStatus === RAISED).length,
  [RAISED_IM1]: orders.filter(order => order.orderStatus === RAISED_IM1).length,
  [IM1_WITH_GP_TOO_LONG]: orders.filter(order => order.orderStatus === IM1_WITH_GP_TOO_LONG).length,
  [READY_FOR_COLLECTION]: orders.filter(order => order.orderStatus === READY_FOR_COLLECTION).length,
  [GPAPPROVED]: orders.filter(order => order.orderStatus === GPAPPROVED).length
}));

export const getSelectedOrders = createSelector(
  [getOrders, getSelectedOrderIds],
  (orders, selectedIds) => orders.filter(order => selectedIds.has(order.id))
);

import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  getAdminConfigVar,
  Pharmacy,
  PromotedServices,
  Service,
  Owner
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import PromotedService from './PromotedService';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types';

type AppServiceCardTabProps = {
  pharmacy: Pharmacy;
  setPharmacy: (p: Partial<Pharmacy>) => void;
  validated: boolean;
  setValidated: (v: boolean) => void;
};

export default ({ pharmacy, setPharmacy, validated, setValidated }: AppServiceCardTabProps) => {
  const {
    hasAviClinicCard,
    hasBookAPharmacyServiceCard,
    hasPromoteServiceCard,
    promotedServices
  } = pharmacy;
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchServices = async () => {
    try {
      setLoading(true);
      const { data } = await Axios.post(getAdminConfigVar('serviceBookingGetServicesEndpoint'));

      setServices(data.result);
      setError('');
    } catch (err) {
      setError('There was a problem fetching the list of services');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pharmacy.promotedServices.length < 1) {
      setPharmacy({
        ...pharmacy,
        hasPromoteServiceCard: false,
        promoteServiceCardText: null,
        promoteServiceId: null
      });
      setValidated(false);
    }
  }, [pharmacy.promotedServices]);

  return (
    <>
      {Boolean(error) && <Alert variant="danger">{error}</Alert>}
      {Boolean(!services.length) ? (
        <Alert variant="warning">
          No services have been added within the service booking configuration
        </Alert>
      ) : (
        <>
          <Row className="mt-5">
            <Col sm={4}>
              <h4 className="mb-4">App Service Cards</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <p>Check the options below to show in the app</p>
            </Col>
          </Row>
          <Row className="mt-3" />
          <Form.Group>
            <Row>
              <Col sm={4}>
                <Form.Label>AviClinic</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Check
                  type="checkbox"
                  checked={hasAviClinicCard}
                  onChange={() => setPharmacy({ hasAviClinicCard: !hasAviClinicCard })}
                  disabled={!hasRole(Role.Admin_Services_Edit)}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col sm={4}>
                <Form.Label>Booking a pharmacy service</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Check
                  type="checkbox"
                  checked={hasBookAPharmacyServiceCard}
                  onChange={() =>
                    setPharmacy({ hasBookAPharmacyServiceCard: !hasBookAPharmacyServiceCard })
                  }
                  disabled={!hasRole(Role.Admin_Services_Edit)}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col sm={4}>
                <Form.Label>Promote service</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Check
                  type="checkbox"
                  checked={hasPromoteServiceCard}
                  onChange={() => {
                    setPharmacy(
                      hasPromoteServiceCard
                        ? {
                            ...pharmacy,
                            hasPromoteServiceCard: false,
                            promoteServiceCardText: null,
                            promoteServiceId: null,
                            promotedServices: []
                          }
                        : {
                            ...pharmacy,
                            hasPromoteServiceCard: true,
                            promotedServices: [
                              ...pharmacy.promotedServices,
                              {
                                serviceId: '',
                                serviceCardText: ''
                              }
                            ]
                          }
                    );
                    setValidated(false);
                  }}
                  disabled={!hasRole(Role.Admin_Services_Edit)}
                />
              </Col>
            </Row>
          </Form.Group>
          {hasPromoteServiceCard && (
            <>
              <Form.Group>
                <Row>
                  <Col sm={4}>
                    <Form.Label>Selected services</Form.Label>
                  </Col>
                  <Col sm={8}>
                    <Row>
                      <Col sm={5}>
                        <Form.Label>Select the service</Form.Label>
                      </Col>
                      <Col sm={7}>
                        <Form.Label>Promotional text to display on the card</Form.Label>
                      </Col>
                    </Row>
                    {promotedServices.map((promotedService: PromotedServices, i: number) => {
                      return (
                        <PromotedService
                          loading={loading}
                          services={services}
                          pharmacy={pharmacy}
                          validated={validated}
                          promotedService={promotedService}
                          setPharmacy={setPharmacy}
                          i={i}
                        />
                      );
                    })}
                  </Col>
                </Row>
                {hasRole(Role.Admin_Services_Edit) && (
                  <Row>
                    <Col sm={{ span: 4, offset: 4 }}>
                      <Button
                        disabled={
                          promotedServices[promotedServices.length - 1].serviceId.length < 1
                        }
                        onClick={() => {
                          setPharmacy({
                            ...pharmacy,
                            promotedServices: [
                              ...pharmacy.promotedServices,
                              {
                                serviceId: '',
                                serviceCardText: ''
                              }
                            ]
                          });
                          setValidated(false);
                        }}
                      >
                        + Promote another service
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form.Group>
            </>
          )}
        </>
      )}
    </>
  );
};

import { connect } from 'react-redux';
import { GlobalState } from '../../reducers';
import {
  getFilteredOrdersByStatuses,
  getSelectedOrderIds,
  getSortFn,
  getSortDesc,
  getErdSurveys
} from '../../selectors/orders';
import { setModalOrder, selectOrder, selectAllOrders, setOrderSort } from '../../actions/orders';
import OrderTable from './OrderTable';
import { Order } from '@avicennapharmacy/managemymeds-shared';

const mapStateToProps = (state: GlobalState, props: { statuses: string[]; orders?: Order[] }) => ({
  orders: props.orders ?? getFilteredOrdersByStatuses(state, props),
  erdSurveys: getErdSurveys(state),
  selectedOrders: getSelectedOrderIds(state),
  sortFn: getSortFn(state),
  sortDesc: getSortDesc(state)
});

const mapDispatchToProps = (dispatch: any, props: { statuses: string[] }) => ({
  setModalOrder: (orderId: string) => dispatch(setModalOrder(orderId)),
  selectOrder: (order: Order) => dispatch(selectOrder(order.id)),
  selectAllOrders: () => dispatch(selectAllOrders(props.statuses)),
  setOrderSort: (sortFn: (a: Order, b: Order) => number) => dispatch(setOrderSort(sortFn))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTable);

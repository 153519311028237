import { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';

export default (defaultValue: any, delay: number = 500, options: any = {}) => {
  const [value, setValue] = useState(defaultValue);
  const [signal, setSignal] = useState(Date.now());

  const triggerUpdate = useCallback(
    debounce(
      () => {
        setSignal(Date.now());
      },
      delay,
      options
    ),
    []
  );

  const setValueDebounced = useCallback(
    value => {
      setValue(value);
      triggerUpdate();
    },
    [triggerUpdate]
  );

  return [value, setValueDebounced, signal];
};

import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../../Header.container';
import PageContainer from '../../layout/PageContainer';
import { H2 } from '../../typography/Headers';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { PharmacyGroup } from '@avicennapharmacy/managemymeds-shared';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const APIKEYS = 'API Keys',
  SERVICE_BOOKING = 'Service Booking';

export default ({ group }: { group: PharmacyGroup | null | undefined }) => (
  <PageContainer>
    <Header />
    <Row>
      <Col>
        <H2>Group Settings</H2>
      </Col>
    </Row>
    <Tabs defaultActiveKey={APIKEYS} id="group-settings-tabs" className="my-3">
      <Tab eventKey={APIKEYS} title={APIKEYS} className="my-3 mx-2">
        <Row>
          <Col sm={6} md={4}>
            <h4 className="mb-3 mt-4">API Keys</h4>
            <Form>
              <Form.Group>
                <Form.Label>
                  Service booking public API Key
                  <br />
                  <small>Use this to embed the booking widget on your own website</small>
                </Form.Label>
                <Form.Control type="text" value={group?.bookingApiKey} disabled />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Tab>
      <Tab eventKey={SERVICE_BOOKING} title={SERVICE_BOOKING} className="my-3 mx-2">
        <Row>
          <Col sm={6} md={4}>
            <h4 className="mb-3 mt-4">Zoom Setup</h4>
            <Form>
              <Form.Group>
                <Form.Label>Zoom Auth Token</Form.Label>
                <Form.Control type="text" value="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Zoom User</Form.Label>
                <Form.Control type="text" value="3o2NRIuHRuS9WYDx8NRZEg" />
              </Form.Group>
              <Button>Save</Button>
            </Form>
          </Col>
        </Row>
      </Tab>
    </Tabs>
  </PageContainer>
);
